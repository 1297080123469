<template>
  <v-dialog persistent max-width="400" :value="true" @click:outside="close" @keydown.esc="close">
    <well title="Импорт excel с продажами">
      <template v-slot:title="{ title }">
        {{title}}
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <task-creation-form
          @submit="close"
          @reset="close"
      />
    </well>
  </v-dialog>
</template>

<script>
import Well from '@/components/Well/Well';
import TaskCreationForm from '@/components/TaskCreationForm/TaskCreationForm';

export default {
  name: 'ImportRelevanceExcelDialog',
  components: {
    Well,
    TaskCreationForm,
  },
  methods: {
    close() {
      this.$router.push({ hash: null });
    }
  }
}
</script>
