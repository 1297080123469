<template>
  <div :class="$style.root">
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn color="primary" absolute right fab v-bind="attrs" v-on="on" @click="onCreate">
            <v-icon v-text="'mdi-plus'" />
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Зарегистрировать сотрудника</span>
    </v-tooltip>
    <user-list :reload="reload"/>
    <v-navigation-drawer :value="!!id" app stateless width="430" right mobile-breakpoint="0">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="['Сотрудник', 'Новый сотрудник'][+isCreate]" />
          </v-list-item-content>
          <v-list-item-action>
            <v-row dense align="center">
              <v-col>
                <v-btn icon @click="onClose">
                  <v-icon color="grey lighten-1" v-text="'mdi-close'" />
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </template>
      <div v-if="id" :class="$style.drawer">
        <user-detail :id="id"/>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import UserList from '@/components/UserList/UserList';
  import UserDetail from '@/components/UserDetail/UserDetail';

  export default {
    name: 'UserListPage',
    components: {
      UserList,
      UserDetail,
    },
    props: {
      id: { type: String },
      reload: { type: Boolean },
      view: { type: String },
    },
    computed: {
      ...mapGetters({
        access: 'access',
        userParams: 'user/userParams',
      }),
      isCreate() {
        return this.id === 'creation';
      },
    },
    methods: {
      onCreate() {
        this.$router.push({name: 'UserListPage', params: { id: 'creation' }});
      },
      onClose() {
        this.$router.push({ name: 'UserListPage' });
      },
    },
    watch: {
      userParams: {
        handler() {
          if(this.userParams && this.userParams.navigation && !this.userParams.navigation[this.$options.name] && this.userParams.defaultPage) this.$router.push({ name: this.userParams.defaultPage});
        },
        deep: true,
      }
    }
  }
</script>

<style module lang="scss">
  .root{
    .drawer{
      padding: 6px 16px 16px;
    }
  }
</style>
