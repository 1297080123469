var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('v-data-table',{attrs:{"fixed-header":"","must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":_vm._.filter([
        {text: 'Время создания', value: 'date'},
        {text: 'Автор заявки', value: 'user'},
        {text: '№ заявки', value: 'id'},
        {text: 'Округ', value: 'district.name'},
        {text: 'Регион', value: 'region.name'},
        {text: 'Тип', value: 'type.name', sortable: false},
        {text: 'Статус заявки', value: 'status.name', sortable: false},
        {text: 'Дистрибьютор', value: 'distributor.name'},
        {text: 'Клиент', value: 'nameClient'},
        {text: 'Статус в заявке', value: 'newStatus.name'},
        {text: 'ИНН', value: 'inn'},
        {text: 'Классификация', value: 'classification.name'} ], function (ref) {
            var value = ref.value;

            return (_vm.isAdmin || _vm.isAdminView) || (!['distributor.name'].includes(value));
}),"mobile-breakpoint":0,"height":_vm.height,"items":_vm.items,"item-class":function (ref) {
            var _obj;

            var id = ref.id;
            return [_vm.$style.row, ( _obj = {}, _obj[_vm.$style.active] = id === _vm.active, _obj )];
},"server-items-length":_vm.count,"options":_vm.options,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 100, 500],
        itemsPerPageText: '',
      }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('dealer-task-list-filter',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }