<template>
<v-form v-model="valid" ref="form" :class="$style.root" @submit.prevent="onSubmit({ inn, date, sum, type })">
  <div :class="$style.row">
    <div :class="$style.col" v-if="!type">
      <date-picker v-model="date">
        <template v-slot:activator="{ onOpen, onInput, value, mask}">
          <v-text-field
              label="Дата ввода"
              dense
              hide-details="auto"
              append-icon="mdi-calendar"
              v-mask="mask"
              :value="value"
              @input="onInput"
              @click:append="onOpen"
              :rules="[rules.required, () => !!period || 'Не валидная дата']"
          />
        </template>
      </date-picker>
    </div>
    <div :class="$style.col">
      <v-text-field
          label="Сумма, руб."
          type="number"
          dense
          hide-details="auto"
          v-model="sum"
          :rules="[rules.required, rules.sum]"
      />
    </div>
  </div>
  <div :class="$style.row">
    <div :class="$style.col">
      <v-text-field
          :label="'Продажи за '+actualPeriod.months_title"
          dense
          readonly
          disabled
          hide-details
          :value="period"
      />
    </div>
  </div>
  <v-alert v-if="error" dense outlined type="error">
    <div v-html="error.error_description" />
  </v-alert>
  <v-btn dense depressed type="submit" v-text="'Сохранить'" />
</v-form>
</template>

<script>
import { mapActions } from 'vuex';
import { join, split, toNumber } from 'lodash';
import { mask } from 'vue-the-mask';
import DatePicker from '@/components/DatePicker/DatePicker';

export default {
  name: 'RealizationForm',
  directives: { mask },
  components: {
    DatePicker,
  },
  props: {
    inn: { type: String, required: true },
    type: { type: String, default: () => '' },
  },
  data(){
    return {
      valid: true,
      error: null,
      date: this.getNowDate(),
      sum: null,
      actualPeriod: {'months_title':'12 месяцев'},
      rules: {
        required: v => !!v || 'Обязательное поле',
        sum: v => 0 <= toNumber(v) || 'Сумма не может быть отрицательной',
      },
    };
  },
  computed: {
    period() {
      if(this.type && this.actualPeriod) return this.actualPeriod.period;
      else {
        const [, month, year] = split(this.date, '.');
        const lang = ['Дек', 'Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек', 'Янв'];
        if (!lang[toNumber(month)]) return '';
        return `${lang[toNumber(month)]} ${toNumber(year) - 1} - ${lang[toNumber(month) - 1]} ${year}`;
      }
    }
  },
  methods: {
    ...mapActions({
      send: 'realizations/sendItem',
      getActualPeriod: 'realizations/getActualPeriod',
    }),
    reset() {
      this.sum = 0;
      this.date = this.getNowDate();
    },
    getNowDate() {
      const date = new Date();
      const day = `${date.getDate()}`.padStart(2, '0');
      const month = `${(date.getMonth()+1)}`.padStart(2, '0');
      const year = `${date.getFullYear()}`;
      return join([day, month, year], '.');
    },
    onSubmit({ inn, date, sum, type }) {
      this.$refs.form.validate();
      if (this.valid) {
        this.error = null;
        this.send({ inn, date, sum, type }).then(() => {
          this.reset();
          this.$emit('submit');
          if(this.type) {
            if(this.type==='relevance') this.$router.push({ name: 'RelevanceListPage', params: { reload: true }});
            else if(this.type==='advance') this.$router.push({ name: 'AdvanceClientListPage', params: { reload: true }});
          }
        })
        .catch((error) => (this.error = error));
      }
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    }
  },
  mounted() {
    this.$refs.form.validate();

    if(this.type && this.inn) {
      this.getActualPeriod({
        inn: this.inn,
        type: this.type
      }).then((result) => {
        this.actualPeriod = result;
      })
    }
    else this.type='';
  }
}
</script>

<style module lang="scss">
.root {
  padding: 8px 16px;
}
.row {
  display: flex;
  margin-left: -16px;
  margin-right: -16px;
  .col {
    padding-left: 16px;
    padding-right: 16px;
    flex: 1 1 50%;
  }
  &:not(:first-child) {
    margin-top: 30px;
  }
  &:not(:last-child){
    margin-bottom: 20px;
  }
}
</style>
