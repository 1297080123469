<template>
    <div :class="$style.root">
        <user-status-switch :employee="employee" @input="onSubmit" class="mb-6"/>
        <v-alert v-if="error" dense outlined type="error">
            <div v-html="error.error_description" />
        </v-alert>
        <user-view-row title="Дата регистрации" :value="employee.dateRegister"/>
        <user-view-row title="ФИО" :value="employee.name"/>
        <user-view-row title="E-mail" :value="employee.email"/>
        <user-view-row title="Телефон" :value="employee.personalPhone"/>
        <user-view-row title="Тип должности" :value="employee.positionType"/>
        <user-view-row title="Должность" :value="employee.position"/>
        <user-view-row title="Активен" :value="employee.active"/>
    </div>
</template>

<script>
    import UserViewRow from '@/components/UserViewRow/UserViewRow';
    import UserStatusSwitch from '@/components/UserStatusSwitch/UserStatusSwitch';
    import {mapActions} from "vuex";

    export default {
        name: 'UserView',
        props: {
            employee: {type: Object}
        },
        data() {
            return {
                error: null
            }
        },
        components: {
            UserViewRow,
            UserStatusSwitch,
        },
        methods: {
            ...mapActions({
                fetchCurrent: 'employee/fetchCurrent',
                update: 'employee/update',
            }),
            onSubmit(userFields) {
                this.update(userFields)
                    .then(() => {
                        this.$router.push({ name: 'UserListPage', params: { reload: true } });
                    })
                    .catch((error) => (this.error = error));
            }
        },
    }
</script>

<style module lang="scss">
    .root {
    }
</style>
