<template>
    <div :class="$style.root" v-if="items.length">
        <v-tooltip v-for="{ text, icon, color, disabled, action } in items" bottom :key="text">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mr-2" v-bind="attrs" v-on="on" depressed :disabled="disabled" :color="color" @click="action">
                    <v-icon v-if="icon" v-text="icon"/>
                    <template v-else>{{text}}</template>
                </v-btn>
            </template>
            <span>{{text}}</span>
        </v-tooltip>
        <slot/>
    </div>
</template>

<script>
    import {get, isString} from 'lodash';

    export default {
        name: 'UserStatusSwitch',
        props: {
            employee: {type: Object},
        },
        computed: {
            items() {
                const employeeId = get(this.employee, 'id', '');
                const items = [];
                if (isString(employeeId)) {
                    if (this.employee.activeCode === 'Y') {
                        items.push(
                            {
                                text: 'Деактивировать',
                                color: 'error',
                                action: () => this.$emit('input', {id: this.employee.id, active: 'N'}),
                            }
                        );
                    } else {
                        items.push(
                            {
                                text: 'Активировать',
                                color: 'success',
                                action: () => this.$emit('input', {id: this.employee.id, active: 'Y'}),
                            }
                        );
                    }
                } else {
                    items.push(
                        {
                            text: 'Сохранить',
                            color: 'success',
                            action: () => this.$emit('input', 'save'),
                        }
                    );
                    items.push(
                        {
                            text: 'Отменить',
                            color: 'error',
                            action: () => this.$emit('input', 'cancel'),
                        }
                    );
                }

                return items;
            }
        },
    }
</script>

<style module lang="scss">
    .root {
    }
</style>
