import { get, toString } from 'lodash';

/**
 * Выборка полей авансовой скидки из структуры arResult
 * @param item
 * @returns {{advancePeriodStart: string, advancePeriodDays: string, inn: string, id: string}}
 */
export const advanceItemAdapter = (item) => ({
  id: toString(get(item, 'ID')),
  advancePeriodDays: toString(get(item, 'ADVANCE_PERIOD_DAYS')),
  advancePeriodStart: toString(get(item, 'ADVANCE_PERIOD_START')),
});
