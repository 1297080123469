import Vue from 'vue';
import Vuetify from 'vuetify';
import {light} from './theme';
import ru from 'vuetify/src/locale/ru.ts'

Vue.use(Vuetify);

const options = {
  theme: {
    themes: {
      light,
    },
  },
  lang: {
    locales: { ru },
    current: 'ru',
  },
};

export default new Vuetify(options);
