var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:_vm.$style.root,attrs:{"dense":"","must-sort":"","hide-default-footer":_vm.count < 6,"items-per-page":5,"items":_vm.items,"item-class":function (ref) {
    var cls = ref.cls;

    return [_vm.$style.tr, cls];
},"headers":[
      {value: 'name', text: 'Имя'} ],"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: [5, 10, 20, -1],
      itemsPerPageText: '',
    }}})}
var staticRenderFns = []

export { render, staticRenderFns }