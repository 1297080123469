<template>
<div :class="$style.root">
  <v-data-table
      v-if="items"
      class="mb-3"
      dense
      hide-default-footer
      must-sort
      item-key="name"
      :class="$style.table"
      :items-per-page="-1"
      :items="items"
      :headers="[
        {value: 'inn', text: 'ИНН'},
        {value: 'name', text: 'Имя'},
        {value: 'control'},
      ]"
      :item-class="() => $style.tr"
  >
    <template v-slot:item.inn="{ index, item: { inn } }">
      <div :class="$style.field">
        <inn-field
          :value="inn"
          :not="getNot(index)"
          :check="['required', 'inn', 'not', 'duplicate', 'inner', 'related']"
          @input="(value) => onInput(value, index)"
          @update="onUpdate"
        />
      </div>
    </template>
    <template v-slot:item.name="{ item: { name, inn } }">
      <router-link v-if="name && inn" :to="{ name: 'ClientListPage', params: { inn } }">
        <span class="text-no-wrap">{{name}}</span>
      </router-link>
    </template>
    <template v-slot:item.control="{ index }">
      <v-btn class="ml-2 float-right" small icon @click="onRemove(index)">
        <v-icon small v-text="'mdi-delete'" />
      </v-btn>
    </template>
  </v-data-table>
  <v-btn dense depressed @click="onAdd" :disabled="disabled">
    <v-icon v-text="'mdi-plus'" />
    Привязать
  </v-btn>
</div>
</template>

<script>
import { map, findIndex, forEach, uniqBy, filter } from 'lodash';
import InnField from '@/components/InnField/InnField';

export default {
  name: 'ClientAttachTable',
  components: {
    InnField,
  },
  props: {
    current: { type: String },
    value: { type: Array, default: () => [] },
  },
  computed: {
    items() {
      return map(this.value, (item, index) => ({
        index,
        ...item,
      }))
    },
    disabled(){
      return !!(findIndex(this.value, {id: ''}) + 1);
    }
  },
  methods: {
    getNot(index) {
      return [this.current, ...filter(map(this.value, 'inn'), (v, i) => i !== index)];
    },
    getRaw(value) {
      return JSON.parse(JSON.stringify(value))
    },
    onAdd() {
      const value = this.getRaw(this.value);
      this.$emit('input', [...value, { id: '', name: '', inn: '' }]);
    },
    onRemove(index){
      const value = this.getRaw(this.value);
      value.splice(index, 1);
      this.$emit('input', value);
    },
    onInput(inn, index) {
      const value = this.getRaw(this.value);
      value[index].id = '';
      value[index].name = '';
      value[index].inn = inn;
      this.$emit('input', value);
    },
    onUpdate(items) {
      const value = this.getRaw(this.value);
      forEach(items, ({ id, code, name, related }) => {
        if (!related) return;
        const index = findIndex(value, { inn: code });
        if (index + 1) {
          value[index].id = id;
          value[index].name = related ? name : '';
        }
      });
      this.$emit('input', uniqBy(value, 'id'));
    }
  },
}
</script>

<style module lang="scss">
.root {}
.table {}
.tr {}
.field {
  width: 130px;
}
</style>
