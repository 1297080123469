var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('v-data-table',{attrs:{"fixed-header":"","must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":_vm._.filter([
        {text: 'Название', value: 'name'},
        {text: 'ИНН', value: 'inn'},
        {text: 'Статус', value: 'status.name'},
        {text: 'bp.iek.ru', value: 'bpConnected.name'},
        {text: 'Классификация', value: 'classification.name'},
        {text: 'Дистрибуторы', value: 'distributors'} ]),"mobile-breakpoint":0,"height":_vm.height,"items":_vm.items,"item-class":function (ref) {
            var _obj;

            var inn = ref.inn;
            return [_vm.$style.row, ( _obj = {}, _obj[_vm.$style.active] = inn === _vm.active, _obj )];
},"server-items-length":_vm.count,"options":_vm.options,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 100, 500],
        itemsPerPageText: '',
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.distributors",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:_vm.$style.distributors},[_vm._v(" "+_vm._s(_vm._.join(_vm._.map(item.distributors, 'name'), ', '))+" ")])]}},{key:"body.prepend",fn:function(){return [_c('regional-client-list-filter',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]},proxy:true},{key:"item.bpConnected.name",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:_vm.$style.distributors},[(item.bpConnected.code === 'Y')?_c('v-icon',{attrs:{"color":"success"},domProps:{"textContent":_vm._s('mdi-check-bold')}}):_c('v-icon',{attrs:{"color":"error"},domProps:{"textContent":_vm._s('mdi-close-thick')}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }