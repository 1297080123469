<template>
  <div :class="$style.root">
    <div :class="$style.label" v-if="title">{{ title }}</div>
    <v-menu v-model="menu" :close-on-content-click="false" @close="onClose">
      <template v-slot:activator="{ on: { click }}">
        <v-text-field
            readonly
            dense
            clearable
            placeholder="Все"
            prepend-icon="mdi-calendar"
            :outlined="outlined"
            hide-details="auto"
            :value="dateView"
            @click="click"
            @click:prepend="click"
            @click:clear="clear"
        />
      </template>
      <v-date-picker scrollable range v-model="raw">
        <v-spacer/>
        <v-btn text color="primary" @click="onClose">
          Выбрать
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { split, join, reverse, map } from 'lodash';

export default {
  name: 'DatePickerRange',
  props: {
    defaultValue: {type: Array},
    value: {type: String},
    title: {type: String},
    outlined: {type: Boolean, default: () => false}
  },
  data() {
    return {
      menu: false,
      raw: [],
    }
  },
  computed: {
    dateView() {
      return join(map(this.raw, date => join(reverse(split(date, '-')), '.')), ' - ')
    }
  },
  methods: {
    onChange(value) {
      this.raw = value
    },
    onClose() {
      this.menu = false;
      this.$emit('input', join(map(this.raw, date => join(reverse(split(date, '-')), '.')), '-'));
    },
    clear() {
      this.raw = [];
      this.onClose();
    },
  },
  mounted() {
    if(this.defaultValue) {
      this.raw = this.defaultValue;
    }
  },
}
</script>

<style module lang="scss">
.root {
  .label {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
</style>
