<script>
import { get, last, reduce, set, isArray, map, isNaN, toNumber } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import STATUSES from '@/api/enums/promotion';
import { promotionEmptyAdapter } from "@/store/promotions/utils";

export default {
    name: 'PromotionsForm',
    props: {
        promotion: {
            type: Object,
            default() {
                return promotionEmptyAdapter();
            }
        }
    },
    data() {
        return {
            error: null,
            fields: {},
            files: [],
            valid: true,
            rules: {
                required: val => !!val || 'Обязательно для заполнения',
                numeric: val => !isNaN(+val) || 'Может содержать только цифры',
                percent: val => (0 <= toNumber(val) && toNumber(val) <= 100) || 'Введите корректно значение процента',
            },
        }
    },
    computed: {
        ...mapGetters({
            statuses: 'clients/statuses',
            distributor: 'user/distributor'
        }),
        isCreate() {
            return this.status === STATUSES.CREATURE;
        },
        isEditing() {
            return this.status === STATUSES.EDITING;
        },
        id() {
            return get(this.promotion, 'id', null);
        },
        status() {
            return get(this.promotion, 'status.code', null);
        },
        values() {
            return reduce(this.fields, (acc, cur, key) => {
                const value = last(cur);
                set(acc, key, isArray(value) ? map(value, 'id') : value);
                return acc;
            }, {});
        },
        watchData() {
            return this.id + this.promotion.clientInn + this.status;
        }
    },
    methods: {
        ...mapActions({
            'update': 'promotions/update',
        }),
        submit(status) {
            let fields = {
                name: this.fields.name,
                conditions: this.fields.conditions,
                discount: this.fields.discount,
                activeFrom: this.fields.activeFrom,
                activeTo: this.fields.activeTo,
                site: this.fields.site,
                salePoint: this.fields.salePoint,
                skuFile: map(this.files, 'id')
            };
            if (this.isCreate) {
                fields.clientInn = this.fields.clientInn;
            } else {
                fields.id = this.id;
                fields.status = status;
            }

            this.update(fields)
                .then(id => this.$emit('submit', id))
                .catch((error) => (this.error = error));
        },
        reset() {
            const promotion = JSON.parse(JSON.stringify(this.promotion));
            if(this.fields.name) {
                promotion.fields.name = this.fields.name
            }
            this.fields = get(promotion, 'fields', {});
            this.files = get(promotion, 'skuFile', []);
        }
    },
    watch: {
        'watchData': {
            immediate: true,
            handler() {
                this.reset()
            }
        },
    },
}
</script>
