var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length)?_c('div',{class:_vm.$style.root},[_vm._l((_vm.items),function(ref){
var text = ref.text;
var icon = ref.icon;
var color = ref.color;
var disabled = ref.disabled;
var action = ref.action;
return _c('v-tooltip',{key:text,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"depressed":"","disabled":disabled,"color":color},on:{"click":action}},'v-btn',attrs,false),on),[(icon)?_c('v-icon',{domProps:{"textContent":_vm._s(icon)}}):[_vm._v(_vm._s(text))]],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(text))])])}),_vm._t("default")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }