<template>
    <v-autocomplete
            :label="label"
            ref="input"
            clearable
            :value="value"
            :items="items"
            :rules="[val => !!val || 'Обязательно для заполнения']"
            :search-input.sync="search"
            dense
            hide-details="auto"
            @input="(value) => $emit('input', value)"
    />
</template>

<script>
    import {get} from 'lodash';
    import client from '@/store/clients/api';

    export default {
        name: 'InnAutocompleteField',
        props: {
            value: {type: String},
            label: {type: String},
        },
        data() {
            return {
                search: '',
                items: [],
                pending: false
            }
        },
        watch: {
            search(value) {
                if (value && value.length >= 3) {
                    this.pending = true;
                    client.findDistributorINNList(value)
                        .then(result => {
                            const success = !get(result, 'result.error');
                            if (success) {
                                this.items = get(result, 'result.items');
                            }
                            if (!success) throw {
                                error: 'Ошибка получения данных',
                                error_description: get(result, 'message'),
                            };
                        })
                        .finally(() => {
                            this.pending = false;
                        })
                } else {
                    this.items = [];
                }
            }
        },
    }
</script>

<style module lang="scss"></style>
