<template>
  <v-dialog persistent max-width="600" :value="true" @click:outside="close" @keydown.esc="close">
    <well title="Задать вопрос по работе iPrice">
      <template v-slot:title="{ title }">
        {{title}}
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <question-form
          @submit="close"
          @reset="close"
      />
    </well>
  </v-dialog>
</template>

<script>
import Well from '@/components/Well/Well';
import QuestionForm from '@/components/QuestionForm/QuestionForm';

export default {
  name: 'QuestionDialog',
  components: {
    Well,
    QuestionForm,
  },
  methods: {
    close() {
      this.$router.push({ hash: null });
    }
  }
}
</script>
