<template>
  <component v-if="dialog" :is="dialog" />
</template>
<script>
import {get, kebabCase, replace} from 'lodash';

import TaskCreationDialog from '@/dialogs/TaskCreationDialog/TaskCreationDialog';
import InstructionsDialog from '@/dialogs/InstructionsDialog/InstructionsDialog';
import InstructionsDealerDialog from '@/dialogs/InstructionsDealerDialog/InstructionsDealerDialog';
import InstructionsApiDialog from '@/dialogs/InstructionsApiDialog/InstructionsApiDialog';
import UserEditDialog from '@/dialogs/UserEditDialog/UserEditDialog';
import QuestionDialog from '@/dialogs/QuestionDialog/QuestionDialog';
import DistributorQuestionDialog from '@/dialogs/DistributorQuestionDialog/DistributorQuestionDialog';
import CisLinkDialog from '@/dialogs/CisLinkDialog/CisLinkDialog';
import AffiliationDialog from '@/dialogs/AffiliationDialog/AffiliationDialog';
import ImportRelevanceExcelDialog from '@/dialogs/ImportRelevanceExcelDialog/ImportRelevanceExcelDialog';
import ClientAccountingDialog from '@/dialogs/ClientAccountingDialog/ClientAccountingDialog';

export default {
  name: 'DialogManager',
  components: {
    InstructionsDialog,
    InstructionsApiDialog,
    TaskCreationDialog,
    UserEditDialog,
    CisLinkDialog,
    AffiliationDialog,
    QuestionDialog,
    DistributorQuestionDialog,
    ImportRelevanceExcelDialog,
    InstructionsDealerDialog,
    ClientAccountingDialog
  },
  computed: {
    dialog() {
      const dialog = get(this.$route, ['query', 'dialog'], get(this.$route, ['params', 'dialog'], replace(this.$route.hash, '#', '')));
      return kebabCase(dialog);
    }
  },
}
</script>
