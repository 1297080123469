<template>
  <tr :class="$style.root">
    <td>
      <div :class="$style.control">
        <date-picker
                v-model="advanceDiscountFrom"
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="name"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="inn"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
                v-model="status"
                :items="statuses"
                hideSelected
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <date-picker
                v-model="advanceDiscountTo"
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="district"
            :items="districts"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import {get, debounce, pickBy} from 'lodash';
import {mapGetters} from 'vuex';

import DatePicker from '@/components/DatePickerRange/DatePickerRange';

export default {
  name: 'AdvanceClientListFilter',
  components: {
    DatePicker,
  },
  props: {
    value: { type: Object },
  },
  data() {
    return {
      advanceDiscountFrom: get(this.value, 'advanceDiscountFrom', ''),
      name: get(this.value, 'name', ''),
      inn: get(this.value, 'inn', ''),
      status: get(this.value, 'status', ''),
      advanceDiscountTo: get(this.value, 'advanceDiscountTo', ''),
      district: get(this.value, 'district', ''),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      districts: 'clients/districts',
      statuses: 'clients/statuses',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    }
  },
  methods: {
    onChange: debounce(function() {
      this.$emit('input', pickBy({
        advanceDiscountFrom: (this.advanceDiscountFrom?this.advanceDiscountFrom:'1'),
        name: this.name,
        inn: this.inn,
        status: this.status,
        advanceDiscountTo: this.advanceDiscountTo,
        district: this.district,
      }, Boolean));
    }, 600),
  },
}
</script>

<style module lang="scss">
.root {
  background: none !important;
  .control {
    width: 250px;
  }
}
</style>
