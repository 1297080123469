<template>
<div :class="$style.root">
  <div :class="$style.inner">
    <h1 :class="$style.title">Восстановление пароля</h1>
    <div class="text-center">
      <p>Контрольная строка, а также ваши регистрационные данные были высланы на email. Пожалуйста, дождитесь письма, так как контрольная строка изменяется при каждом запросе.</p>
    </div>
    <div class="mt-6">
      <v-btn
          v-text="'Авторизоваться'"
          block
          depressed
          color="primary"
          @click="$router.replace({ name: 'UserSignInPage' })"
      />
      <v-btn
          v-text="'Не пришло письмо'"
          block
          depressed
          class="mt-3"
          @click="$router.replace({ name: 'UserRecoveryPage' })"
      />
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'UserRecoverySuccessPage',
}
</script>

<style module lang="scss">
.root {
  display: flex;
  min-height: 90vh;
  .inner {
    margin: auto;
    max-width: 100%;
    width: 440px;
  }
  .title {
    margin-bottom: 10px;
    font-weight: normal;
    text-align: center;
  }
}
</style>
