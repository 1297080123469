<template>
  <v-data-table
      dense
      must-sort
      :hide-default-footer="count < 6"
      :class="$style.root"
      :items-per-page="5"
      :items="value"
      :item-class="() => $style.tr"
      :headers="[
        {value: 'name', text: 'Имя'},
        {value: 'size', text: 'Размер'},
      ]"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, -1],
        itemsPerPageText: '',
      }"
      @click:row="onClick"
  />
</template>

<script>
import {get} from 'lodash';
import api from '@/api';

export default {
  name: 'FileTable',
  props: {
    value: {
      type: Array,
    },
  },
  computed: {
    count() {
      return get(this, 'value.length', 0);
    }
  },
  methods: {
    onClick({ path, name }) {
      api.download(path, name, {});
    }
  }
}
</script>

<style module lang="scss">
.root {
  white-space: nowrap;
  .tr {
    cursor: pointer;
  }
  :global {
    .v-data-footer{
      justify-content: space-between;
      padding: 0;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
