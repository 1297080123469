import { get, includes, set, map, toNumber, findIndex } from 'lodash';
import promotions from './api';
import { enumerationAdapter } from '@/store/utils';
import { promotionItemAdapter } from "@/store/promotions/utils";

const START = 'START';
const END = 'END';
const UPDATE_COUNT = 'UPDATE_COUNT';
const UPDATE_ITEMS = 'UPDATE_ITEMS';
const UPDATE_CURRENT = 'UPDATE_CURRENT';
const REMOVE_CURRENT = 'REMOVE_CURRENT';
const UPDATE_STATUSES = 'UPDATE_STATUSES';
const UPDATE_CONDITIONS_LIST = 'UPDATE_CONDITIONS_LIST';
const UPDATE_PROMOTION_RULES = 'UPDATE_PROMOTION_RULES';
const PUSH_COMMENT = 'PUSH_COMMENT';

export default {
  namespaced: true,
  state: {
    items: [],
    count: 0,
    current: null,
    statuses: [],
    conditionsList: [],
    promotionRules: [],
    roc: 0,
    pending: {
      update: false,
      send: false,
      fetchItems: false,
      fetchCurrent: false,
      fetchStatuses: false,
      fetchConditionsList: false,
      fetchPromotionRules: false,
    }
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    current(state) {
      return promotionItemAdapter(state.current);
    },
    items(state) {
      return map(state.items, promotionItemAdapter);
    },
    count(state) {
      return toNumber(state.count);
    },
    statuses(state) {
      return map(state.statuses, enumerationAdapter);
    },
    conditionsList(state) {
      return map(state.conditionsList, enumerationAdapter);
    },
    promotionRules(state) {
      return state.promotionRules;
    },
    roc(state) {
      return state.roc;
    },
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [REMOVE_CURRENT](state) {
      state.current = null;
    },
    [UPDATE_CURRENT](state, item) {
      state.current = item;
      const index = findIndex(state.items, {id: item.id});
      if (index + 1) {
        state.items.splice(index, 1, item);
      }
    },
    [UPDATE_ITEMS](state, items) {
      state.items = items;
    },
    [UPDATE_COUNT](state, count) {
      state.count = count;
    },
    [UPDATE_STATUSES](state, items) {
      state.statuses = items;
    },
    [UPDATE_CONDITIONS_LIST](state, items) {
      state.conditionsList = items;
    },
    [UPDATE_PROMOTION_RULES](state, items) {
      state.promotionRules = items.rules;
      state.roc = items.roc;
    },
    [PUSH_COMMENT](state, comment) {
      const index = findIndex(state.items, { id: promotions.id });
      const comments = get(state.current, 'comments');
      comments ? comments.unshift(comment) : set(state.current, 'comments', [comment]);
      if (index + 1) {
        set(state, 'items.comments', state.current.comments);
      }
    },
  },
  actions: {
    async fetchCurrent({commit}, id) {
      commit(START, 'fetchCurrent');
      const {result} = await promotions.item(id);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_CURRENT, result);
      }
      commit(END, 'fetchCurrent');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async removeCurrent({commit}) {
      commit(REMOVE_CURRENT);
    },
    async fetchItems({commit}, {page, size, sort, filter}) {
      commit(START, 'fetchItems');
      const {result} = await promotions.list(page, size, sort, filter);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_ITEMS, result.items);
        commit(UPDATE_COUNT, toNumber(get(result, 'navResult.navRecordCount', 0)));
      }
      commit(END, 'fetchItems');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchStatuses({commit}) {
      commit(START, 'fetchStatuses');
      const {result} = await promotions.statuses();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_STATUSES, result.items);
      commit(END, 'fetchStatuses');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchConditionsList({commit}) {
      commit(START, 'fetchConditionsList');
      const {result} = await promotions.listConditions();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_CONDITIONS_LIST, result.items);
      commit(END, 'fetchConditionsList');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchPromotionRules({commit}) {
      commit(START, 'fetchPromotionRules');
      const {result} = await promotions.promotionRules();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_PROMOTION_RULES, result);
      commit(END, 'fetchPromotionRules');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async update({commit}, {
      id,
      name,
      status,
      conditions,
      clientInn,
      discount,
      activeFrom,
      activeTo,
      site,
      salePoint,
      skuFile
    }) {
      commit(START, 'update');
      const {result} = await promotions.update({
        id,
        name,
        status,
        conditions,
        clientInn,
        discount,
        activeFrom,
        activeTo,
        site,
        salePoint,
        skuFile
      });
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_CURRENT, result);
      }
      commit(END, 'update');

      if (!success) throw {
        error: 'Ошибка при попытке сохранить данные акции',
        error_description: get(result, 'message'),
      }

      return get(result, 'id', null);
    },
    async sendComment({ commit }, {id, message}) {
      commit(START, 'send');
      const { result } = await promotions.comment(id, message);
      const success = !get(result, 'error');
      if (success) commit(PUSH_COMMENT, result);
      if (!success) throw {
        error: 'Ошибка отправки сообщения',
        error_description: get(result, 'message'),
      };
      commit(END, 'send');
    }
  },
}
