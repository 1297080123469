<script>
    import {get, last, reduce, set, isArray, map} from 'lodash';
    import {mapActions, mapGetters} from 'vuex';
    import STATUSES from '@/api/enums/statuses';
    import {employeeEmptyAdapter} from "../../store/employee/utils";

    export default {
        name: 'UserForm',
        props: {
            employee: {
                type: Object,
                default() {
                    return employeeEmptyAdapter();
                }
            }
        },
        data() {
            return {
                error: null,
                fields: {},
                valid: true,
                rules: {
                    required: val => !!val || 'Обязательно для заполнения',
                    email: v => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Некорректный адрес электронной почты',
                    phone: val => (!val || (/^(\+\d|\d)(\(\d*\)|\d-\d| |\d)*\d*$/gm.test(val) && val.length >= 6)) || 'Не корректный номер телефона'
                },
            }
        },
        computed: {
            ...mapGetters({
                access: 'access',
                statuses: 'clients/statuses',
                classifications: 'clients/classifications',
                distributor: 'user/distributor',
                realizations: 'realizations/count'
            }),
            isAdmin() {
                return this.access(({ADMIN}, ROLE) => ADMIN === ROLE);
            },
            isAdjustment() {
                return this.status === STATUSES.ADJUSTMENT;
            },
            isEditing() {
                return this.status === STATUSES.EDITING;
            },
            id() {
                return get(this.employee, 'id', null);
            },
            inn() {
                return get(this, 'employee.inn') || last(get(this, 'fields.inn', []));
            },
            status() {
                return get(this.employee, 'status.code', null);
            },
            values() {
                return reduce(this.fields, (acc, cur, key) => {
                    if (!this.isAdmin && (key === 'distributors')) return acc; // Пропускать поле дистребьютера для не администратора
                    const value = last(cur);
                    set(acc, key, isArray(value) ? map(value, 'id') : value);
                    return acc;
                }, {});
            },
            watchData() {
                return this.id;
            }
        },
        methods: {
            ...mapActions({
                'create': 'employee/create',
            }),
            submit(status) {
                if(status && status === 'save') {
                    this.create(this.employee)
                    .then(id => this.$emit('submit', id))
                    .catch((error) => (this.error = error));
                } else {
                    this.error = {error_description: 'Неизвестная ошибка. Попробуйте позже.'};
                }
            },
            reset() {
                const employee = JSON.parse(JSON.stringify(this.employee));
                this.fields = get(employee, {});
            }
        },
        watch: {
            'watchData': {
                immediate: true,
                handler() {
                    this.reset()
                }
            },
        },
    }
</script>
