<template>
  <v-form v-model="valid" ref="form" @submit.prevent="onSubmit">
    <v-select
        class="mb-6"
        label="Выберите тип заявки"
        outlined
        dense
        hide-details="auto"
        v-model="type"
        :items="types"
        :rules="[rules.required]"
    />
    <div v-if="isUpdate" class="mb-4">
      <inn-field
          label="ИНН"
          outlined
          v-model="inn"
          :check="['required', 'inn', 'not', 'duplicate', 'inner', 'notRelated']"
      />
    </div>
    <div class="mt-4 text-right">
      <v-btn
          v-text="'Создать заявку'"
          min-width="140px"
          color="primary"
          type="submit"
          :disabled="!valid"
      />
      <v-btn
          class="ml-2"
          v-text="'Отменить'"
          @click="onReset"
      />
    </div>
  </v-form>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import InnField from '@/components/InnField/InnField';
import TYPES from '@/api/enums/types';

export default {
  name: 'TaskCreationForm',
  components: {
    InnField
  },
  data() {
    return {
      valid: true,
      type: '',
      inn: '',
      rules: {
        required: v => !!v || 'Обязательное поле',
      },
      types: [
        {text: 'Создание клиента', value: TYPES.CREATE},
        {text: 'Привязка существующего клиента', value: TYPES.UPDATE},
      ],
    }
  },
  computed: {
    ...mapGetters({
      distributor: 'user/distributor',
    }),
    isCreate(){
      return this.type === TYPES.CREATE;
    },
    isUpdate(){
      return this.type === TYPES.UPDATE;
    },
  },
  methods: {
    ...mapActions({
      'update': 'tasks/update',
    }),
    reset() {
      this.type = '';
      this.inn = '';
    },
    onSubmit() {
      this.$refs.form.validate();
      if (this.valid) {
        if (this.isCreate) {
          this.$router.push({name: 'TaskListPage', params: { id: 'creation' }});
        }
        if (this.isUpdate) {
          this.update({
            inn: this.inn,
            distributor: get(this.distributor, 'id'),
            type: TYPES.CREATE,
          });
          this.$emit('submit');
        }
      }
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    },
  }
}
</script>

<style module lang="scss"></style>
