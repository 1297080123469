<template>
  <v-dialog persistent max-width="1200" :value="true" @click:outside="close" @keydown.esc="close">
    <well title="Описание интеграционного API iPrice">
      <template v-slot:title="{ title }">
        {{title}}
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <div :class="$style.root" v-html="html" ref="root"  />
    </well>
  </v-dialog>
</template>

<script>
import instructionsApi from '!!raw-loader!./instructionsApi.html';
import Well from '@/components/Well/Well';
export default {
  name: 'InstructionsApiDialog',
  components: {
    Well,
  },
  data() {
    return {
      html: instructionsApi,
    }
  },
  computed: {},
  methods: {
    close() {
      this.$router.push({ hash: null });
    }
  }
}
</script>
<style module lang="scss">
.root{
  pointer-events: none;
  user-select: none;
  a {
    pointer-events: auto;
  }
  img {
    max-width: 100%;
  }
  h2 {
    margin-bottom: 20px;
    margin-top: 40px;
  }
  ul {
    margin-bottom: 30px;
    li {
      margin-bottom: 5px;
    }
    ul {
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }
}
</style>
