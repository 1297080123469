<template>
<div :class="$style.root">
  <regional-client-list v-if="isRegional" />
  <client-list v-else-if="showContent" />
  <v-navigation-drawer :value="!!inn" app stateless width="430" right mobile-breakpoint="0">
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-text="'Клиент'" />
        </v-list-item-content>
        <v-list-item-action>
          <v-row dense align="center">
            <v-col cols="auto" v-if="isAdmin || isAdminView">
              <v-btn icon small :to="{ hash: 'ClientAccountingDialog' }">
                <v-icon color="grey lighten-1" v-text="'mdi-information-outline'" />
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="inn !== 'creation' && isAdmin">
              <v-btn icon small :to="{ hash: 'AffiliationDialog' }">
                <v-icon color="grey lighten-1" v-text="'mdi-graph-outline'" />
              </v-btn>
            </v-col>
            <v-col>
              <v-btn v-if="!editing && !isAdminView" icon :to="{params: {view: 'editing'}}">
                <v-icon color="grey lighten-1" v-text="['mdi-pen', 'mdi-close'][+editing]" />
              </v-btn>
            </v-col>
            <v-col>
              <v-btn icon @click="onClose">
                <v-icon color="grey lighten-1" v-text="'mdi-close'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </template>
    <div v-if="inn" :class="$style.drawer">
      <client-detail :inn="inn" :editing="editing" />
    </div>
  </v-navigation-drawer>
</div>
</template>

<script>
import {mapGetters} from 'vuex';
import ClientList from '@/components/ClientList/ClientList';
import ClientDetail from '@/components/ClientDetail/ClientDetail';
import RegionalClientList from '@/components/RegionalClientList/RegionalClientList';

export default {
  name: 'ClientListPage',
  components: {
    ClientList,
    ClientDetail,
    RegionalClientList
  },
  props: {
    inn: { type: String },
    view: { type: String }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      accessDealer: 'user/accessDealer',
      accessRegional: 'user/accessRegional',
      userParams: 'user/userParams',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    isDealer() {
      return this.accessDealer;
    },
    isRegional() {
      return this.accessRegional;
    },
    editing() {
      return this.view === 'editing';
    },
    showContent() {
      return (this.userParams && !this.userParams.navigation) || (this.userParams && this.userParams.navigation && this.userParams.navigation[this.$options.name]);
    },
  },
  methods: {
    onClose() {
      this.$router.push({ name: 'ClientListPage' });
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if(this.isDealer) this.$router.push({ name: 'DealerClientListPage'});
      }, 200);
    });
  },
  watch: {
    userParams: {
      handler() {
        if(this.userParams) {
          if(this.userParams.navigation && !this.userParams.navigation[this.$options.name] && this.userParams.defaultPage) this.$router.push({ name: this.userParams.defaultPage});
        }
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root{
  .drawer{
    padding: 6px 16px 16px;
  }
}
</style>
