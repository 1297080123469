<template>
<div :class="$style.root">
  <task-view-row
      v-if="task.type"
      title="Тип"
      :value="[task.type.name]"
      disabled
  />
  <task-view-row title="ИНН" :value="fields.inn" />
  <task-view-row title="Клиент" :value="fields.name" />
  <task-view-row title="Адрес" :value="fields.address" />
  <task-view-row title="Телефон" :value="fields.phone" />
  <task-view-row title="Сайт" :value="fields.site" />
  <task-view-row title="Классификация" :value="fields.classification" :items="classifications" />
  <task-view-row title="ОКВЭД" :value="fields.okved" />
  <task-view-row title="Статус" :value="fields.statusClient" :items="statuses" />
  <panel v-if="task.files && task.files.length" title="Документы">
    <file-table :value="task.files" />
  </panel>
  <panel-difference title="Скидки" :items="fields.sales">
    <template v-slot:default="{ items }">
      <client-discount-table :value="items" />
    </template>
  </panel-difference>
  <panel-difference title="Связанные клиенты" :items="fields.clients" :diff="diff">
    <template v-slot:default="{ items }">
      <client-table :value="items" />
    </template>
  </panel-difference>
  <panel-difference title="Дистрибьюторы" :items="fields.distributors" :diff="diff">
    <template v-slot:default="{ items }">
      <client-distributor-table :value="items" />
    </template>
  </panel-difference>
</div>
</template>

<script>
import { get, includes } from 'lodash';
import TaskForm from '@/mixins/TaskForm/TaskForm';

import Panel from '@/components/Panel/Panel';
import FileTable from '@/components/FileTable/FileTable';
import TaskViewRow from '@/components/TaskViewRow/TaskViewRow';
import ClientTable from '@/components/ClientTable/ClientTable';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import ClientDiscountTable from '@/components/ClientDiscountTable/ClientDiscountTable';
import ClientDistributorTable from '@/components/ClientDistributorTable/ClientDistributorTable';
import STATUSES from '@/api/enums/statuses';

export default {
  name: 'DealerTaskView',
  mixins: [ TaskForm ],
  components: {
    Panel,
    FileTable,
    TaskViewRow,
    ClientTable,
    PanelDifference,
    ClientDiscountTable,
    ClientDistributorTable,
  },
  computed: {
    diff() {
      return !includes([STATUSES.APPROVAL, STATUSES.ADJUSTMENT], get(this.task, 'status.code'));
    },
  }
}
</script>

<style module lang="scss">
.root{}
</style>
