<template>
  <div :class="$style.root">
    <v-tooltip v-if="!isAdminView && (!isCreate || distributor.id)" left>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn color="primary" absolute right fab v-bind="attrs" v-on="on" @click="onCreate">
            <v-icon v-text="'mdi-plus'" />
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Создать заявку</span>
    </v-tooltip>
    <task-list v-if="showContent"/>
    <v-navigation-drawer :value="!!id" app stateless width="430" right mobile-breakpoint="0">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="['Заявка №' + id, 'Новая заявка'][+isCreate]" />
          </v-list-item-content>
          <v-list-item-action>
            <v-row dense align="center">
              <v-col cols="auto" v-if="isAdmin || isAdminView">
                <v-btn icon small :to="{ hash: 'ClientAccountingDialog' }">
                  <v-icon color="grey lighten-1" v-text="'mdi-information-outline'" />
                </v-btn>
              </v-col>
              <v-col cols="auto" v-if="isAdmin && (id !== 'creation')">
                <v-btn icon small :to="{ hash: 'AffiliationDialog' }">
                  <v-icon color="grey lighten-1" v-text="'mdi-graph-outline'" />
                </v-btn>
              </v-col>
              <v-col>
                <v-btn icon @click="onClose">
                  <v-icon color="grey lighten-1" v-text="'mdi-close'" />
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </template>
      <div v-if="id" :class="$style.drawer">
        <task-detail :id="id" />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TaskList from '@/components/TaskList/TaskList';
import TaskDetail from '@/components/TaskDetail/TaskDetail';

export default {
  name: 'TaskListPage',
  components: {
    TaskList,
    TaskDetail,
  },
  props: {
    id: { type: String }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      distributor: 'user/distributor',
      task: 'tasks/current',
      accessDealer: 'user/accessDealer',
      userParams: 'user/userParams',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    isDealer() {
      return this.accessDealer;
    },
    isCreate() {
      return this.id === 'creation';
    },
    showContent() {
      return (this.userParams && !this.userParams.navigation) || (this.userParams && this.userParams.navigation && this.userParams.navigation[this.$options.name]);
    },
  },
  methods: {
    ...mapActions({
      'fetchTypes': 'tasks/fetchTypes',
      'fetchNewStatuses': 'tasks/fetchNewStatuses',
      'fetchStatuses': 'tasks/fetchStatuses',
      'fetchAdvanceDiscountPeriods': 'tasks/fetchAdvanceDiscountPeriods',
    }),
    onCreate() {
      this.$router.push({name: 'TaskListPage', params: { id: 'creation' }});
    },
    onClose() {
      this.$router.push({ name: 'TaskListPage' });
    }
  },
  mounted() {
    this.fetchTypes();
    this.fetchNewStatuses();
    this.fetchStatuses();
    this.fetchAdvanceDiscountPeriods();
    this.$nextTick(() => {
      setTimeout(() => {
        if(this.isDealer) this.$router.push({ name: 'DealerTaskListPage'});
      }, 200);
    });
  },
  watch: {
    userParams: {
      handler() {
        if(this.userParams) {
          if(this.userParams.navigation && !this.userParams.navigation[this.$options.name] && this.userParams.defaultPage) this.$router.push({ name: this.userParams.defaultPage});
        }
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root{
  .drawer{
    padding: 6px 16px 16px;
  }
}
</style>
