<template>
<v-card>
  <v-card-title v-if="title">
    <slot name="title" v-bind="{ title }">
      {{ title }}
    </slot>
  </v-card-title>
  <v-card-text>
    <div :class="$style.inner">
      <slot />
    </div>
  </v-card-text>
</v-card>
</template>

<script>
export default {
  name: 'Well',
  props: {
    title: { props: String }
  }
}
</script>

<style module lang="scss">
.inner {
  max-height: calc(100vh - 180px);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
