<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <h1 :class="$style.title">Авторизация</h1>
      <user-sign-in-form
          @submit="$router.replace({ name: 'ClientListPage' })"
      />
    </div>
  </div>
</template>

<script>
import UserSignInForm from '@/components/UserSignInForm/UserSignInForm';

export default {
  name: 'UserSignInPage',
  components: {
    UserSignInForm,
  },
}
</script>

<style module lang="scss">
.root {
  display: flex;
  min-height: 90vh;
  .inner {
    margin: auto;
    max-width: 100%;
    width: 440px;
  }
  .title {
    margin-bottom: 30px;
    font-weight: normal;
    text-align: center;
  }
}
</style>
