<template>
  <div :class="$style.root">
    <v-tooltip v-if="!isAdminView && (!isCreate || distributor.id)" left>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn color="primary" absolute right fab v-bind="attrs" v-on="on" @click="onCreate">
            <v-icon v-text="'mdi-plus'" />
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Создать заявку</span>
    </v-tooltip>
    <dealer-task-list :reload="reload"/>
    <v-navigation-drawer :value="!!id" app stateless width="430" right mobile-breakpoint="0">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="['Заявка №' + id, 'Новая заявка'][+isCreate]" />
          </v-list-item-content>
          <v-list-item-action>
            <v-row dense align="center">
              <v-col>
                <v-btn icon @click="onClose">
                  <v-icon color="grey lighten-1" v-text="'mdi-close'" />
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </template>
      <div v-if="id" :class="$style.drawer">
        <dealer-task-detail :id="id" />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DealerTaskList from '@/components/DealerTaskList/DealerTaskList';
import DealerTaskDetail from '@/components/DealerTaskDetail/DealerTaskDetail';

export default {
  name: 'DealerTaskListPage',
  components: {
    DealerTaskList,
    DealerTaskDetail,
  },
  props: {
    id: { type: String },
    reload: { type: Boolean }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      distributor: 'user/distributor',
      task: 'tasks/current',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    isCreate() {
      return this.id === 'creation';
    },
  },
  methods: {
    ...mapActions({
      'fetchTypes': 'tasks/fetchTypes',
      'fetchNewStatuses': 'tasks/fetchNewStatuses',
      'fetchStatuses': 'tasks/fetchStatuses',
    }),
    onCreate() {
      this.$router.push({name: 'DealerTaskListPage', params: { id: 'creation' }});
    },
    onClose() {
      this.$router.push({ name: 'DealerTaskListPage' });
    }
  },
  mounted() {
    this.fetchTypes();
    this.fetchNewStatuses();
    this.fetchStatuses();
  }
}
</script>

<style module lang="scss">
.root{
  .drawer{
    padding: 6px 16px 16px;
  }
}
</style>
