<template>
  <v-form v-model="valid" ref="form" @submit.prevent="onSubmit({ name, password, newPassword, notSendEvent })">
    <v-text-field
        name="name"
        class="mb-6 mt-2"
        label="Фамилия Имя Отчество"
        outlined
        hide-details="auto"
        v-model="name"
        :rules="[rules.required]"
    />
    <v-text-field
        class="mb-6"
        label="Связь с дистрибьютором"
        outlined
        disabled
        hide-details="auto"
        v-model="distributor"
    />
    <v-text-field
        class="mb-6"
        label="Роль"
        outlined
        disabled
        hide-details="auto"
        v-model="role"
    />
    <div class="mb-6">
      <v-text-field
          name="password"
          class="mb-2"
          label="Текущий пароль"
          outlined
          type="password"
          hide-details="auto"
          v-model="password"
          :rules="[v => !!v || 'Для сохранения изменений введите текущий пароль',]"
      />
    </div>
    <div class="mb-6">
      <div v-if="change" class="mb-6">
        <v-text-field
            class="mb-6"
            label="Новый пароль"
            outlined
            type="password"
            hide-details="auto"
            v-model="newPassword"
            :rules="[rules.required]"
        />
        <v-text-field
            label="Повторите пароль"
            outlined
            type="password"
            hide-details="auto"
            v-model="newPasswordRepeat"
            :rules="[rules.required, v => (v === newPassword) || 'Пароли не совпадают']"
        />
      </div>
      <v-btn
          v-text="['Изменить пароль', 'Отмена'][+change]"
          depressed
          @click="onCollapse"
      />
      <h3 class="mt-4">
        Уведомления и рассылки
      </h3>
      <div class="not-send-event">
        <v-checkbox
                name="notSendEvent"
                v-model="notSendEvent"
                :label="'Не получать почтовые уведомления и рассылки'"
                style="font-size: 12px"
        ></v-checkbox>
      </div>
    </div>
    <v-alert v-if="error" dense outlined type="error">
      <div v-html="error.error_description" />
    </v-alert>
    <div>
      <v-btn
          v-text="'Сохранить'"
          depressed
          color="primary"
          type="submit"
      />
      <v-btn
          v-text="'Закрыть'"
          depressed
          class="ml-3"
          @click="onReset"
      />
    </div>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import {get} from 'lodash';
export default {
  name: 'UserEditForm',
  props: {
    value: { type: Object },
  },
  data() {
    return {
      valid: true,
      error: null,
      name: get(this.value, 'name', ''),
      distributor: get(this.value, 'distributor.name', ''),
      role: get(this.value, 'role.name', ''),
      password: '',
      newPassword: '',
      newPasswordRepeat: '',
      change: false,
      rules: {
        required: v => !!v || 'Обязательное поле',
      },
      notSendEvent: (get(this.value, 'notSendEvent', '') === '1'),
    }
  },
  methods: {
    ...mapActions({
      fetch: 'user/fetch',
      update: 'user/update',
    }),
    reset() {
      this.name = get(this.value, 'name', '');
      this.distributor = get(this.value, 'distributor.name', '');
      this.role = get(this.value, 'role.name', '');
      this.password = '';
      this.newPassword = '';
      this.newPasswordRepeat = '';
      this.notSendEvent = (get(this.value, 'notSendEvent', '') === '1');
    },
    onCollapse() {
      this.change = !this.change;
      this.newPassword = '';
      this.newPasswordRepeat = '';
    },
    onSubmit({ name, password, newPassword, notSendEvent }) {
      this.$refs.form.validate();
      if (this.valid) {
        this.error = null;
        this.update({ name, password, newPassword, notSendEvent })
        .then(() => this.$emit('submit'))
        .catch((error) => (this.error = error));
      }
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    }
  },
  beforeMount() {
    this.fetch().then(() => {
      this.reset();
    });
  },
}
</script>

<style module lang="scss"></style>
