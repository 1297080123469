<template>
  <v-app>
    <vue-extend-layouts />
    <dialog-manager />
  </v-app>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import VueExtendLayouts from 'vue-extend-layout';
import DialogManager from '@/components/DialogManager/DialogManager';

export default {
  name: 'App',
  components: {
    VueExtendLayouts,
    DialogManager,
  },
  computed: {
    ...mapGetters({
      id: 'user/id',
    })
  },
  methods: {
    ...mapActions({
      init: 'init',
      signIn: 'user/signIn',
    }),
    resize: debounce(function() {
      const meta = document.head.querySelector('meta[name="viewport"]');
      meta.content = window.outerWidth > 485 ?
          'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui':
          'width=485'
    }, 500),
  },
  beforeMount() {
    this.id && this.init();
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    id(id) { id && this.init() }
  }
}
</script>
