<template>
  <div :class="$style.root" v-if="items.length">
    <v-tooltip v-for="{ text, icon, color, disabled, action } in items" bottom :key="text">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-2" v-bind="attrs" v-on="on" depressed :disabled="disabled" :color="color" @click="action">
          <v-icon v-if="icon" v-text="icon"/>
          <template v-else>{{ text }}</template>
        </v-btn>
      </template>
      <span>{{ text }}</span>
    </v-tooltip>
    <slot/>
  </div>
</template>

<script>
import { includes } from 'lodash';
import { mapGetters } from 'vuex';
import STATUSES from '@/api/enums/promotion';

export default {
  name: 'PromotionsStatusSwitch',
  props: {
    value: {type: String},
    disabled: {type: Boolean},
    editingView: {type: Boolean},
  },
  computed: {
    ...mapGetters({
      access: 'access'
    }),
    isAdmin() {
      return this.access(({ADMIN}, ROLE) => ADMIN === ROLE);
    },
    items() {
      const items = [];
      if (this.isAdmin) {
        if (includes([STATUSES.TOAPPROVE], this.value)) {
          items.push(
              {
                text: 'Согласовать',
                icon: 'mdi-check-circle-outline',
                color: 'success',
                action: () => this.$emit('input', STATUSES.APPROVED),
              },
              {
                text: 'На корректировку',
                icon: 'mdi-chevron-double-right',
                color: 'warning',
                action: () => this.$emit('input', STATUSES.ADJUSTMENT),
              },
              {
                text: 'Отклонить',
                icon: 'mdi-close-circle-outline',
                color: 'error',
                action: () => this.$emit('input', STATUSES.REJECTED),
              },
          );
        }
      } else {
        if (this.editingView) {
          items.push(
              {
                text: 'Сохранить',
                color: 'primary',
                action: () => this.$emit('input', this.value),
              },
              {
                text: 'Отмена',
                action: () => this.$emit('reset', STATUSES.CANCEL),
              },
          );
        } else if (includes([STATUSES.CREATURE], this.value)) {
          items.push(
              {
                text: 'Создать акцию',
                color: 'primary',
                disabled: this.disabled,
                action: () => this.$emit('input', STATUSES.TOAPPROVE),
              },
          );
        } else if (includes([STATUSES.ADJUSTMENT], this.value)) {
          items.push(
              {
                text: 'На согласование',
                color: 'primary',
                disabled: this.disabled,
                action: () => this.$emit('input', STATUSES.TOAPPROVE),
              },
              {
                text: 'Отменить акцию',
                icon: 'mdi-close-circle-outline',
                color: 'error',
                action: () => this.$emit('input', STATUSES.REJECTED),
              },
          );
        }
      }

      return items;
    }
  },
}
</script>

<style module lang="scss">
.root {
}
</style>
