<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <task-form
          v-if="editing"
          :task="task"
          :key="task.id"
          :taskType="type"
          @submit="onSubmit"
      />
      <task-view v-else :task="task" :taskType="type"/>
    </div>
    <panel v-if="task.history && task.history.length" title="История изменения заявки">
      <task-history-table :value="task.history" />
    </panel>
    <panel v-if="commenting || (task.comments && task.comments.length)" title="Комментарии">
      <comment-list :value="task.comments">
        <comment-creation-form v-if="commenting && !isAdminView" :id="task.id" />
      </comment-list>
    </panel>
    <div v-if="pending" :class="$style.loader" />
  </div>
</template>

<script>
import { get, includes, filter } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import STATUSES from '@/api/enums/statuses';

import Panel from '@/components/Panel/Panel';
import TaskView from '@/components/TaskView/TaskView';
import TaskForm from '@/components/TaskForm/TaskForm';
import CommentList from '@/components/CommentList/CommentList';
import TaskHistoryTable from '@/components/TaskHistoryTable/TaskHistoryTable';
import CommentCreationForm from '@/components/CommentCreationForm/CommentCreationForm';
import { taskEmptyAdapter } from '@/store/tasks/utils';

export default {
  name: 'TaskDetail',
  components: {
    Panel,
    TaskView,
    TaskForm,
    CommentList,
    TaskHistoryTable,
    CommentCreationForm,
  },
  data () {
    return {
      type: 'task',
    }
  },
  props: {
    id: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'tasks/pending',
      current: 'tasks/current',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    editing() {
      const status = get(this.task, 'status.code');
      return includes([STATUSES.CREATURE], status) || !this.isAdmin && includes([STATUSES.ADJUSTMENT], status);
    },
    commenting() {
      return includes([STATUSES.APPROVAL, STATUSES.ADJUSTMENT], get(this.task, 'status.code'));
    },
    history() {
      return filter(get(this.task, 'history', []), { field: 'Тип' });
    },
    isCreate() {
      return this.id === 'creation';
    },
    task() {
      if (this.isCreate) return taskEmptyAdapter();
      return this.current;
    }
  },
  methods: {
    ...mapActions({
      'fetchCurrent': 'tasks/fetchCurrent',
      'removeCurrent': 'tasks/removeCurrent',
    }),
    onUpdate() {
      this.isCreate || this.fetchCurrent(this.id);
    },
    onSubmit(id) {
      this.$router.push({ name: 'TaskListPage', params: { id } });
    },
  },
  watch: {
    id() {
      this.onUpdate();
    }
  },
  beforeMount() { this.onUpdate() },
  beforeDestroy() { this.removeCurrent() }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .inner {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
