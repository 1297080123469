import { toString } from 'lodash';
import { Api } from '@/api';
export class Accounting extends Api {
  data(inn) {
    return this.request('clients.findOrgAccounting', {
      params: {
        inn: toString(inn),
      }
    })
  }
}
export default new Accounting();
