import { get, toString } from 'lodash';
import { Api } from '@/api';

export class User extends Api {

  /**
   * Проверка на авторизованность пользователя
   * @returns {Promise<boolean>}
   */
  async auth() {
    return this.request('user.auth').then((result) => !!get(result, 'isAuthorized'));
  }

  /**
   * Метод вернет профиль авторизованного пользователя
   * @returns {Promise<*>}
   */
  async info() {
    return this.request('user.info');
  }

  /**
   * Метод вернет параметры пользователя
   * @returns {Promise<*>}
   */
  async getParams() {
    return this.request('user.getParams');
  }

  /**
   * Обновление информации о пользователе
   * @param name
   * @param password
   * @param newPassword
   * @returns {Promise<*>}
   */
  async update(name, password, newPassword, notSendEvent) {
    return this.request('user.update', {
      method: 'POST',
      data: this.toFormData(newPassword ? {
        name: toString(name),
        password: toString(password),
        newPassword: toString(newPassword),
        newPasswordRepeat: toString(newPassword),
        notSendEvent: (notSendEvent?1:0)
      } : {
        name: toString(name),
        password: toString(password),
        notSendEvent: (notSendEvent?1:0)
      })
    });
  }

  /**
   * Метод авторизации по логину и паролю
   * @param login
   * @param password
   * @returns {Promise<*>}
   */
  async signIn(login, password) {
    return this.request('user.auth', {
      method: 'POST',
      data: this.toFormData({
        authForm: 'Y',
        type: 'auth',
        userLogin: toString(login),
        userPassword: toString(password),
      }),
    }).then(response => {
      if (get(response, 'result.isAuthorized')) localStorage.setItem('auth', 'Y');
      return response;
    });
  }

  /**
   * Метод выхода из системы
   */
  async signOut() {
    return this.request('user.auth', {
      method: 'GET',
      params: {
        logout: 'yes',
      },
    }).then(response => {
      if (!get(response, 'result.isAuthorized')) localStorage.removeItem('auth');
      return response;
    });
  }

  /**
   * Метод отправки строки сброса пароля на привязаный email
   * @param login
   * @returns {Promise<*>}
   */
  async sendPassword(login) {
    return this.request('user.auth', {
      method: 'POST',
      data: this.toFormData({
        authForm: 'Y',
        type: 'sendPwd',
        userLogin: toString(login),
      }),
    });
  }

  /**
   * Метод установки нового пароля
   * @param login
   * @param password
   * @param word
   * @returns {Promise<*>}
   */
  async resetPassword(login, password, word) {
    return this.request('user.auth', {
      method: 'POST',
      data: this.toFormData({
        authForm: 'Y',
        type: 'changePwd',
        userLogin: toString(login),
        userCheckWord: toString(word),
        userPassword: toString(password),
        userConfirmPassword: toString(password),
      }),
    });
  }

  /**
   * Отправка вопроса в тех поддержку
   * @param question
   * @returns {Promise<*>}
   */
  async sendQuestionForm(question) {
    return this.request('user.sendQuestionForm', {
      method: 'POST',
      data: this.toFormData({
        question: question
      })
    });
  }

  /**
   * Отправка вопроса в тех поддержку дистрибутору, для неавторизированных
   * @param data
   * @returns {Promise<*>}
   */
  async sendDistributorQuestionForm(data) {
    return this.request('user.sendDistributorQuestionForm', {
      method: 'POST',
      data: this.toFormData(data)
    });
  }

  /**
   * Метод вернет параметры каптчи
   * @returns {Promise<*>}
   */
  async getCaptcha() {
    return this.request('user.getCaptcha');
  }
}

export default new User();
