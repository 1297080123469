<template>
  <div :class="$style.root">
    <v-form ref="form" v-model="valid" :class="$style.root">
      <audit-status-switch class="mb-6" :value="status" :disabled="formValid" @input="onSubmit" @reset="onReset" />
      <v-alert v-if="error" class="mb-6" dense outlined type="error">
        <div v-html="error.error_description" />
      </v-alert>
      <panel title="Дистрибутор">
        <audit-distributor-field
                :name="fields.distributor || ''"
                :value="fields.distributor || ''"
                @input="(value) => onDistributorInput(value)"
                @word="onDistributorInputWord"
        />
      </panel>
      <panel v-if="!isAuditor" title="Клиент">
        <audit-form-row
                title="ИНН"
                v-model="fields.inn"
                :component="isCreate ? 'inn-field': null"
                :disabled="!isCreate"
                :check="['required', 'inn', 'not']"
                @update="onUpdateInn"
                @innString="onInnInputWord"
        />
        <audit-form-row title="Название" v-model="fields.name" v-if="this.correct" disabled/>
      </panel>

      <panel-difference v-if="distributorChoose && isAuditor" title="Клиенты" :items="fields.clients">
        <template v-slot:default="{ items, index }">
          <client-table v-if="viewOnly" :value="items" />
          <audit-client-attach-table v-else-if="isAuditor" v-model="fields.clients[index]" :checkInn="['required', 'relatedByDistributor', 'inn', 'duplicate']"  :current="values.inn" :distributor="fields.distributor" />
          <audit-client-attach-table v-else v-model="fields.clients[index]" :checkInn="['required', 'inn', 'duplicate', 'not']" :current="values.inn" />
        </template>
      </panel-difference>

      <panel v-if="!distributorChoose && isAuditor" title="Не зависимый ИНН">
        <audit-form-row
                title="ИНН"
                v-model="fields.inn"
                :component="isCreate ? 'inn-field': null"
                :disabled="!isCreate"
                :check="['inn']"
                @update="onUpdateInn"
                @innString="onInnInputWord"
        />
        <audit-form-row
                title="Не зависимый клиент"
                v-model="fields.name"
                v-if="this.correct"
                disabled
        />
        <div v-if="this.correct && distributors.length" :class="$style.root">
          <v-row dense>
            <v-col :cols="12">
              <div :class="$style.label">Привязанные дистрибуторы</div>
              <div :class="$style.value" v-for="(val, index) in distributors" :key="index"><a href="#" @click="onDistributorChoose(val.id,val.client)">{{ val.name }}</a></div>
            </v-col>
          </v-row>
        </div>
        <audit-form-row v-else-if="this.correct"
                title="Укажите название дитрибутора"
                v-model="fields.distributorName"
        />
      </panel>
      <panel title="Комментарий">
        <audit-form-row title="Комментарий" component="v-textarea" v-model="fields.comment"/>
      </panel>
    </v-form>
  </div>
</template>

<script>
import { get, head, toNumber, forEach, findIndex } from 'lodash';
import client from '@/store/clients/api';
import { clientItemAdapter } from '@/store/clients/utils';
import { auditEmptyAdapter } from '@/store/audit/utils';
import {mapGetters} from 'vuex';

import AuditForm from '@/mixins/AuditForm/AuditForm';
import Panel from '@/components/Panel/Panel';
import AuditFormRow from '@/components/AuditFormRow/AuditFormRow';
import ClientTable from '@/components/ClientTable/ClientTable';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import AuditStatusSwitch from '@/components/AuditStatusSwitch/AuditStatusSwitch';
import AuditClientAttachTable from '@/components/AuditClientAttachTable/AuditClientAttachTable';
import AuditDistributorField from '@/components/AuditDistributorField/AuditDistributorField';

export default {
  name: 'AuditForm',
  mixins: [ AuditForm ],
  components: {
    Panel,
    AuditFormRow,
    ClientTable,
    PanelDifference,
    AuditStatusSwitch,
    AuditClientAttachTable,
    AuditDistributorField,
  },
  data() {
    return {
      correct: false,
      inner: false,
      related: false,
      distributors: []
    };
  },
  computed: {
    ...mapGetters({
      access: 'access',
      distributor: 'user/distributor',
      accessAuditor: 'user/accessAuditor',
      accessAuditorRM: 'user/accessAuditorRM'
    }),
    viewOnly() {
      return !this.isCreate ;
    },
    distributorChoose: function () {
      return !!this.fields.distributor;
    },
    formValid: function () {
      return !(((!!this.fields.distributor && !!this.fields.clients[0].length) || (!!this.fields.inn && !!this.fields.inn[0]) || (!this.isAuditor && !!this.fields.clients[0].length)) && this.valid);
    },
    isAuditor() {
      return this.accessAuditor || this.accessAuditorRM;
    },
  },
  methods: {
    onDistributorInput(id, client) {
      if(this.isAuditor) {
        this.reset();
        this.$refs.form.validate();
        this.fields.distributor = id;
        if (client) this.fields.clients[0][0] = client;
      }
      else {
        this.fields.distributor = id;
      }
      if (this.error) {
        this.error = {};
      }
    },
    onDistributorInputWord(word) {
      if(!word) {
        if(this.isAuditor) {
          this.reset();
          this.$refs.form.validate();
        }
        this.fields.distributor = false;
        this.distributors = [];
      }
      if(this.error) this.error=false;
    },
    onInnInputWord(word) {
      this.error=false;
      if(word && !this.valid) this.correct=false;
    },
    onDistributorChoose(distId, client) {
      if(Array.isArray(distId)) distId=distId[0];
      this.onDistributorInput(distId, client);
    },
    onUpdateInn(items) {
      this.distributors=[];
      const item = head(items) || {};
      const code = item.code || this.values.inn;
      this.correct = !!get(item, 'code', false);
      this.inner = get(item, 'inner', false);
      const innName = get(item, 'name', false);

      if (this.isCreate) {
        this.$refs.form.validate();
        this.fields.name=[innName];
        code && this.$set(this.fields.inn, 0, code);
        if (this.inner && code) {
          client.item(code).then(result => {
            this.distributors=[];
            if(result.result.displayProperties.distributors.value) {
              if(!Array.isArray(result.result.displayProperties.distributors.displayValue)) {
                this.distributors=[{
                  name: result.result.displayProperties.distributors.displayValue.replace(/<\/?[^>]+(>|$)/g, ""),
                  id: result.result.displayProperties.distributors.value,
                  client: {id:result.result.id,inn:result.result.code,name:result.result.name}
                }];
              }
              else {
                forEach(result.result.displayProperties.distributors.value, (value, index) => {
                  this.distributors.push({
                    name:result.result.displayProperties.distributors.displayValue[index].replace(/<\/?[^>]+(>|$)/g, ""),
                    id:value,
                    client: {id:result.result.id,inn:result.result.code,name:result.result.name}
                  });
                });
              }
            }
            const client = auditEmptyAdapter(clientItemAdapter(result.result));
            if (client.fields) {
              this.fields.name=client.fields.name;
            }
          })
        }

        this.$snack({
          text: `Клиент ${['не', ''][toNumber(this.inner)]} найден в базе iPrice`,
          pos: 'top-center',
          actionText: 'Понятно',
        });
      }
    },
    onSubmit(status) {
      this.$refs.form.validate();
      if (this.valid) {
        // Если какой то из ИНН не найден в базе клиентов, отправляем всех через ИНН
        if(this.fields.clients[0] && !!(findIndex(this.fields.clients[0], {id: ''}) + 1)) {
          this.fields.inn=[];
          forEach(this.fields.clients[0], (value) => {
            this.fields.inn.push(value.inn);
          });
        }
        this.submit(status, this.inner);
      }
    },
    onReset(){
      this.reset();
      this.$emit('reset');
    },
  },
  watch: {
    'statuses': {
      handler() {
        // Произойдет только если статусы не успели загрузиться на момент рендера
        this.onReset();
      },
    },
  }
}
</script>

<style module lang="scss">
.root{}
.label {
    font-size: 12px;
    color: rgba(0,0,0,.6);
    &:not(:last-child) {
        margin-bottom: 6px;
    }
}
</style>
