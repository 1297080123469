<template>
  <v-data-table
      dense
      must-sort
      :hide-default-footer="count < 6"
      :class="$style.root"
      :items-per-page="5"
      :items="items"
      :item-class="({ cls, blocked }) => [$style.tr, cls, { [$style.blocked]: blocked }]"
      :headers="hasRealizations ? [
        {value: 'inn', text: 'ИНН'},
        {value: 'name', text: 'Имя'},
        {value: 'realizations.period', text: 'Период'},
        {value: 'realizations.sum', text: 'Сумма, руб.'},
      ] : [
        {value: 'inn', text: 'ИНН'},
        {value: 'name', text: 'Имя'},
      ]"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, -1],
        itemsPerPageText: '',
      }"
      @click:row="({ inn, blocked }) => blocked || $router.push({ name: 'ClientListPage', params: { inn } })"
  />
</template>

<script>
import { get, map, find } from 'lodash';

export default {
  name: 'AuditClientTable',
  props: {
    value: {
      type: Array,
    },
  },
  computed: {
    hasRealizations() {
      return !!find(this.items, 'realizations.id');
    },
    hasMultiplication() {
      return find(this.items, 'multiplication');
    },
    items() {
      return map(this.value, (item) => item);
    },
    count() {
      return get(this, 'value.length', 0);
    }
  }
}
</script>

<style module lang="scss">
.root {
  white-space: nowrap;
  .tr {
    cursor: pointer;
    &.blocked { pointer-events: none }
    td:first-child {
      width: 1px;
      padding-right: 0;
    }
  }
  :global {
    .v-data-footer{
      justify-content: space-between;
      padding: 0;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
