import { get, includes, map, set, toNumber } from 'lodash';
import classifications from '@/store/classifications/api';
import { classificationItemAdapter } from '@/store/classifications/utils';

const START = 'START';
const END = 'END';
const UPDATE_COUNT = 'UPDATE_COUNT';
const UPDATE_ITEMS = 'UPDATE_ITEMS';

export default {
  namespaced: true,
  state: {
    items: [],
    count: 0,
    pending: {
      fetchItems: false,
      fetchExel: false,
    }
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    items(state) {
      return map(state.items, classificationItemAdapter);
    },
    count(state) {
      return toNumber(state.count);
    },
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [UPDATE_ITEMS](state, items) {
      state.items = items;
    },
    [UPDATE_COUNT](state, count) {
      state.count = count;
    },
  },
  actions: {
    async fetchItems({ commit }, { page, size, sort, filter }) {
      commit(START, 'fetchItems');
      const { result } = await classifications.list(page, size, sort, filter);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_ITEMS, result.items);
        commit(UPDATE_COUNT, toNumber(get(result, 'count', 0)));
      }
      commit(END, 'fetchItems');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchExel({ commit }, { page, size, sort, filter }) {
      commit(START, 'fetchExel');
      const { result } = await classifications.excel(page, size, sort, filter);
      const success = !get(result, 'error');
      if (success) {
        const url = get(result, 'file.path');
        const name = get(result, 'file.name', 'list');
        if (url) {
          const a = document.createElement('a');
          a.href = url;
          a.download = name;
          a.click();
          a.remove();
        }
      }
      commit(END, 'fetchExel');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
  },
}
