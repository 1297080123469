<template>
  <v-form v-model="valid" ref="form" @submit.prevent="onSubmit()">
    <div class="mt-4 mb-4">
      <v-textarea
              name="question"
              v-model="question"
              label="Опишите вашу проблему"
              outlined
              dense
              hide-details="auto"
              class="mt-4"
              :rules="[rules.required]"
              v-if="!result"
      ></v-textarea>
    </div>
    <v-alert v-if="result" dense outlined type="success">
      <div v-html="result" />
    </v-alert>
    <v-alert v-if="error" dense outlined type="error">
      <div v-html="error.error_description" />
    </v-alert>
    <div>
      <v-btn
          v-text="'Отправить'"
          depressed
          color="primary"
          type="submit"
          class="mr-3"
          v-if="!result"
      />
      <v-btn
          v-text="'Закрыть'"
          depressed
          @click="onReset"
      />
    </div>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'QuestionForm',
  props: {
    value: { type: Object },
  },
  data() {
    return {
      valid: true,
      error: null,
      question: '',
      result: null,
      rules: {
        required: v => !!v || 'Обязательное поле',
      },
    }
  },
  methods: {
    ...mapActions({
      sendQuestionForm: 'user/sendQuestionForm',
    }),
    reset() {
      this.question = '';
    },
    onSubmit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.error = null;
        this.sendQuestionForm({ question:this.question })
        .then(() => {
          this.result = 'Спасибо за Ваше обращение!';
        })
        .catch((error) => (this.error = error));
      }
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    }
  },
  beforeMount() {
    this.reset();
  },
}
</script>

<style module lang="scss"></style>
