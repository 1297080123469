<template>
<div :class="$style.root">
  <div :class="$style.inner">
    <h1 :class="$style.title">Восстановление пароля</h1>
    <div class="text-center">
      <p>На указанную почту придет письмо со ссылкой для восстановления пароля.</p>
    </div>
    <user-recovery-form
        @reset="$router.back()"
        @submit="$router.push({ name: 'UserRecoverySuccessPage' })"
    />
  </div>
</div>
</template>

<script>
import UserRecoveryForm from '@/components/UserRecoveryForm/UserRecoveryForm';
export default {
  name: 'UserRecoveryPage',
  components: {
    UserRecoveryForm,
  }
}
</script>

<style module lang="scss">
.root {
  display: flex;
  min-height: 90vh;
  .inner {
    margin: auto;
    max-width: 100%;
    width: 440px;
  }
  .title {
    margin-bottom: 10px;
    font-weight: normal;
    text-align: center;
  }
}
</style>
