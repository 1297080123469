<template>
  <div :class="$style.root">
    <v-form ref="form" :class="$style.root">
      <v-btn class="mr-2 mb-6" v-on="on" depressed :disabled="!files || !files.length" @click="onSubmit">
        Импортировать
      </v-btn>
      <v-alert v-if="error" class="mb-6" dense outlined type="error">
        <div v-html="error.error_description" />
      </v-alert>
      <panel v-if="isAdmin" title="Дистрибутор"><audit-distributor-field :value="distributor" @input="(value) => onDistributorInput(value)"/></panel>
      <panel title="Загрузите файл Excel">
        <file-single :value="importFile" @input="(value) => (files = value) " />
      </panel>
    </v-form>
    <div v-if="pending" :class="$style.loader" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Panel from '@/components/Panel/Panel';
import FileSingle from '@/components/FileSingle/FileSingle';
import AuditDistributorField from '@/components/AuditDistributorField/AuditDistributorField';

export default {
  name: 'ImportExcelDetail',
  components: {
    Panel,
    FileSingle,
    AuditDistributorField
  },
  props: {
    files: { type: Array },
    distributor: { type: String }
  },
  data(){
    return {
      pending: false,
      importFile: [],
      error: null,
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'clients/pending',
      client: 'clients/current',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
  },
  methods: {
    ...mapActions({
      'fetchImportExcel': 'clients/fetchImportExcel'
    }),
    onDistributorInput(id) {
      this.distributor=id;
    },
    onSubmit() {
      this.error=false;
      this.pending=true;
      if(this.isAdmin && !this.distributor) {
        this.error = {
          error: true,
          error_description: 'Укажите дитрибутора загружаемых продаж!'
        };
        this.pending=false;
      }
      else {
        this.fetchImportExcel({files: this.files, distributor: this.distributor})
            .then(response => {
              if (response.error) {
                this.error = {
                  error_description: response.message
                };
              } else {
                this.$router.push({name: 'RelevanceListPage', params: {reload: true}});
                console.log('Успешно выгружено клиентов: ' + response.count);
              }
              this.pending = false;
            });
      }
    },
  }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
