<template>
    <div :class="$style.root">
        <div :class="$style.inner">
            <user-form
                    v-if="isCreate"
                    :employee="employee"
                    :key="employee.id"
                    @submit="onSubmit"
            />
            <user-view v-else :employee="employee"/>
        </div>
        <div v-if="pending" :class="$style.loader"/>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';

    import UserView from '@/components/UserView/UserView';
    import UserForm from "@/components/UserForm/UserForm";

    import {employeeEmptyAdapter} from "../../store/employee/utils";

    export default {
        name: 'UserDetail',
        components: {
            UserView,
            UserForm,
        },
        props: {
            id: {type: String, required: true},
        },
        computed: {
            ...mapGetters({
                pending: 'employee/pending',
                current: 'employee/current',
            }),
            isCreate() {
                return this.id === 'creation';
            },
            employee() {
                if (this.isCreate) {
                    return employeeEmptyAdapter();
                }
                return this.current;
            }
        },
        methods: {
            ...mapActions({
                'fetchCurrent': 'employee/fetchCurrent',
                'removeCurrent': 'employee/removeCurrent',
            }),
            onUpdate() {
                if(!this.isCreate) {
                    this.fetchCurrent(this.id).then(() => {
                        if (this.current.id === '') {
                            this.$router.push({name: 'UserListPage', params: {reload: true}});
                        }
                    });
                }
            },
            onSubmit() {
                this.$router.push({name: 'UserListPage', params: {reload: true}});
            },
        },
        watch: {
            id() {
                this.onUpdate();
            },
        },
        beforeMount() {
            this.onUpdate()
        },
        beforeDestroy() {
            this.removeCurrent()
        }
    }
</script>

<style module lang="scss">
    .root {
        position: relative;

        .inner {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        .loader {
            background: rgba(255, 255, 255, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
