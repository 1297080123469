import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import Snackbar from 'node-snackbar';
import _ from 'lodash';
import { store } from './store';
import { router } from './router';
import { vuetify } from './plugins';

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'node-snackbar/dist/snackbar.css';

import '@/styles/main.scss';

import App from './App';

Vue.prototype.$snack = Snackbar.show;
Vue.prototype._ = _;
Vue.config.productionTip = false;

sync(store, router);

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app');
