<template>
  <div :class="$style.root">
    <v-form ref="form" v-model="valid" :class="$style.root">
      <task-status-switch class="mb-6" :value="status" :disabled="!modified || !valid" @input="onSubmit" @reset="onReset" />
      <v-alert v-if="error" class="mb-6" dense outlined type="error">
        <div v-html="error.error_description" />
      </v-alert>
      <task-form-row
          v-if="task.type"
          title="Тип"
          :value="[task.type.name]"
          disabled
      />
      <task-form-row
          title="ИНН"
          v-model="fields.inn"
          :component="isCreate ? 'inn-field': null"
          :check="['required', 'inn', 'not', 'duplicate', 'inner']"
          :disabled="!isCreate"
          @update="onUpdate"
      />
      <task-form-row
          title="Клиент"
          v-model="fields.name"
          :rules="[rules.required]"
          disabled
      />
      <task-form-row
          title="Адрес"
          v-model="fields.address"
          :rules="[rules.required]"
          disabled
      />
      <task-form-row
          title="Телефон"
          v-model="fields.phone"
          disabled
      />
      <task-form-row
          title="Сайт"
          v-model="fields.site"
          disabled
      />
      <task-form-row
          title="Классификация"
          v-model="fields.classification"
          :rules="[rules.required]"
          :items="classifications"
          disabled
      />
      <task-form-row
          title="ОКВЭД"
          v-model="fields.okved"
          disabled
      />
      <task-form-row
          title="Статус"
          v-model="fields.statusClient"
          :items="statusesByClassification"
          disabled
      />
      <panel-difference title="Связанные клиенты" :items="fields.clients">
        <client-table :value="items" />
      </panel-difference>
      <panel-difference title="Скидки" :items="fields.sales">
        <client-discount-table :value="items" />
      </panel-difference>
      <panel-difference v-if="isAdmin" title="Дистрибьюторы" :items="fields.distributors">
        <client-distributor-table />
      </panel-difference>
    </v-form>
  </div>
</template>

<script>
import { get, head, toNumber, map, filter, includes, last, compact } from 'lodash';
import client from '@/store/clients/api';
import { enumerationAdapter } from '@/store/utils';
import { clientItemAdapter } from '@/store/clients/utils';
import { taskEmptyAdapter } from '@/store/tasks/utils';

import TaskForm from '@/mixins/TaskForm/TaskForm';
import TaskFormRow from '@/components/TaskFormRow/TaskFormRow';
import ClientTable from '@/components/ClientTable/ClientTable';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import TaskStatusSwitch from '@/components/TaskStatusSwitch/TaskStatusSwitch';
import ClientDiscountTable from '@/components/ClientDiscountTable/ClientDiscountTable';
import ClientDistributorTable from '@/components/ClientDistributorTable/ClientDistributorTable';

export default {
  name: 'DealerTaskForm',
  mixins: [ TaskForm ],
  components: {
    TaskFormRow,
    ClientTable,
    PanelDifference,
    TaskStatusSwitch,
    ClientDiscountTable,
    ClientDistributorTable,
  },
  data() {
    return {
      statusesByClassification: this.statuses,
      correct: false,
      inner: false,
      related: false,
    };
  },
  computed: {
    viewOnly() {
      return !!(this.isCreate && ((this.inner && !this.related) || (this.related))) ;
    },
  },
  methods: {
    onUpdate(items) {
      if(this.error) {
        this.error=null;
      }
      const item = head(items) || {};
      const { name, phone, address, okved } = item;
      const code = item.code || this.values.inn;
      this.correct = !!get(item, 'code', false);
      this.inner = get(item, 'inner', false);
      this.related = get(item, 'related', false);
      if (this.isCreate) {
        this.reset();
        this.$refs.form.validate();

        code && this.$set(this.fields.inn, 0, code);
        name && this.$set(this.fields.name, 0, name);
        phone && this.$set(this.fields.phone, 0, phone);
        address && this.$set(this.fields.address, 0, address);
        okved && this.$set(this.fields.okved, 0, okved);

        if (this.inner && code) {
          client.item(code).then(result => {
            const client = taskEmptyAdapter(clientItemAdapter(result.result));
            if (client.fields) {
              this.fields = client.fields;
            }
          })
        }

        this.$snack({
          text: `Клиент ${['не', ''][toNumber(this.inner)]} найден в базе iPrice`,
          pos: 'top-center',
          actionText: 'Понятно',
        });
      }
    },
    onSubmit(status) {
      this.$refs.form.validate();
      if (this.valid) {
        this.submit(status, this.inner);
      }
    },
    onReset(){
      this.reset();
      this.$emit('reset');
    },
    onUpdateStatusesByClassification() {
      const statuses = this.statuses;
      const classification = this.values.classification;
      this.statusesByClassification = statuses;
      classification && client.statuses(classification).then(result => {
        const currents = this.fields.statusClient;
        const pick = map(map(get(result, 'result.items'), enumerationAdapter), 'code');
        const items = map(statuses, (item) => ({ ...item, disabled: !item.approval }));
        this.statusesByClassification = filter(items, ({ code, disabled }) => {
          return (!disabled && includes(pick, code)) || disabled && includes(compact(currents), code);
        });
      });
    }
  },
  watch: {
    'statuses': {
      handler() {
        // Произойдет только если статусы не успели загрузиться на момент рендера
        this.onUpdateStatusesByClassification();
        this.onReset();
      },
    },
    'values.classification': {
      immediate: true,
      handler() {
        this.onUpdateStatusesByClassification();
      },
    },
    'statusesByClassification': {
      handler(statuses) {
        if (!includes(map(statuses, 'code'), last(this.fields.statusClient))) {
          this.fields.statusClient.splice(-1, 1,'');
        }
      }
    }
  }
}
</script>

<style module lang="scss">
.root{}
</style>
