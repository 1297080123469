<script>
import { get, last, reduce, set, merge, isEqual, isArray, map, pickBy, isNaN, omit } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { taskEmptyAdapter } from '@/store/tasks/utils';
import client from '@/store/clients/api';
import { saleAdapter } from '@/store/utils';
import STATUSES from '@/api/enums/statuses';
import TYPES from '@/api/enums/types';

export default {
  name: 'TaskForm',
  props: {
    task: {
      type: Object,
      default() {
        return taskEmptyAdapter();
      }
    }
  },
  data() {
    return {
      error: null,
      fields: {},
      files: [],
      advanceDiscount: false,
      advanceDiscountPeriod: [],
      valid: true,
      rules: {
        required: val => !!val || 'Обязательно для заполнения',
        numeric: val => !isNaN(+val) || 'Может содержать только цифры',
        phone: val => (!val || (/^(\+\d|\d)(\(\d*\)|\d-\d| |\d)*\d*$/gm.test(val) && val.length >= 6)) || 'Не корректный номер телефона'
      },
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      statuses: 'clients/statuses',
      classifications: 'clients/classifications',
      distributor: 'user/distributor',
      realizations: 'realizations/count'
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isCreate() {
      return this.status === STATUSES.CREATURE;
    },
    isAdjustment() {
      return this.status === STATUSES.ADJUSTMENT;
    },
    isEditing() {
      return this.status === STATUSES.EDITING;
    },
    validRealizations() {
      return !this.isCreate || this.isAdmin || !!this.realizations;
    },
    id() {
      return get(this.task, 'id', null);
    },
    inn(){
      return get(this, 'task.inn') || last(get(this, 'fields.inn', []));
    },
    status() {
      return get(this.task, 'status.code', null);
    },
    values() {
      return reduce(this.fields, (acc, cur, key) => {
        if (!this.isAdmin && (key === 'distributors')) return acc; // Пропускать поле дистребьютера для не администратора
        const value = last(cur);
        set(acc, key, isArray(value) ? map(value, 'id') : value);
        return acc;
      }, {});
    },
    modified() {
      if (this.isAdjustment && this.files.length) return true;
      const drop = ['sales', 'comment'];
      const base = omit(JSON.parse(JSON.stringify(this.fields)), drop);
      const current = omit(JSON.parse(JSON.stringify(get(this.task, 'fields', {}))), drop);
      return !isEqual(base, current);
    },
    watchData() {
      return this.id + this.task.inn + this.status;
    }
  },
  methods: {
    ...mapActions({
      'update': 'tasks/update',
    }),
    submit(status, binding){
      const id = this.id;
      const inn = get(this.values, 'inn');
      const distributor = get(this.distributor, 'id', null);
      const files = map(this.files, 'id');
      const [advanceDiscount] = this.task.advanceDiscount;
      const base = pickBy({ id, status, files }, Boolean);
      let submitData = merge(base, this.values, {advanceDiscount});
      if(this.task.advanceDiscountPeriod) [submitData.advanceDiscountPeriod]=this.task.advanceDiscountPeriod;

      this.update(binding ?
          { inn, distributor, type: TYPES.CREATE }:
          submitData
      )
      .then(id => this.$emit('submit', id))
      .catch((error) => (this.error = error));
    },
    reset() {
      const task = JSON.parse(JSON.stringify(this.task));
      this.fields = get(task, 'fields', {});
      this.files = get(task, 'files', []);
    }
  },
  watch: {
    'watchData': {
      immediate: true,
      handler() { this.reset() }
    },
    'values.statusClient': {
      handler(status) {
        status && client.discount(status).then(result => {
          const nextSales = map(get(result, 'result.items'), saleAdapter);
          this.fields.sales.splice(-1, 1, nextSales);
        });
      }
    }
  },
}
</script>
