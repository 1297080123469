<template>
  <v-dialog persistent max-width="400" :value="true" @click:outside="close" @keydown.esc="close">
    <well title="Мой профиль">
      <template v-slot:title="{ title }">
        {{title}}
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <user-edit-form
          :value="user"
          @submit="close"
          @reset="close"
      />
    </well>
  </v-dialog>
</template>

<script>
import Well from '@/components/Well/Well';
import UserEditForm from '@/components/UserEditForm/UserEditForm';
import {mapGetters} from 'vuex';

export default {
  name: 'TaskCreationDialog',
  components: {
    Well,
    UserEditForm,
  },
  computed: {
    ...mapGetters({
      name: 'user/name',
      distributor: 'user/distributor',
      role: 'user/role',
      notSendEvent: 'user/notSendEvent'
    }),
    user() {
      return {
        name: this.name,
        distributor: this.distributor,
        role: this.role,
        notSendEvent: this.notSendEvent,
      }
    }
  },
  methods: {
    close() {
      this.$router.push({ hash: null });
    }
  }
}
</script>
