<template>
  <tr :class="$style.root">
    <td>
      <div :class="$style.control">
        <date-picker
            v-model="date"
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="user"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="id"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="district"
            :items="districts"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="region"
            :items="district ? regionsByDistrict[district] : regions"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="type"
            :items="types"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="status"
            :items="statuses"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td v-if="isAdmin || isAdminView">
      <div :class="$style.control">
        <v-text-field
            v-model="distributor"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="client"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
                v-model="newStatus"
                :items="newStatuses"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="inn"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="classification"
            :items="classifications"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { get, debounce, pickBy } from 'lodash';
import { mapGetters } from 'vuex';

import DatePicker from '@/components/DatePickerRange/DatePickerRange';

export default {
  name: 'TaskListFilter',
  components: {
    DatePicker,
  },
  props: {
    value: { type: Object },
  },
  data() {
    return {
      date: get(this.value, 'date', ''),
      user: get(this.value, 'user', ''),
      id: get(this.value, 'id', ''),
      region: get(this.value, 'region', ''),
      district: get(this.value, 'district', ''),
      type: get(this.value, 'type', ''),
      status: get(this.value, 'status', ''),
      distributor: get(this.value, 'distributor', ''),
      client: get(this.value, 'client', ''),
      newStatus: get(this.value, 'newStatus', ''),
      inn: get(this.value, 'inn', ''),
      classification: get(this.value, 'classification', ''),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      statuses: 'tasks/statuses',
      newStatuses: 'tasks/newStatuses',
      regions: 'clients/regions',
      districts: 'clients/districts',
      types: 'tasks/types',
      classifications: 'clients/classifications',
      regionsByDistrict: 'clients/regionsByDistrict',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    }
  },
  methods: {
    onChange: debounce(function() {
      this.$emit('input', pickBy({
        date: this.date,
        user: this.user,
        author: this.author,
        id: this.id,
        district: this.district,
        region: this.region,
        type: this.type,
        status: this.status,
        distributor: this.distributor,
        client: this.client,
        newStatus: this.newStatus,
        inn: this.inn,
        classification: this.classification,
      }, Boolean));
    }, 600),
  },
  watch: {
    district: {
      handler() {
        this.region = '';
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  background: none !important;
  .control {
    width: 150px;
  }
}
</style>
