<template>
<div :class="$style.root">
  <classifications-list />
  <v-navigation-drawer :value="!!inn" app stateless width="430" right mobile-breakpoint="0">
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-text="'Клиент'" />
        </v-list-item-content>
        <v-list-item-action>
          <v-row dense align="center">
            <v-col cols="auto" v-if="inn !== 'creation' && isAdmin">
              <v-btn icon small :to="{ hash: 'AffiliationDialog' }">
                <v-icon color="grey lighten-1" v-text="'mdi-graph-outline'" />
              </v-btn>
            </v-col>
            <v-col>
              <v-btn v-if="!editing && !isAdminView" icon :to="{params: {view: 'editing'}}">
                <v-icon color="grey lighten-1" v-text="['mdi-pen', 'mdi-close'][+editing]" />
              </v-btn>
            </v-col>
            <v-col>
              <v-btn icon @click="onClose">
                <v-icon color="grey lighten-1" v-text="'mdi-close'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </template>
    <div v-if="inn" :class="$style.drawer">
      <client-detail :inn="inn" :editing="editing" />
    </div>
  </v-navigation-drawer>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClassificationsList from '@/components/ClassificationsList/ClassificationsList';
import ClientDetail from '@/components/ClientDetail/ClientDetail';

export default {
  name: 'ClassificationsListPage',
  components: {
    ClassificationsList,
    ClientDetail,
  },
  props: {
    inn: { type: String },
    view: { type: String },
  },
  computed: {
    ...mapGetters({
      access: 'access',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    editing() {
      return this.view === 'editing';
    }
  },
  methods: {
    onClose() {
      this.$router.push({ name: 'ClassificationsListPage' });
    }
  }
}
</script>

<style module lang="scss">
.root{
  .drawer{
    padding: 6px 16px 16px;
  }
}
</style>
