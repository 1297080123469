<template>
  <div :class="$style.root">
    <v-file-input multiple :class="$style.input" :id="`FILE_${_uid}`" @change="upload"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
    <div v-if="files.length" :class="$style.list">
      <div v-for="({name, size}, index) in files" :class="$style.li" :key="index">
        <v-text-field
            outlined
            dense
            readonly
            hide-details="auto"
            :label="size"
            :value="name"
        />
        <v-btn class="ml-2" small icon @click="files.splice(index, 1)">
          <v-icon dense v-text="'mdi-delete'"/>
        </v-btn>
      </div>
    </div>
    <v-btn :class="$style.btn" tag="label" depressed :for="`FILE_${_uid}`" :loading="pending">
      <v-icon v-text="'mdi-plus'"/>
      Загрузить
    </v-btn>
    <v-btn :class="$style.btnDownload" tag="label" depressed
           href="/local/modules/intervolga.custom/data/product_list_template.xlsx">
      <v-icon v-text="'mdi-download'"/>
      Скачать шаблон
    </v-btn>
  </div>
</template>

<script>
import api from '@/api';
import { concat } from 'lodash'

export default {
  name: 'PromotionsFileList',
  props: {
    value: {type: Array},
  },
  data() {
    return {
      pending: false,
      files: [],
    }
  },
  methods: {
    upload(items) {
      this.pending = true;
      api.upload(items).then(files => {
        this.files = concat(this.files, files);
        this.pending = false;
      });
    }
  },
  beforeMount() {
    if(this.value && this.value.length) {
      this.files = concat(this.files, this.value);
    }
  },
  watch: {
    files: {
      deep: true,
      handler() {
        this.$emit('input', [
          ...this.files,
        ]);
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  .input {
    display: none;
  }

  .list {
    margin-bottom: 20px;

    .li {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
    }
  }

  .btn {
    cursor: pointer;
  }

  .btnDownload {
    cursor: pointer;
    margin-left: 10px;
  }
}
</style>
