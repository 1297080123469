import { toString, forEach } from 'lodash';
import { Api } from '@/api';

export class Client extends Api {
  item(inn) {
    return this.request('clients.info', {
      params: {
        inn: toString(inn),
      }
    })
  }
  /**
   * Получение списка доступных клиентов
   * @param page
   * @param size
   * @param sort
   * @param filter
   * @returns {Promise<*>}
   */
  list(page = 1, size = -1, sort = {}, filter = {}) {
    const params = {
      page: toString(page),
      size: toString(size),
      extend: 'Y', // Параметр позволяет вернуть информацию по дистребютерам в списке
    };
    forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
    forEach(filter, (value, key) => params[`filter[${key}]`] = value);
    return this.request('clients.list', {
      params,
    });
  }
  realization(inn, distributor, type='') {
    const params = {
      inn: toString(inn),
      distributor: toString(distributor),
      type: toString(type),
    };
    return this.request('clients.listRealizations', {
      params,
    });
  }
  realizationsByDistributors(inn, type) {
    const params = {
      inn: toString(inn),
      type: toString(type)
    };
    return this.request('clients.listRealizationsByDistributors', {
      params,
    })
  }
  addRealization(inn, sum, date, type){
    return this.request('clients.addRealization', {
      method: 'POST',
      data: this.toFormData({
        inn: toString(inn),
        sum: toString(sum),
        date: toString(date),
        type: toString(type),
      }),
    })
  }
  discount(status) {
    return this.request('clients.listSalesByStatus', {
      params: { status }
    })
  }
  sales() {
    return this.request('clients.listSales');
  }
  statuses(classification) {
    return this.request('clients.listStatuses', {
      params: { classification },
    });
  }
  regions() {
    return this.request('clients.listRegions');
  }
  districts() {
    return this.request('clients.listDistricts');
  }
  classifications() {
    return this.request('clients.listClassifications');
  }
  ckgList() {
    return this.request('clients.listCKG');
  }
  bpConnectedList() {
    return this.request('clients.listBPConnected');
  }
  find(inn) {
    return this.request('clients.findINN', {
      params: {
        inn: toString(inn),
      }
    })
  }
  findDistributorINNList(inn) {
    return this.request('clients.findDistributorINNList', {
      params: {
        inn: toString(inn),
      }
    })
  }
  findCisLink(inn) {
    return this.request('clients.findINNCisLink', {
      params: {
        inn: toString(inn),
      }
    })
  }
  findAffiliated(inn) {
    return this.request('clients.findAffiliated', {
      params: {
        inn: toString(inn),
      }
    })
  }
  excel(page = 1, size = -1, sort = {}, filter = {}, type = '') {
    const params = {
      page: toString(page),
      size: toString(size),
    };
    let url;
    if(type==='relevance') url = 'clients.excelRelevanceToQueue';
    else if(type==='advance') url = 'clients.excelAdvanceToQueue';
    else url = 'clients.excelToQueue';
    forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
    forEach(filter, (value, key) => params[`filter[${key}]`] = value);
    return this.request(url, {
      params,
    });
  }
  importSaleExcel(files = {}, distributor = false) {
    const params = {
      files: files,
      distributor: distributor
    };
    return this.request('clients.importSaleExcel', {
      params,
    });
  }
  actualPeriod(inn, type) {
    return this.request('clients.actualPeriod', {
      params: {
        inn: toString(inn),
        type: toString(type),
      }
    });
  }
  advanceDiscountHistoryList(inn) {
    const params = {
      inn: toString(inn),
    };
    return this.request('clients.listAdvanceDiscountHistory', {
      params,
    });
  }
}

export default new Client();
