<template>
<div :class="$style.root">
  <dealer-statuses-list />
  <v-navigation-drawer :value="!!inn" app stateless width="430" right mobile-breakpoint="0">
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title v-text="'Клиент'" />
        </v-list-item-content>
        <v-list-item-action>
          <v-row dense align="center">
            <v-col>
              <v-btn icon @click="onClose">
                <v-icon color="grey lighten-1" v-text="'mdi-close'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>
    </template>
    <div v-if="inn" :class="$style.drawer">
      <dealer-client-detail :inn="inn" />
    </div>
  </v-navigation-drawer>
</div>
</template>

<script>

import DealerStatusesList from '@/components/DealerStatusesList/DealerStatusesList';
import DealerClientDetail from '@/components/DealerClientDetail/DealerClientDetail';

export default {
  name: 'DealerStatusesListPage',
  components: {
    DealerStatusesList,
    DealerClientDetail,
  },
  props: {
    inn: { type: String },
    view: { type: String },
  },
  methods: {
    onClose() {
      this.$router.push({ name: 'DealerStatusesListPage' });
    }
  }
}
</script>

<style module lang="scss">
.root{
  .drawer{
    padding: 6px 16px 16px;
  }
}
</style>
