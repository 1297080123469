<template>
  <v-form v-model="valid" ref="form" @submit.prevent="onSubmit({login, password})">
    <v-text-field
        class="mb-6"
        label="Email"
        outlined
        hide-details="auto"
        v-model="login"
        :rules="[rules.required, rules.email]"
    />
    <v-text-field
        class="mb-6"
        label="Пароль"
        outlined
        :type="type"
        hide-details="auto"
        :append-icon="type === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
        v-model="password"
        :rules="[rules.required]"
        @click:append="() => (type = type === 'password' ? 'text' : 'password')"
    />
    <v-alert v-if="error" dense outlined type="error">
      <div v-html="error.error_description" />
    </v-alert>
    <div>
      <v-btn
          v-text="['Войти'][+pending]"
          block
          depressed
          color="primary"
          type="submit"
          :loading="pending"
      />
      <v-btn
          v-text="'Забыли пароль'"
          block
          text
          depressed
          class="mt-3"
          :to="{name: 'UserRecoveryPage'}"
      />
      <v-btn
              v-text="'Вопрос в техническую поддержку дистрибутора'"
              block
              text
              depressed
              class="mt-3"
              :to="{ hash: 'DistributorQuestionDialog' }"
      />
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'UserSignInForm',
  data(){
    return {
      type: 'password',
      valid: true,
      error: null,
      login: '',
      password: '',
      rules: {
        required: v => !!v || 'Обязательное поле',
        email: v => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Некорректный адрес электронной почты'
      },
    }
  },
  computed: {
    ...mapGetters({
      pending: 'user/pending',
    })
  },
  methods: {
    ...mapActions({
      signIn: 'user/signIn',
    }),
    reset() {
      this.login = '';
      this.password = '';
    },
    onSubmit({ login, password }) {
      this.$refs.form.validate();
      if (this.valid) {
        this.error = null;
        this.signIn({ login, password })
        .then(() => this.$emit('submit'))
        .catch((error) => (this.error = error));
      }
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    }
  },
}
</script>

<style module lang="scss"></style>
