import {includes, set, get, values, map, mapValues, toString} from 'lodash';
import affiliation from './api';

const START = 'START';
const END = 'END';
const UPDATE_LIST = 'UPDATE_LIST';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchItems: false,
    },
    list: {},
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    list(state) {
      return mapValues(state.list, (items) => {
        return map(items, item => ({
          inn: toString(get(item, 'code')),
          name: toString(get(item, 'name')),
          address: toString(get(item, 'address')),
          phone: toString(get(item, 'phone')),
          okved: toString(get(item, 'okved')),
          deleteDate: toString(get(item, 'deleteDate')),
        }));
      })
    }
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [UPDATE_LIST](state, list) {
      state.list = {
        ...list,
        ...state.list,
      };
    },
  },
  actions: {
    async fetchItems({ commit }, inn) {
      commit(START, 'fetchItems');
      const { result } = await affiliation.list(inn);
      const success = !get(result, 'error');
      if (success) commit(UPDATE_LIST, { [inn]: values(get(result, 'items', [])) });
      commit(END, 'fetchItems');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
  },
}
