<template>
<div :class="$style.root" v-if="items.length">
  <v-tooltip v-for="{ text, icon, color, disabled, action } in items" bottom :key="text">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-bind="attrs" v-on="on" depressed :disabled="disabled" :color="color" @click="action">
        <v-icon v-if="icon" v-text="icon" />
        <template v-else>{{text}}</template>
      </v-btn>
    </template>
    <span>{{text}}</span>
  </v-tooltip>
  <slot />
</div>
</template>

<script>
import { includes } from 'lodash';
import { mapGetters } from 'vuex';
import STATUSES from '@/api/enums/statuses';

export default {
  name: 'TaskStatusSwitch',
  props: {
    value: { type: String },
    disabled: { type: Boolean },
    buttonView: { type: Object, default: () =>({approve: true, adjustment: true, reject: true}) },
  },
  computed: {
    ...mapGetters({
      access: 'access',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    items() {
      const items = [];
      if (this.isAdmin && includes([STATUSES.APPROVAL], this.value)) {
        if(this.buttonView.approve)
          items.push(
            {
              text: 'Согласовать',
              icon: 'mdi-check-circle-outline',
              color: 'success',
              action: () => this.$emit('input', STATUSES.AGREED),
            });

        if(this.buttonView.adjustment)
          items.push(
            {
              text: 'Корректировать',
              icon: 'mdi-pen',
              color: 'warning',
              action: () => this.$emit('input', STATUSES.ADJUSTMENT),
            });

        if(this.buttonView.reject)
          items.push(
            {
              text: 'Отклонить',
              icon: 'mdi-close-circle-outline',
              color: 'error',
              action: () => this.$emit('input', STATUSES.REJECTED),
            });
      }

      if (this.isAdmin && includes([STATUSES.ADJUSTMENT], this.value)) {
        items.push(
            {
              text: 'Отклонить',
              color: 'error',
              action: () => this.$emit('input', STATUSES.REJECTED),
            },
        )
      }

      if (includes([STATUSES.CREATURE, STATUSES.EDITING, STATUSES.ADJUSTMENT], this.value)) {
        items.push(
            {
              text: 'На согласование',
              color: 'primary',
              disabled: this.disabled,
              action: () => this.$emit('input', STATUSES.APPROVAL),
            },
        );
      }

      if (includes([STATUSES.EDITING], this.value) || (!this.isAdmin && includes([STATUSES.ADJUSTMENT], this.value))) {
        items.push(
            {
              text: 'Отмена',
              action: () => this.$emit('reset', STATUSES.CANCEL),
            },
        );
      }

      return items;
    }
  },
}
</script>

<style module lang="scss">
.root {}
</style>
