var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(_vm.items)?_c('v-data-table',{class:_vm.$style.table,attrs:{"dense":"","must-sort":"","item-key":"name","loading":_vm.pending,"loading-text":"Загрузка...","items-per-page":5,"hide-default-footer":_vm.items.length < 6,"items":_vm.items,"headers":_vm.distributor ? [
        {value: 'inn', text: 'ИНН'},
        {value: 'client.name', text: 'Имя', cls: '1'},
        {value: 'period', text: 'Период'},
        {value: 'sum', text: 'Сумма, руб'} ] : [
        {value: 'period', text: 'Период', cls: 'nowrap'},
        {value: 'sum', text: 'Сумма, руб'} ],"item-class":function () { return _vm.distributor ? [_vm.$style.tr, _vm.$style.full] : [_vm.$style.tr, _vm.$style.two]; },"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, -1],
        itemsPerPageText: '',
      }},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_vm._t("default")]},proxy:true}],null,true)}):_vm._e(),(_vm.pending)?_c('div',{class:_vm.$style.loader}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }