<template>
<div :class="$style.root">
  <v-progress-linear v-if="pending" indeterminate />
  <v-simple-table v-else dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th>ИНН</th>
        <th class="text-left" v-text="'Квартал 1'" />
        <th class="text-left" v-text="'Квартал 2'" />
        <th class="text-left" v-text="'Квартал 3'" />
        <th class="text-left" v-text="'Квартал 4'" />
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item) in items" :key="item.inn">
        <td>{{ inn }}</td>
        <td v-for="(value, index) in item[inn]" :key="index">
          {{value ? `${_.round(value, 2)}р` : 'Нет данных'}}
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'CisLinkQuarterTable',
  props: {
    inn: { type: String },
  },
  computed: {
    ...mapGetters({
      pending: 'cislink/pending',
      items: 'cislink/quarters',
    }),
  },
  methods: {
    ...mapActions({
      fetchSellOutByQuarter: 'cislink/fetchSellOutByQuarter',
    }),
  },
  beforeMount() {
    this.fetchSellOutByQuarter(this.inn);
  },
  watch: {
    inn() {
      this.fetchSellOutByQuarter(this.inn);
    }
  }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  white-space: nowrap;
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  td {
    width: 1px !important;
  }
}
</style>
