<template>
  <tr :class="$style.root">
    <td>
      <div :class="$style.control">
        <date-picker
                v-model="date"
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="id"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
                v-model="distributorName"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="client"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
                v-model="check"
                :items="checksList"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
                v-model="violations"
                :items="violationsList"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
                v-model="person"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
                v-model="preview"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
                v-model="district"
                :items="districts"
                hideSelected
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
                v-model="region"
                :items="district ? regionsByDistrict[district] : regions"
                hideSelected
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
                v-model="innType"
                :items="innTypeList"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <date-picker
                v-model="dateClose"
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control" />
    </td>
  </tr>
</template>

<script>
import { get, debounce, pickBy } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import DatePicker from '@/components/DatePickerRange/DatePickerRange';

export default {
  name: 'AuditResultListFilter',
  components: {
    DatePicker,
  },
  props: {
    value: { type: Object },
  },
  data() {
    return {
      date: get(this.value, 'date', ''),
      id: get(this.value, 'id', ''),
      distributorName: get(this.value, 'distributorName', ''),
      client: get(this.value, 'client', ''),
      check: get(this.value, 'check', ''),
      violations: get(this.value, 'violations', ''),
      person: get(this.value, 'person', ''),
      preview: get(this.value, 'preview', ''),
      innType: get(this.value, 'innType', ''),
      dateClose: get(this.value, 'dateClose', ''),
      region: get(this.value, 'region', ''),
      district: get(this.value, 'district', ''),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      violationsList: 'audit/violationsList',
      checksList: 'audit/checksList',
      innTypeList: 'audit/innTypeList',
      regions: 'clients/regions',
      districts: 'clients/districts',
      regionsByDistrict: 'clients/regionsByDistrict'
    })
  },
  methods: {
    ...mapActions({
      'fetchStatuses': 'audit/fetchStatuses',
      'fetchViolationsList': 'audit/fetchViolationsList',
      'fetchChecksList': 'audit/fetchChecksList',
      'fetchInnTypeList': 'audit/fetchInnTypeList'
    }),
    onChange: debounce(function() {
      this.$emit('input', pickBy({
        date: this.date,
        id: this.id,
        distributorName: this.distributorName,
        client: this.client,
        check: this.check,
        violations: this.violations,
        person: this.person,
        preview: this.preview,
        innType: this.innType,
        dateClose: this.dateClose,
        district: this.district,
        region: this.region,
      }, Boolean));
    }, 600),
  },
  watch: {
    district: {
      handler() {
        this.region = '';
      }
    }
  },
  mounted() {
    this.fetchStatuses();
    this.fetchViolationsList();
    this.fetchChecksList();
    this.fetchInnTypeList();
  },
}
</script>

<style module lang="scss">
.root {
  background: none !important;
  .control {
    width: 150px;
  }
}
</style>
