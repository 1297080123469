<template>
  <panel :title="title">
    <div :class="$style.muted" v-if="diff && !difference.length">Не изменилость</div>
    <panel v-for="(items, index) in difference" :title="length > 1 ? ['Было', 'Стало'][index] : null" :key="index">
      <slot v-bind="{items, index, length}" />
    </panel>
  </panel>
</template>

<script>
import {countBy, flatten, map, merge, reduce, filter} from 'lodash';
import Panel from '@/components/Panel/Panel';

export default {
  name: 'PanelDifference',
  components: {
    Panel,
  },
  props: {
    title: { type: String },
    diff: { type: Boolean },
    items: { type: Array, default: () => [] },
  },
  computed: {
    length() {
      return this.items.length;
    },
    difference() {
      if (this.length < 2) return this.items;
      const dub = reduce(countBy(map(flatten(this.items), 'id')), (acc, val, key) => val > 1 ? acc.concat(key) : acc, []);
      return map(this.items, (item, index) => {
        const cls = (index ? 'success' : 'error' ) + ' lighten-5';
        const items = map(item, item => merge(item, {
          cls: !dub.includes(item.id) ? cls : '',
        }));
        return this.diff ? filter(items, ({ cls }) => cls) : items;
      });
    }
  }
}
</script>

<style module lang="scss">
.muted{
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
}
</style>
