<template>
  <div :class="$style.root">
    <v-card-title>
      <slot name="title">
        Бухгалтерские формы
      </slot>
    </v-card-title>
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="[
        {text: 'Год', value: 'year'},
        {text: 'Выручка', value: 'revenue'},
        {text: 'Валовая прибыль (убыток)', value: 'gross'},
        {text: 'Чистая прибыль (убыток)', value: 'profit'},
        {text: 'Запасы', value: 'reserves'},
      ]"
        :itemsPerPage="20"
        :mobile-breakpoint="0"
        :items="formItems"
        :item-class="() => $style.row"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [-1],
        }"
    />
    <v-card-title>
      <slot name="title">
        Госконтракты
      </slot>
    </v-card-title>
    <v-data-table
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="[
        {text: 'Год', value: 'year'},
        {text: 'Сумма по госконтрактам', value: 'sum'},
        {text: 'Доля ГК е Выручке', value: 'share'},
      ]"
        :itemsPerPage="20"
        :mobile-breakpoint="0"
        :items="governmentItems"
        :item-class="() => $style.row"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [-1],
        }"
    />
    <v-card-title>
      <slot name="title">
        Экспертный рейтинг
      </slot>
    </v-card-title>
    <v-data-table
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="[
        {text: 'Год', value: 'year'},
        {text: 'Рейтинг', value: 'rating'},
      ]"
        :itemsPerPage="20"
        :mobile-breakpoint="0"
        :items="ratingItems"
        :item-class="() => $style.row"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [-1],
        }"
    />
    <v-card-title>
      <slot name="title">
        Размер компании
      </slot>
    </v-card-title>
    <v-data-table
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="[
        {text: 'Год', value: 'year'},
        {text: 'Размер', value: 'sizeName'},
      ]"
        :itemsPerPage="20"
        :mobile-breakpoint="0"
        :items="companySizeItems"
        :item-class="() => $style.row"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [-1],
        }"
    />
    <v-card-title>
      <slot name="title">
        Численность компании
      </slot>
    </v-card-title>
    <v-data-table
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="[
        {text: 'Год', value: 'year'},
        {text: 'Численность', value: 'size'},
      ]"
        :itemsPerPage="20"
        :mobile-breakpoint="0"
        :items="companySizeCountItems"
        :item-class="() => $style.row"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [-1],
        }"
    />
    <div v-if="pending" :class="$style.loader"/>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClientAccountingTable',
  props: {
    inn: {type: String, required: true},
  },
  data() {
    return {
      height: window.innerHeight - 310,
    }
  },
  computed: {
    ...mapGetters({
      pending: 'accounting/pending',
      listForms: 'accounting/listForms',
      listGovernment: 'accounting/listGovernment',
      listRaiting: 'accounting/listRaiting',
      listCompanySize: 'accounting/listCompanySize',
      listCompanySizeCount: 'accounting/listCompanySizeCount',
    }),
    formItems() {
      return get(this.listForms, this.inn, []);
    },
    governmentItems() {
      return get(this.listGovernment, this.inn, []);
    },
    ratingItems() {
      return get(this.listRaiting, this.inn, []);
    },
    companySizeItems() {
      return get(this.listCompanySize, this.inn, []);
    },
    companySizeCountItems() {
      return get(this.listCompanySizeCount, this.inn, []);
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'accounting/fetchItems',
    }),
    resize() {
      this.height = window.innerHeight - 310;
    },
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, {passive: true});
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    inn: {
      immediate: true,
      handler() {
        if (!this.formItems.length) this.fetchItems(this.inn);
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  position: relative;

  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  :global {
    .v-data-footer {
      justify-content: flex-start;
      padding-left: 16px;

      .v-data-footer__select {
        flex-grow: 1;
        font-size: 18px !important;
        justify-content: space-between;

        .v-input {
          min-width: 60px;
        }
      }

      .v-data-footer__pagination {
        min-width: 70px;
      }

      .v-select {

        .v-input {

        }
      }
    }
  }
}
</style>
