const clientStatuses = {
  LOSS: '050_loss',
  VIP: '100_vip',
  PREMIUM: '200_premium',
  STANDARD: '300_standard',
  PARTNER: '400_partner',
  BAN: '500_ban',
};

export default clientStatuses;
