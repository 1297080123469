<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <dealer-task-form
          v-if="editing"
          :task="task"
          :key="task.id"
          @submit="onSubmit"
      />
      <dealer-task-view v-else :task="task"/>
    </div>
    <div v-if="pending" :class="$style.loader" />
  </div>
</template>

<script>
import { get, includes } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import STATUSES from '@/api/enums/statuses';

import DealerTaskView from '@/components/DealerTaskView/DealerTaskView';
import DealerTaskForm from '@/components/DealerTaskForm/DealerTaskForm';
import { taskEmptyAdapter } from '@/store/tasks/utils';

export default {
  name: 'DealerTaskDetail',
  components: {
    DealerTaskView,
    DealerTaskForm
  },
  props: {
    id: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'tasks/pending',
      current: 'tasks/current',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    editing() {
      const status = get(this.task, 'status.code');
      return includes([STATUSES.CREATURE], status) || !this.isAdmin && includes([STATUSES.ADJUSTMENT], status);
    },
    isCreate() {
      return this.id === 'creation';
    },
    task() {
      if (this.isCreate) return taskEmptyAdapter();
      return this.current;
    }
  },
  methods: {
    ...mapActions({
      'fetchCurrent': 'tasks/fetchCurrent',
      'removeCurrent': 'tasks/removeCurrent',
    }),
    onUpdate() {
      this.isCreate || this.fetchCurrent(this.id);
    },
    onSubmit(id) {
      this.$router.push({ name: 'DealerTaskListPage', params: { id: id, reload: true } });
    },
  },
  watch: {
    id() {
      this.onUpdate();
    }
  },
  beforeMount() { this.onUpdate() },
  beforeDestroy() { this.removeCurrent() }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .inner {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
