import {get, toString} from 'lodash';

/**
 * Заглушка пустой заявки
 * @param item
 */
export const downloadsEmptyAdapter = (item) => {
    return {
        id: [get(item, 'id', '')],
        dateStart: [get(item, 'dateRegister', '')],
        dateAvailable: [get(item, 'dateAvailable', '')],
        name: [get(item, 'name', '')],
        status: [get(item, 'status', '')],
        link: [get(item, 'link', 'Y')],
        readyPercent: [get(item, 'readyPercent', 0)],
    }
};

/**
 * Выборка полей заявки из структуры arResult
 * @param item
 * @returns {{id: string, dateRegister: string, name:string, email:string, personalPhone:string, active:string, activeCode:string, position:string, positionType:string}}
 */
export const downloadsItemAdapter = (item) => {
    return {
        id: toString(get(item, 'id')),
        dateStart: toString(get(item, 'dateStart')),
        dateAvailable: toString(get(item, 'dateAvailable')),
        name: toString(get(item, 'name')),
        link: toString(get(item, 'link')),
        readyPercent: toString(get(item, 'readyPercent')),
        status: {
            code: toString(get(item, 'status.code')),
            name: toString(get(item, 'status.name')),
        },
    };
};
