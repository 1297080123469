<template>
  <div :class="$style.root">
    <advance-client-list :reload="reload" />
    <v-navigation-drawer :value="!!inn" app stateless width="430" right mobile-breakpoint="0">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="'Клиент'" />
          </v-list-item-content>
          <v-list-item-action>
            <v-row dense align="center">
              <v-col>
                <v-btn icon @click="onClose">
                  <v-icon color="grey lighten-1" v-text="'mdi-close'" />
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </template>
      <div v-if="inn" :class="$style.drawer">
        <client-detail :inn="inn" :editing="editing" realizationType="advance"/>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import AdvanceClientList from '@/components/AdvanceClientList/AdvanceClientList';
  import ClientDetail from '@/components/ClientDetail/ClientDetail';

  export default {
    name: 'AdvanceClientListPage',
    components: {
      AdvanceClientList,
      ClientDetail
    },
    props: {
      inn: { type: String },
      view: { type: String },
      reload: { type: Boolean },
    },
    computed: {
      ...mapGetters({
        access: 'access',
      }),
      isAdmin() {
        return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
      },
      isAdminView() {
        return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
      },
      editing() {
        return this.view === 'editing';
      }
    },
    methods: {
      onClose() {
        this.$router.push({ name: 'AdvanceClientList' });
      }
    },
  }
</script>

<style module lang="scss">
  .root{
    .drawer{
      padding: 6px 16px 16px;
    }
  }
</style>
