<template>
  <v-data-table
      dense
      must-sort
      :hide-default-footer="value.length < 6"
      :class="$style.root"
      :items-per-page="5"
      :items="value"
      :item-class="() => $style.tr"
      :headers="[
        {value: 'field', text: 'Поле'},
        {value: 'from', text: 'Было'},
        {value: 'to', text: 'Стало'},
        {value: 'user', text: 'Изменил'},
        {value: 'date', text: 'Дата'},
      ]"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, -1],
        itemsPerPageText: '',
      }"
  />
</template>

<script>
export default {
  name: 'ClientHistoryTable',
  props: {
    value: {
      type: Array,
    },
  }
}
</script>

<style module lang="scss">
.root {
  white-space: nowrap;
  .tr {
    background: none !important;
    td {
      width: 1px;
    }
  }
  :global {
    .v-data-footer{
      justify-content: space-between;
      padding: 0;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
