<template>
    <div :class="$style.root">
        <v-form ref="form" v-model="valid" :class="$style.root">
            <user-status-switch class="mb-6" :employee="employee" :disabled="!valid" @input="onSubmit"/>
            <v-alert v-if="error" class="mb-6" dense outlined type="error">
                <div v-html="error.error_description"/>
            </v-alert>
            <task-form-row
                    title="ФИО"
                    v-model="employee.name"
                    :rules="[rules.required]"
            />
            <task-form-row
                    title="Тип должности"
                    v-model="employee.positionType"
                    :items="positionTypes"
                    :rules="[rules.required]"
            />
            <task-form-row
                    title="Должность"
                    v-model="employee.position"
            />
            <task-form-row
                    title="Контактный телефон"
                    v-model="employee.personalPhone"
                    :rules="[rules.phone]"
            />
            <task-form-row
                    title="Почта"
                    v-model="employee.email"
                    :rules="[rules.required, rules.email]"
            />
            <task-form-row
                    title="Пароль"
                    type="password"
                    v-model="employee.password"
                    :rules="[rules.required]"
            />
            <task-form-row
                    title="Активность"
                    v-model="employee.activeCode"
                    :items="activeList"
                    :rules="[rules.required]"
            />
        </v-form>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';

    import UserForm from '@/mixins/UserForm/UserForm';
    import TaskFormRow from '@/components/TaskFormRow/TaskFormRow';
    import UserStatusSwitch from '@/components/UserStatusSwitch/UserStatusSwitch';
    import {bitrixBooleanList} from "../../store/utils";

    export default {
        name: 'UserForm',
        mixins: [UserForm],
        components: {
            TaskFormRow,
            UserStatusSwitch,
        },
        data() {
            return {
                statusesByClassification: this.statuses,
                correct: false,
                inner: false,
                related: false,
                innClassesOkved: [],
                advanceDiscount: false,
            };
        },
        computed: {
            ...mapGetters({
                positionTypes: 'employee/positionTypes',
            }),
            activeList() {
                return bitrixBooleanList;
            },
            viewOnly() {
                return !!(this.isCreate && ((this.inner && !this.related) || (this.related)));
            },
        },
        methods: {
            ...mapActions({
                'fetchPositionTypes': 'employee/fetchPositionTypes',
            }),
            onSubmit(status) {
                if (status === 'cancel') {
                    this.$router.push({name: 'UserListPage'});
                } else if (status === 'save') {
                    this.$refs.form.validate();
                    if (this.valid) {
                        this.submit(status);
                    }
                }
            },
            onReset() {
                this.reset();
                this.$emit('reset');
            },
        },
        mounted() {
            this.fetchPositionTypes();
        },
    }
</script>

<style module lang="scss">
    .root {
    }
</style>
