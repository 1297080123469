import { includes, set, get, values, map, mapValues, toString, toNumber } from 'lodash';
import accounting from './api';
import { priceAdapter } from "@/store/utils";

const START = 'START';
const END = 'END';
const UPDATE_LIST = 'UPDATE_LIST';

export default {
    namespaced: true,
    state: {
        pending: {
            fetchItems: false,
        },
        listForms: {},
        listGovernment: {},
        listRaiting: {},
        listCompanySize: {},
        listCompanySizeCount: {},
    },
    getters: {
        pending(state) {
            return includes(state.pending, true);
        },
        listForms(state) {
            return mapValues(state.listForms, (items) => {
                return map(items, item => ({
                    year: toNumber(get(item, 'year')),
                    revenue: priceAdapter(get(item, 'revenue')),
                    gross: priceAdapter(get(item, 'gross')),
                    profit: priceAdapter(get(item, 'profit')),
                    reserves: priceAdapter(get(item, 'reserves')),
                }));
            })
        },
        listGovernment(state) {
            return mapValues(state.listGovernment, (items) => {
                return map(items, item => ({
                    year: toNumber(get(item, 'year')),
                    sum: priceAdapter(get(item, 'sum')),
                    share: toString(get(item, 'share')),
                }));
            })
        },
        listRaiting(state) {
            return mapValues(state.listRaiting, (items) => {
                return map(items, item => ({
                    year: toNumber(get(item, 'year')),
                    rating: toString(get(item, 'rating')),
                }));
            })
        },
        listCompanySize(state) {
            return mapValues(state.listCompanySize, (items) => {
                return map(items, item => ({
                    year: toNumber(get(item, 'year')),
                    sizeName: toString(get(item, 'sizeName')),
                }));
            })
        },
        listCompanySizeCount(state) {
            return mapValues(state.listCompanySizeCount, (items) => {
                return map(items, item => ({
                    year: toNumber(get(item, 'year')),
                    size: toString(get(item, 'size')),
                }));
            })
        },
    },
    mutations: {
        [START](state, key) {
            set(state, ['pending', key], true);
        },
        [END](state, key) {
            set(state, ['pending', key], false);
        },
        [UPDATE_LIST](state, list) {
            state.listForms = {
                ...list.listForms,
                ...state.listForms,
            };
            state.listGovernment = {
                ...list.listGovernment,
                ...state.listGovernment,
            };
            state.listRaiting = {
                ...list.listRaiting,
                ...state.listRaiting,
            };
            state.listCompanySize = {
                ...list.listCompanySize,
                ...state.listCompanySize,
            };
            state.listCompanySizeCount = {
                ...list.listCompanySizeCount,
                ...state.listCompanySizeCount,
            };
        },
    },
    actions: {
        async fetchItems({commit}, inn) {
            commit(START, 'fetchItems');
            const {result} = await accounting.data(inn);
            const success = !get(result, 'error');
            if (success) commit(UPDATE_LIST, {
                'listForms': {[inn]: values(get(result, 'listForms', []))},
                'listGovernment': {[inn]: values(get(result, 'listGovernment', []))},
                'listRaiting': {[inn]: values(get(result, 'listRaiting', []))},
                'listCompanySize': {[inn]: values(get(result, 'listCompanySize', []))},
                'listCompanySizeCount': {[inn]: values(get(result, 'listCompanySizeCount', []))},
            });
            commit(END, 'fetchItems');

            if (!success) throw {
                error: 'Ошибка получения данных',
                error_description: get(result, 'message'),
            };
        },
    },
}
