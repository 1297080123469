<template>
  <v-form v-model="valid" ref="form" @submit.prevent="onSubmit({ login, password, word })">
    <v-text-field
        class="mb-6"
        label="Новый пароль"
        outlined
        type="password"
        hide-details="auto"
        v-model="password"
        :rules="[rules.required]"
    />
    <v-text-field
        class="mb-6"
        label="Повторите пароль"
        outlined
        type="password"
        hide-details="auto"
        v-model="passwordRepeat"
        :rules="[rules.required, v => (v === password) || 'Пароли не совпадают']"
    />
    <v-alert v-if="error" dense outlined type="error">
      <div v-html="error.error_description" />
    </v-alert>
    <div>
      <v-btn
          v-text="'Сохранить'"
          block
          depressed
          color="primary"
          type="submit"
      />
      <v-btn
          v-text="'Отмена'"
          block
          text
          depressed
          class="mt-3"
          @click="onReset"
      />
    </div>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'UserEditForm',
  props: {
    login: { type: String },
    word: { type: String },
  },
  data() {
    return {
      valid: true,
      error: null,
      password: '',
      passwordRepeat: '',
      rules: {
        required: v => !!v || 'Обязательное поле',
      },
    }
  },
  computed: {},
  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword',
    }),
    reset() {
      this.password = '';
      this.passwordRepeat = '';
    },
    onSubmit({login, password, word}) {
      this.$refs.form.validate();
      if (this.valid) {
        this.error = null;
        this.resetPassword({login, password, word})
        .then(() => this.$emit('submit'))
        .catch((error) => (this.error = error));
      }
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    }
  },
}
</script>

<style module lang="scss"></style>
