<template>
  <div :class="$style.root">
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="_.filter([
          {text: 'Раздел выгрузки', value: 'name'},
          {text: 'Дата формирования', value: 'dateStart'},
          {text: 'Статус', value: 'status.name'},
          {text: 'Срок действия ссылки', value: 'dateAvailable'},
        ])"
        :mobile-breakpoint="0"
        :height="height"
        :items="items"
        :item-class="({ id }) => [$style.row, {
            [$style.active]: id === active,
        }]"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500],
          itemsPerPageText: '',
        }"
    >
      <template v-slot:item.status.name="{ item }">
        <div v-if="item.status.code==='formed'">
          <a :href="item.link" target="_blank" :class="$style.downloadLink">Скачать</a>
        </div>
        <div v-else-if="item.status.code==='formation'">
          {{ item.status.name }} {{ item.readyPercent }}%
        </div>
        <div v-else>
          {{ item.status.name }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get, pick, merge } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DownloadsList',
  data() {
    const filter = {};
    const options = pick(JSON.parse(localStorage.getItem('DownloadsList.options')), ['sortBy', 'sortDesc', 'page', 'itemsPerPage']);
    return {
      height: window.innerHeight - 90,
      filter: merge({}, filter),
      options: merge({
        sortBy: ['dateStart'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 20,
      }, options),
      updateInterval: false
    }
  },
  props: {
    reload: {type: Boolean}
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'downloads/pending',
      items: 'downloads/items',
      count: 'downloads/count',
    }),
    active() {
      return this.$route.params.id;
    },
    sort() {
      const field = get(this.options, ['sortBy', 0], 'dateStart');
      const direction = ['asc', 'desc'][+get(this.options, ['sortDesc', 0], true)];
      return {
        field: field,
        direction,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchItems: 'downloads/fetchItems',
    }),
    update() {
      this.fetchItems({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      });
    },
    updateLocalStorage() {
      localStorage.setItem('DownloadsList.options', JSON.stringify(this.options));
    },
    resize() {
      this.height = window.innerHeight - 90;
    },
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, {passive: true});

    this.updateInterval = setInterval(() => this.update(), 30000);
  },
  beforeDestroy() {
    if(this.updateInterval) {
      clearInterval(this.updateInterval)
    }
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    filter: {
      handler() {
        this.update();
      },
      deep: true,
    },
    options: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
    reload: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-data-footer {
      justify-content: flex-start;
      padding-left: 16px;

      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }

      .v-data-footer__pagination {
        min-width: 70px;
      }

      .v-select {
        margin-left: 0 !important;
      }
    }

  }

  .row {
    cursor: default;

    td {
      width: 1px;
    }
  }

  .active {
    background: #eeeeee;
  }

  .downloadLink {
    display: block;
    background-color: #ffc90e;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    padding: 8px 0;
    font-weight: bold;
    font-size: 12px;
    max-width: 150px;

    &:hover {
      color: #3b3b3b;
    }
  }
}
</style>
