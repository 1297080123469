var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(_vm.items)?_c('v-data-table',{staticClass:"mb-3",class:_vm.$style.table,attrs:{"dense":"","hide-default-footer":"","must-sort":"","item-key":"name","items-per-page":-1,"items":_vm.items,"headers":[
        {value: 'inn', text: 'ИНН'},
        {value: 'name', text: 'Имя'},
        {value: 'control'} ],"item-class":function () { return _vm.$style.tr; }},scopedSlots:_vm._u([{key:"item.inn",fn:function(ref){
      var index = ref.index;
      var inn = ref.item.inn;
return [_c('div',{class:_vm.$style.field},[_c('inn-field',{attrs:{"value":inn,"not":_vm.getNot(index),"check":_vm.checkInn,"distributor":_vm.distributor},on:{"input":function (value) { return _vm.onInput(value, index); },"update":_vm.onUpdate}})],1)]}},(_vm.isReadClient)?{key:"item.name",fn:function(ref){
      var ref_item = ref.item;
      var name = ref_item.name;
      var inn = ref_item.inn;
return [(name && inn)?_c('router-link',{attrs:{"to":{ name: 'ClientListPage', params: { inn: inn } }}},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(name))])]):_vm._e()]}}:{key:"item.name",fn:function(ref){
      var name = ref.item.name;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(name))])]}},{key:"item.control",fn:function(ref){
      var index = ref.index;
return [_c('v-btn',{staticClass:"ml-2 float-right",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.onRemove(index)}}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-delete')}})],1)]}}],null,true)}):_vm._e(),_c('v-btn',{attrs:{"dense":"","depressed":"","disabled":_vm.disabled},on:{"click":_vm.onAdd}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-plus')}}),_vm._v(" Добавить ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }