<template>
  <div :class="$style.root">
    <v-form ref="form" v-model="valid" :class="$style.root">
      <audit-status-switch class="mb-6" :value="status" :disabled="formValid" :editingView="editingView" @input="onSubmit" @reset="onReset" />
      <v-alert v-if="error" class="mb-6" dense outlined type="error">
        <div v-html="error.error_description" />
      </v-alert>
      <audit-view-row v-if="fields.authorDistributorName" title="Дистрибутор (автор)" :value="fields.authorDistributorName" />

      <panel v-if="editingView" title="Проверяется"><audit-distributor-field :value="fields.distributor" @input="(value) => onDistributorInput(value)"/></panel>
      <audit-view-row v-else-if="fields.distributorName" title="Проверяется" :value="fields.distributorName" />

      <panel-difference v-if="!hasInn" title="Клиенты" :items="fields.clients" :diff="diff">
        <template v-slot:default="{ items }">
          <audit-client-table :value="items" />
        </template>
      </panel-difference>
      <audit-view-row title="Тип ИНН" :value="[audit.innType.name]" />
      <audit-view-row v-if="hasInn" title="ИНН" :value="fields.inn" />
      <audit-view-row v-if="hasInn" title="Клиент" :value="fields.clientName" />
      <audit-view-row title="Статус" :value="fields.status" />
      <audit-view-row title="Дата закрытия заявки" :value="fields.dateClose" />

      <audit-view-row v-if="viewOnly" title="Тип проверки по" :value="[audit.check.name]" />
      <panel v-else title="Тип проверки по">
        <audit-form-row title="Тип проверки по" v-model="fields.check" :items="checksList"/>
      </panel>

      <audit-view-row v-if="viewOnly" title="Контактное лицо" :value="fields.person" />
      <panel v-else title="Контактное лицо">
        <audit-form-row title="Контактное лицо" v-model="fields.person"/>
      </panel>

      <panel title="Документы">
        <file-table v-if="viewOnly" :value="audit.files"/>
        <file-list v-else :value="audit.files" @input="(value) => (files = value) " />
      </panel>

      <audit-view-row v-if="viewOnly" title="Выявлены ли нарушения" :value="[audit.violations.name]" />
      <panel v-else-if="fields.statusCode!='100_new'" title="Выявлены ли нарушения">
        <audit-form-row title="Выявлены ли нарушения" v-model="fields.violations" :items="violationsList"/>
      </panel>

      <audit-view-row v-if="viewOnly" title="Результат" :value="fields.preview" />
      <panel v-else-if="fields.statusCode!='100_new'" title="Результат">
        <audit-form-row title="Результат" component="v-textarea" v-model="fields.preview"/>
      </panel>

      <audit-view-row title="Комментарий" :value="fields.comment" />
    </v-form>
  </div>
</template>

<script>
import { get, head, toNumber } from 'lodash';
import { mapGetters } from 'vuex';
import client from '@/store/clients/api';
import { clientItemAdapter } from '@/store/clients/utils';
import { auditEmptyAdapter } from '@/store/audit/utils';

import AuditViewRow from '@/components/AuditViewRow/AuditViewRow';
import AuditForm from '@/mixins/AuditForm/AuditForm';
import AuditClientTable from '@/components/AuditClientTable/AuditClientTable';
import Panel from '@/components/Panel/Panel';
import AuditFormRow from '@/components/AuditFormRow/AuditFormRow';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import AuditStatusSwitch from '@/components/AuditStatusSwitch/AuditStatusSwitch';
import FileTable from '@/components/FileTable/FileTable';
import FileList from '@/components/FileList/FileList';
import AuditDistributorField from '@/components/AuditDistributorField/AuditDistributorField';

export default {
  name: 'AuditResultForm',
  mixins: [ AuditForm ],
  props: {
    editingView: { type: Boolean },
  },
  components: {
    Panel,
    AuditFormRow,
    AuditViewRow,
    PanelDifference,
    AuditStatusSwitch,
    AuditClientTable,
    FileTable,
    FileList,
    AuditDistributorField
  },
  data() {
    return {
      correct: false,
      inner: false,
      related: false,
    };
  },
  computed: {
    ...mapGetters({
      violationsList: 'audit/violationsList',
      checksList: 'audit/checksList',
      innTypeList: 'audit/innTypeList',
      accessAuditorRFO: 'user/accessAuditorRFO'
    }),
    viewOnly() {
      if(
              (this.fields.statusCode && (this.fields.statusCode=='300_success' || this.fields.statusCode=='500_decline'))
              || this.accessAuditorRFO
              || this.editingView
      ) return true;
      else return false;
    },
    distributorChoose: function () {
      return !!this.fields.distributor;
    },
    formValid: function () {
      return !(((!!this.fields.distributor && !!this.fields.clients[0].length) || (!!this.fields.inn && !!this.fields.inn[0])) && this.valid);
    },
    hasInn() {
      return (!!this.fields.inn && !!this.fields.inn[0]);
    }
  },
  methods: {
    onDistributorInput(id) {
      this.reset();
      this.$refs.form.validate();
      this.fields.distributor=id;
    },
    onUpdateInn(items) {
      const item = head(items) || {};
      const code = item.code || this.values.inn;
      this.correct = !!get(item, 'code', false);
      this.inner = get(item, 'inner', false);

      if (this.isCreate) {
        this.$refs.form.validate();

        code && this.$set(this.fields.inn, 0, code);

        if (this.inner && code) {
          client.item(code).then(result => {
            const client = auditEmptyAdapter(clientItemAdapter(result.result));
            if (client.fields) {
              this.fields.name=client.fields.name;
            }
          })
        }

        this.$snack({
          text: `Клиент ${['не', ''][toNumber(this.inner)]} найден в базе iPrice`,
          pos: 'top-center',
          actionText: 'Понятно',
        });
      }
    },
    onSubmit(status) {
      this.$refs.form.validate();
      if (this.valid) {
        this.submit(status, this.inner);
      }
    },
    onReset(){
      this.editingView = false;
      this.$emit('update:editingView', false);
      this.reset();
      this.$emit('reset');
    },
  },
  watch: {
    'statuses': {
      handler() {
        // Произойдет только если статусы не успели загрузиться на момент рендера
        this.onReset();
      },
    },
  }
}
</script>

<style module lang="scss">
.root{}
</style>
