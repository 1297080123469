<script>
import { get, last, reduce, set, isEqual, isArray, map, isNaN, omit } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { auditEmptyAdapter } from '@/store/audit/utils';
import STATUSES from '@/api/enums/statuses';

export default {
  name: 'AuditForm',
  props: {
    audit: {
      type: Object,
      default() {
        return auditEmptyAdapter();
      }
    }
  },
  data() {
    return {
      error: null,
      fields: {},
      files: [],
      valid: true,
      rules: {
        required: val => !!val || 'Обязательно для заполнения',
        numeric: val => !isNaN(+val) || 'Может содержать только цифры',
        phone: val => (!val || (/^(\+\d|\d)(\(\d*\)|\d-\d| |\d)*\d*$/gm.test(val) && val.length >= 6)) || 'Не корректный номер телефона'
      },
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      statuses: 'clients/statuses',
      distributor: 'user/distributor'
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isCreate() {
      return this.status === STATUSES.CREATURE;
    },
    isAdjustment() {
      return this.status === STATUSES.ADJUSTMENT;
    },
    isEditing() {
      return this.status === STATUSES.EDITING;
    },
    id() {
      return get(this.audit, 'id', null);
    },
    inn(){
      return get(this, 'audit.inn') || last(get(this, 'fields.inn', []));
    },
    status() {
      return get(this.audit, 'status.code', null);
    },
    values() {
      return reduce(this.fields, (acc, cur, key) => {
        if (!this.isAdmin && (key === 'distributors')) return acc; // Пропускать поле дистребьютера для не администратора
        const value = last(cur);
        set(acc, key, isArray(value) ? map(value, 'id') : value);
        return acc;
      }, {});
    },
    modified() {
      if (this.isAdjustment && this.files.length) return true;
      const drop = ['sales', 'comment'];
      const base = omit(JSON.parse(JSON.stringify(this.fields)), drop);
      const current = omit(JSON.parse(JSON.stringify(get(this.audit, 'fields', {}))), drop);
      return !isEqual(base, current);
    },
    watchData() {
      return this.id + this.audit.inn + this.status;
    }
  },
  methods: {
    ...mapActions({
      'update': 'audit/update',
    }),
    submit(status){
        let fields = {};
        if(this.isCreate) {
            fields.inn = this.fields.inn;
            fields.distributor = this.fields.distributor;
            fields.clients = this.fields.clients[0];
            fields.distributorName = this.fields.distributorName;
            fields.comment = this.fields.comment;
        }
        else {
            fields.id = this.id;
            fields.files = map(this.files, 'id');
            fields.person = this.fields.person;
            fields.preview = this.fields.preview;
            fields.check = this.fields.check;
            fields.violations = this.fields.violations;
            fields.distributor = this.fields.distributor;
            fields.status = status;
        }

      this.update(fields)
      .then(id => this.$emit('submit', id))
      .catch((error) => (this.error = error));
    },
    reset() {
      const audit = JSON.parse(JSON.stringify(this.audit));
      this.fields = get(audit, 'fields', {});
      this.files = get(audit, 'files', []);
    }
  },
  watch: {
    'watchData': {
      immediate: true,
      handler() { this.reset() }
    },
  },
}
</script>
