import { get, toNumber, set, isBoolean } from 'lodash';
import Swal from 'sweetalert2';

/**
 * Доработка запроса
 * @param config
 * @returns {*}
 */
export const config = (config) => {
  if (get(config.params, 'logout')) return config;
  const sessid = localStorage.getItem('sessid');
  if (config.data) config.data.append('sessid', sessid);
  else set(config, 'params.sessid', sessid);
  return config;
}

/**
 * Перехват id сессии и установка в sessionStore sessid
 * @param response
 * @returns {*}
 */
export const sessid = (response) => {
  const sessid = get(response, 'data.result.result.sessid');
  sessid && localStorage.setItem('sessid', sessid);
  const isAuthorized = get(response, 'data.result.result.isAuthorized');
  if (isBoolean(isAuthorized)) localStorage.setItem('auth', isAuthorized);
  return response;
}

  /**
 * Обработка ошибок запросов
 * @param error
 * @returns {*}
 */
export const error = (error) => {
  const response = get(error, 'response');
  const code = toNumber(get(response, 'status'));
  const title = get(response, 'statusText');
  const key = get(response, 'data.error');
  const description = get(error, 'data.error_description');
  if ([401, 403].includes(code)) {
    localStorage.removeItem('auth');
    window.location = '/';
  }
  if ([500].includes(code)) Swal.fire({
    title,
    text: `[${key}] ${description}`,
    target: '.v-application',
    customClass: {
      confirmButton: 'v-btn v-btn--contained theme--light v-size--default primary',
    },
  });
  return response || error;
}
