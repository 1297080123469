import {get, includes, set, map, toNumber, findIndex} from 'lodash';
import task from './api';
import { enumerationAdapter } from '@/store/utils';
import { taskItemAdapter } from '@/store/tasks/utils';

const START = 'START';
const END = 'END';
const UPDATE_COUNT = 'UPDATE_COUNT';
const UPDATE_ITEMS = 'UPDATE_ITEMS';
const UPDATE_CURRENT = 'UPDATE_CURRENT';
const REMOVE_CURRENT = 'REMOVE_CURRENT';
const UPDATE_STATUSES = 'UPDATE_STATUSES';
const UPDATE_NEW_STATUSES = 'UPDATE_NEW_STATUSES';
const UPDATE_ADVANCE_DISCOUNT_PERIODS = 'UPDATE_ADVANCE_DISCOUNT_PERIODS';
const UPDATE_REGIONS = 'UPDATE_REGIONS';
const UPDATE_TYPES = 'UPDATE_TYPES';
const UPDATE_DISTRIBUTORS = 'UPDATE_DISTRIBUTORS';
const PUSH_COMMENT = 'PUSH_COMMENT';

export default {
  namespaced: true,
  state: {
    items: [],
    count: 0,
    current: null,
    statuses: [],
    newStatuses: [],
    regions: [],
    types: [],
    distributors: [],
    advanceDiscountPeriods: [],
    pending: {
      update: false,
      send: false,
      fetchItems: false,
      fetchCurrent: false,
      fetchStatuses: false,
      fetchNewStatuses: false,
      fetchTypes: false,
      fetchDistributors: false,
      fetchAdvanceDiscountPeriods: false,
    }
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    current(state) {
      return taskItemAdapter(state.current);
    },
    items(state) {
      return map(state.items, taskItemAdapter);
    },
    count(state) {
      return toNumber(state.count);
    },
    statuses(state) {
      return map(state.statuses, enumerationAdapter);
    },
    newStatuses(state) {
      return map(state.newStatuses, enumerationAdapter);
    },
    advanceDiscountPeriods(state) {
      return map(state.advanceDiscountPeriods, enumerationAdapter);
    },
    regions(state) {
      return map(state.regions, enumerationAdapter);
    },
    types(state) {
      return map(state.types, enumerationAdapter);
    },
    distributors(state) {
      return map(state.distributors, enumerationAdapter);
    }
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [REMOVE_CURRENT](state) {
      state.current = null;
    },
    [UPDATE_CURRENT](state, task) {
      state.current = task;
      const index = findIndex(state.items, { id: task.id });
      if (index + 1) {
        state.items.splice(index, 1, task);
      }
    },
    [UPDATE_ITEMS](state, items) {
      state.items = items;
    },
    [UPDATE_COUNT](state, count) {
      state.count = count;
    },
    [UPDATE_STATUSES](state, items){
      state.statuses = items;
    },
    [UPDATE_NEW_STATUSES](state, items){
      state.newStatuses = items;
    },
    [UPDATE_ADVANCE_DISCOUNT_PERIODS](state, items){
      state.advanceDiscountPeriods = items;
    },
    [UPDATE_REGIONS](state, items){
      state.regions = items;
    },
    [UPDATE_TYPES](state, items){
      state.types = items;
    },
    [PUSH_COMMENT](state, comment) {
      const index = findIndex(state.items, { id: task.id });
      const comments = get(state.current, 'comments');
      comments ? comments.push(comment) : set(state.current, 'comments', [comment]);
      if (index + 1) {
        set(state, 'items.comments', state.current.comments);
      }
    },
    [UPDATE_DISTRIBUTORS](state, items) {
      state.distributors = items;
    }
  },
  actions: {
    async fetchCurrent({ commit }, id) {
      commit(START, 'fetchCurrent');
      const { result } = await task.item(id);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_CURRENT, result);
      }
      commit(END, 'fetchCurrent');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async removeCurrent({ commit }) {
      commit(REMOVE_CURRENT);
    },
    async fetchItems({ commit }, { page, size, sort, filter }) {
      commit(START, 'fetchItems');
      const { result } = await task.list(page, size, sort, filter);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_ITEMS, result.items);
        commit(UPDATE_COUNT, toNumber(get(result, 'navResult.navRecordCount', 0)));
      }
      commit(END, 'fetchItems');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchStatuses({ commit }) {
      commit(START, 'fetchStatuses');
      const { result } = await task.statuses();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_STATUSES, result.items);
      commit(END, 'fetchStatuses');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchNewStatuses({ commit }) {
      commit(START, 'fetchNewStatuses');
      const { result } = await task.newStatuses();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_NEW_STATUSES, result.items);
      commit(END, 'fetchNewStatuses');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchAdvanceDiscountPeriods({ commit }) {
      commit(START, 'fetchAdvanceDiscountPeriods');
      const { result } = await task.advanceDiscountPeriods();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_ADVANCE_DISCOUNT_PERIODS, result.items);
      commit(END, 'fetchAdvanceDiscountPeriods');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchRegions({ commit }) {
      commit(START, 'fetchRegions');
      const { result } = await task.regions();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_REGIONS, result.items);
      commit(END, 'fetchRegions');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchDistributors({ commit }) {
      commit(START, 'fetchDistributors');
      const { result } = await task.distributors();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_DISTRIBUTORS, result.items);
      commit(END, 'fetchDistributors');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchTypes({ commit }) {
      commit(START, 'fetchTypes');
      const { result } = await task.types();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_TYPES, result.items);
      commit(END, 'fetchTypes');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async update({ commit }, fields) {
      commit(START, 'update');
      const { result } = await task.update(fields);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_CURRENT, result);
      }
      commit(END, 'update');

      if (!success) throw {
        error: 'Ошибка при попытке сохранить данные заявки',
        error_description: get(result, 'message'),
      }

      return get(result, 'id', null);
    },
    async send({ commit }, {id, message}) {
      commit(START, 'send');
      const { result } = await task.comment(id, message);
      const success = !get(result, 'error');
      if (success) commit(PUSH_COMMENT, result);
      if (!success) throw {
        error: 'Ошибка отправки сообщения',
        error_description: get(result, 'message'),
      };
      commit(END, 'send');
    }
  },
}
