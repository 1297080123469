<template>
  <tr :class="$style.root">
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="name"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="inn"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="status"
            :items="statuses"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="classification"
            :items="classifications"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="distributor"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import {get, debounce, pickBy} from 'lodash';
import {mapGetters} from 'vuex';

export default {
  name: 'RegionalClientListFilter',
  props: {
    value: { type: Object },
  },
  data() {
    return {
      name: get(this.value, 'name', ''),
      inn: get(this.value, 'inn', ''),
      status: get(this.value, 'status', ''),
      classification: get(this.value, 'classification', ''),
      distributor: get(this.value, 'distributor', ''),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      statuses: 'clients/statuses',
      classifications: 'clients/classifications',
    })
  },
  methods: {
    onChange: debounce(function() {
      this.$emit('input', pickBy({
        name: this.name,
        inn: this.inn,
        district: this.district,
        region: this.region,
        status: this.status,
        classification: this.classification,
        distributorsName: this.distributor,
        // distributors: [#{id}] можно передавать конкретные id подробнее https://youtrack.ivsupport.ru/issue/IC-35#comment=68-276312
      }, Boolean));
    }, 600),
  }
}
</script>

<style module lang="scss">
.root {
  background: none !important;
  .control {
    width: 250px;
  }
}
</style>
