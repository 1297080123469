<template>
  <tr :class="$style.root">
    <td v-if="showDateColumn">
      <div :class="$style.control" />
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="name"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="inn"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control" />
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="district"
            :items="districts"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="region"
            :items="district ? regionsByDistrict[district] : regions"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-if="!preFilter || (preFilter && !preFilter.status)"
            v-model="status"
            :items="statuses"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-if="!preFilter || (preFilter && !preFilter.recommendedStatus)"
            v-model="recommendedStatus"
            :items="statuses"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td v-if="isAdmin || isAdminView">
      <div :class="$style.control">
        <v-select
            v-model="bpConnected"
            :items="bpConnectedList"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="classification"
            :items="classifications"
            hideSelected
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td v-if="isAdmin || isAdminView">
      <div :class="$style.control">
        <v-text-field
            v-model="distributor"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import {get, debounce, pickBy} from 'lodash';
import {mapGetters} from 'vuex';

export default {
  name: 'ClientListFilter',
  props: {
    value: { type: Object },
    preFilter: { type: Object || undefined },
    showDateColumn: { type: Boolean, default: false },
  },
  data() {
    return {
      name: get(this.value, 'name', ''),
      inn: get(this.value, 'inn', ''),
      region: get(this.value, 'region', ''),
      district: get(this.value, 'district', ''),
      status: get(this.value, 'status', ''),
      classification: get(this.value, 'classification', ''),
      distributor: get(this.value, 'distributor', ''),
      bpConnected: get(this.value, 'bpConnected', ''),
      recommendedStatus: get(this.value, 'recommendedStatus', ''),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      regions: 'clients/regions',
      districts: 'clients/districts',
      statuses: 'clients/statuses',
      classifications: 'clients/classifications',
      regionsByDistrict: 'clients/regionsByDistrict',
      bpConnectedList: 'clients/bpConnectedList',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    }
  },
  methods: {
    onChange: debounce(function() {
      this.$emit('input', pickBy({
        name: this.name,
        inn: this.inn,
        district: this.district,
        region: this.region,
        status: this.status,
        classification: this.classification,
        distributorsName: this.distributor,
        bpConnected: this.bpConnected,
        recommendedStatus: this.recommendedStatus,
        // distributors: [#{id}] можно передавать конкретные id подробнее https://youtrack.ivsupport.ru/issue/IC-35#comment=68-276312
      }, Boolean));
    }, 600),
  },
  watch: {
    district: {
      handler() {
        this.region = '';
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  background: none !important;
  .control {
    width: 250px;
  }
}
</style>
