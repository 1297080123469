<template>
  <v-autocomplete
      ref="input"
      :value="value"
      :items="items"
      :search-input.sync="search"
      dense
      hide-details="auto"
      @input="(value) => $emit('input', value)"
  />
</template>

<script>
import {get, map} from 'lodash';
import task from '@/store/audit/api';
import { enumerationIdsAdapter } from '@/store/utils';
export default {
  name: 'AuditDistributorField',
  props: {
    name: {type: String},
    value: {type: String},
  },
  data() {
    return {
      search: '',
      items: [],
      pending: false
    }
  },
  watch: {
    search(value) {
      this.$emit('word', value);
      this.pending = true;
      task.distributors({ name: value || this.name })
      .then(result => {
        const success = !get(result, 'result.error');
        if (success) {
          this.items = map(get(result, 'result.items'), enumerationIdsAdapter);
        }
        if (!success) throw {
          error: 'Ошибка получения данных',
          error_description: get(result, 'message'),
        };
      })
      .finally(() => {
        this.pending = false;
      })
    }
  },
  mounted() {
    // Грязный хак для проверки валиднасти inn по монтированию
    this.$nextTick(() => {
      setTimeout(() => {
        if (!this.value) this.$refs.input.$el.querySelector('input').focus();
      }, 200);
    });
  }
}
</script>

<style module lang="scss"></style>
