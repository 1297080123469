var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:_vm.$style.root,attrs:{"dense":"","must-sort":"","hide-default-header":"","hide-default-footer":_vm.count < 6,"items-per-page":5,"items":_vm.value,"item-class":function () { return _vm.$style.tr; },"headers":[
      {value: 'name', text: 'Название'},
      {value: 'value', text: 'Процент %'} ],"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: [5, 10, 20, -1],
      itemsPerPageText: '',
    }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var ref_item = ref.item;
    var code = ref_item.code;
    var name = ref_item.name;
return [_vm._v(" "+_vm._s(name || _vm._.get(_vm._.find(_vm.sales, 'code'), 'name') || code)+" ")]}},{key:"item.value",fn:function(ref){
    var value = ref.item.value;
return [_vm._v(" "+_vm._s(value)+"% ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }