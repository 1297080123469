<template>
  <v-dialog persistent max-width="800" :value="true" @click:outside="close" @keydown.esc="close">
    <well title="CISlink">
      <template v-slot:title="{ title }">
        {{title}}
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <cis-link-quarter-table :inn="$route.params.inn || task.inn" />
    </well>
  </v-dialog>
</template>

<script>
import Well from '@/components/Well/Well';
import CisLinkQuarterTable from '@/components/CisLinkQuarterTable/CisLinkQuarterTable';

import { mapGetters } from 'vuex';
export default {
  name: 'CisLinkDialog',
  components: {
    Well,
    CisLinkQuarterTable,
  },
  computed: {
    ...mapGetters({
      task: 'tasks/current',
    }),
  },
  methods: {
    close() {
      this.$router.push({ hash: null });
    }
  },
}
</script>
