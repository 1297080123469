import { get, includes, map, set } from 'lodash';
import client from '@/store/clients/api';
import { advanceItemAdapter } from './utils';

const START = 'START';
const END = 'END';
const UPDATE_ITEMS = 'UPDATE_ITEMS';

export default {
  namespaced: true,
  state: {
    items: [],
    pending: {
      fetchItems: false,
    }
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    items(state) {
      return map(state.items, advanceItemAdapter);
    },
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [UPDATE_ITEMS](state, items) {
      state.items = items;
    },
  },
  actions: {
    async fetchItems({ commit }, { inn }) {
      commit(START, 'fetchItems');
      const { result } = await client.advanceDiscountHistoryList(inn);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_ITEMS, result.items);
      }
      commit(END, 'fetchItems');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async clearItems({ commit }) {
      commit(UPDATE_ITEMS, []);
    },
  }
};
