<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <promotions-form
          v-if="editing"
          :promotion="promotion"
          :key="promotion.id"
          :editingView.sync="editingView"
          @submit="onSubmit"
      />
      <promotions-view v-else :promotion="promotion"/>
    </div>
    <panel v-if="commenting || (promotion.comments && promotion.comments.length)" title="Комментарии">
      <comment-list :value="promotion.comments">
        <promotions-comment-form v-if="commenting && !isAdminView" :id="promotion.id" />
      </comment-list>
    </panel>
    <div v-if="pending" :class="$style.loader" />
  </div>
</template>

<script>
import { get, includes } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import STATUSES from '@/api/enums/promotion';

import PromotionsView from "@/components/PromotionsView/PromotionsView.vue";
import PromotionsForm from "@/components/PromotionsForm/PromotionsForm.vue";
import { promotionEmptyAdapter } from "@/store/promotions/utils";
import CommentList from "@/components/CommentList/CommentList.vue";
import Panel from "@/components/Panel/Panel.vue";
import PromotionsCommentForm from "@/components/PromotionsCommentForm/PromotionsCommentForm.vue";

export default {
  name: 'PromotionsDetail',
  components: {
    PromotionsCommentForm,
    Panel, CommentList,
    PromotionsForm,
    PromotionsView,
  },
  props: {
    id: { type: String, required: true },
    editingView: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'promotions/pending',
      current: 'promotions/current',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    editing() {
      const status = get(this.promotion, 'status.code');
      return includes([STATUSES.CREATURE], status) || (!this.isAdmin && includes([STATUSES.ADJUSTMENT], status));
    },
    commenting() {
      return includes([STATUSES.TOAPPROVE, STATUSES.ADJUSTMENT], get(this.promotion, 'status.code'));
    },
    isCreate() {
      return this.id === 'creation';
    },
    promotion() {
      if (this.isCreate) return promotionEmptyAdapter();
      return this.current;
    }
  },
  methods: {
    ...mapActions({
      'fetchCurrent': 'promotions/fetchCurrent',
      'removeCurrent': 'promotions/removeCurrent',
    }),
    onUpdate() {
      this.isCreate || this.fetchCurrent(this.id);
    },
    onSubmit() {
      this.$router.push({ name: 'PromotionsListPage', params: { reload: true } });
    },
  },
  watch: {
    id() {
      this.onUpdate();
    },
    editingView() {
      this.$emit('update:editingView', this.editingView)
    },
    canEdit() {
      this.$emit('update:canEdit', this.canEdit)
    }
  },
  beforeMount() { this.onUpdate() },
  beforeDestroy() { this.removeCurrent() }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .inner {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
