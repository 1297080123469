<template>
  <div :class="$style.root">
    <downloads-list :reload="reload"/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import DownloadsList from '@/components/DownloadsList/DownloadsList';

  export default {
    name: 'DownloadsListPage',
    components: {
      DownloadsList,
    },
    props: {
      id: { type: String },
      reload: { type: Boolean },
      view: { type: String },
    },
    computed: {
      ...mapGetters({
        access: 'access',
        userParams: 'user/userParams',
      }),
    },
    watch: {
      userParams: {
        handler() {
          if(this.userParams && this.userParams.navigation && !this.userParams.navigation[this.$options.name] && this.userParams.defaultPage) this.$router.push({ name: this.userParams.defaultPage});
        },
        deep: true,
      }
    }
  }
</script>

<style module lang="scss">
  .root{
    .drawer{
      padding: 6px 16px 16px;
    }
  }
</style>
