<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <audit-result-form
          v-if="!editing"
          :audit="audit"
          :key="audit.id"
          :editingView.sync="editingView"
          @submit="onSubmit"
      />
      <audit-result-view v-else :audit="audit"/>
    </div>
    <div v-if="pending" :class="$style.loader" />
  </div>
</template>

<script>
import { get, includes } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import STATUSES from '@/api/enums/statuses';

import AuditResultView from '@/components/AuditResultView/AuditResultView';
import AuditResultForm from '@/components/AuditResultForm/AuditResultForm';
import { auditEmptyAdapter } from '@/store/audit/utils';

export default {
  name: 'AuditResultDetail',
  components: {
    AuditResultView,
    AuditResultForm
  },
  props: {
    id: { type: String, required: true },
    editingView: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'audit/pending',
      current: 'audit/current',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    editing() {
      const status = get(this.audit, 'status.code');
      return includes([STATUSES.CREATURE], status) || !this.isAdmin && includes([STATUSES.ADJUSTMENT], status);
    },
    isCreate() {
      return this.id === 'creation';
    },
    audit() {
      if (this.isCreate) return auditEmptyAdapter();
      return this.current;
    }
  },
  methods: {
    ...mapActions({
      'fetchCurrent': 'audit/fetchCurrent',
      'removeCurrent': 'audit/removeCurrent',
    }),
    onUpdate() {
      this.isCreate || this.fetchCurrent(this.id);
    },
    onSubmit() {
      this.$router.push({ name: 'AuditResultListPage', params: { reload: true } });
    },
  },
  watch: {
    id() {
      this.onUpdate();
    },
    editingView() {
      this.$emit('update:editingView', this.editingView)
    }
  },
  beforeMount() { this.onUpdate() },
  beforeDestroy() { this.removeCurrent() }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .inner {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
