<template>
  <div :class="$style.root">
    <promotions-status-switch v-if="hasControls" :value="status" @input="onSubmit" class="mb-6"/>
    <task-view-row title="Название акции" :value="fields.name"/>
    <task-view-row title="ИНН клиента" :value="fields.clientInn"/>
    <task-view-row title="Название клиента" :value="fields.clientName"/>
    <task-view-row title="Округ" :value="fields.district"/>
    <task-view-row title="ЦКГ/Классификация" :value="fields.classification"/>
    <task-view-row title="Статус Клиента" :value="fields.clientStatus"/>
    <task-view-row title="Дистрибутор" :value="fields.distributorName"/>
    <panel title="Информация по акции">
      <task-view-row v-if="fields.salePoint[0]" title="Точка продаж" :value="fields.salePoint"/>
      <task-view-row v-if="fields.site[0]" title="Сайт" :value="fields.site"/>
    </panel>
    <panel v-if="promotion.skuFile && promotion.skuFile.length" title="Акционное оборудование">
      <file-table :value="promotion.skuFile" />
    </panel>
    <panel title="Запрашиваемая скидка">
      <task-view-row title="Условия акции" :value="fields.conditions" :items="conditionsList"/>
      <task-view-row title="Размер снижения РРЦ, %" :value="fields.discount"/>
      <task-view-row title="Срок действия акции" :value="activeDateRange"/>
    </panel>
  </div>
</template>

<script>
import { first, get, includes } from 'lodash';
import { mapGetters } from 'vuex';
import STATUSES from '@/api/enums/statuses';
import TaskViewRow from "@/components/TaskViewRow/TaskViewRow.vue";
import PromotionsForm from "@/mixins/PromotionsForm/PromotionsForm.vue";
import PromotionsStatusSwitch from "@/components/PromotionsStatusSwitch/PromotionsStatusSwitch.vue";
import Panel from "@/components/Panel/Panel.vue";
import FileTable from "@/components/FileTable/FileTable.vue";

export default {
  name: 'PromotionsView',
  mixins: [PromotionsForm],
  components: {
    FileTable, Panel,
    PromotionsStatusSwitch,
    TaskViewRow,
  },
  computed: {
    ...mapGetters({
      conditionsList: 'promotions/conditionsList',
    }),
    hasControls() {
      return this.promotion.canEdit;
    },
    isAdmin() {
      return this.access(({ADMIN}, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ADMIN_VIEW}, ROLE) => ADMIN_VIEW === ROLE);
    },
    diff() {
      return !includes([STATUSES.APPROVAL, STATUSES.ADJUSTMENT], get(this.promotion, 'status.code'));
    },
    activeDateRange() {
      if (!first(this.fields.activeFrom) && !first(this.fields.activeTo)) return '';
      else return [first(this.fields.activeFrom) + ' – ' + first(this.fields.activeTo)];
    },
  },
  methods: {
    onSubmit(status) {
      this.submit(status);
    }
  }
}
</script>

<style module lang="scss">
.root {
}
</style>
