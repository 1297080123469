import { get, includes, map, set, toNumber, findIndex, merge, groupBy, mapValues } from 'lodash';
import client from '@/store/clients/api';
import { enumerationAdapter, enumerationCKGAdapter } from '@/store/utils';
import { clientItemAdapter } from '@/store/clients/utils';

const START = 'START';
const END = 'END';
const UPDATE_COUNT = 'UPDATE_COUNT';
const UPDATE_ITEMS = 'UPDATE_ITEMS';
const UPDATE_CURRENT = 'UPDATE_CURRENT';
const REMOVE_CURRENT = 'REMOVE_CURRENT';
const UPDATE_SALES = 'UPDATE_SALES';
const UPDATE_REGIONS = 'UPDATE_REGIONS';
const UPDATE_DISTRICTS = 'UPDATE_DISTRICTS';
const UPDATE_STATUSES = 'UPDATE_STATUSES';
const UPDATE_CLASSIFICATIONS = 'UPDATE_CLASSIFICATIONS';
const UPDATE_CKG_LIST = 'UPDATE_CKG_LIST';
const UPDATE_BP_CONNECTED_LIST = 'UPDATE_BP_CONNECTED_LIST';

export default {
  namespaced: true,
  state: {
    items: [],
    count: 0,
    current: null,
    sales: [],
    regions: [],
    districts: [],
    statuses: [],
    classifications: [],
    ckgList: [],
    bpConnectedList: [],
    pending: {
      fetchExel: false,
      fetchItems: false,
      fetchCurrent: false,
      fetchCisLink: false,
      fetchSales: false,
      fetchStatuses: false,
      fetchClassifications: false,
      fetchCKGList: false,
      fetchSalesByStatus: false,
      fetchBPConnectedList: false,
    }
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    current(state) {
      return clientItemAdapter(state.current);
    },
    items(state) {
      return map(state.items, clientItemAdapter);
    },
    count(state) {
      return toNumber(state.count);
    },
    sales(state) {
      return map(state.statuses, enumerationAdapter);
    },
    statuses(state) {
      return map(state.statuses, enumerationAdapter);
    },
    regions(state) {
      return map(state.regions, enumerationAdapter);
    },
    districts(state) {
      return map(state.districts, enumerationAdapter);
    },
    regionsByDistrict(state) {
      return mapValues(groupBy(state.regions, 'district'), (items) => map(items, enumerationAdapter));
    },
    classifications(state) {
      return map(state.classifications, enumerationAdapter);
    },
    ckgList(state) {
      return map(state.ckgList, enumerationCKGAdapter);
    },
    bpConnectedList(state) {
      return map(state.bpConnectedList, enumerationAdapter);
    },
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [REMOVE_CURRENT](state) {
      state.current = null;
    },
    [UPDATE_CURRENT](state, client) {
      state.current = client;
      const index = findIndex(state.items, { id: client.id });
      if (index + 1) {
        state.items.splice(index, 1, client);
      }
    },
    [UPDATE_ITEMS](state, items) {
      state.items = items;
    },
    [UPDATE_COUNT](state, count) {
      state.count = count;
    },
    [UPDATE_SALES](state, items) {
      state.sales = items;
    },
    [UPDATE_STATUSES](state, items){
      state.statuses = items;
    },
    [UPDATE_REGIONS](state, items){
      state.regions = items;
    },
    [UPDATE_DISTRICTS](state, items){
      state.districts = items;
    },
    [UPDATE_CLASSIFICATIONS](state, items){
      state.classifications = items;
    },
    [UPDATE_CKG_LIST](state, items){
      state.ckgList = items;
    },
    [UPDATE_BP_CONNECTED_LIST](state, items){
      state.bpConnectedList = items;
    },
  },
  actions: {
    async fetchCurrent({ commit }, inn) {
      commit(START, 'fetchCurrent');
      const { result } = await client.item(inn).then(result => {
        if (get(result, 'result.code')) return result;
        // TODO если нет в базе создадим предзаполненого клиента на основе данных по inn
        return client.find(inn).then((result) => {
          const client = get(result, ['result', 'items', inn]);
          return merge(result, {
            result: {
              name: get(client, 'name'),
              code: get(client, 'code'),
              displayProperties: {
                address: {
                  value: get(client, 'address'),
                },
                phone: {
                  value: get(client, 'phone'),
                },
              }
            },
          })
        });
      });
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_CURRENT, result);
      }
      commit(END, 'fetchCurrent');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async removeCurrent({ commit }) {
      commit(REMOVE_CURRENT);
    },
    async fetchItems({ commit }, { page, size, sort, filter }) {
      commit(START, 'fetchItems');
      commit(UPDATE_ITEMS, []);
      const { result } = await client.list(page, size, sort, filter);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_ITEMS, result.items);
        commit(UPDATE_COUNT, toNumber(get(result, 'navResult.navRecordCount', 0)));
      }
      commit(END, 'fetchItems');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchSales({ commit }) {
      commit(START, 'fetchSales');
      const { result } = await client.sales();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_SALES, result.items);
      commit(END, 'fetchSales');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchRegions({ commit }) {
      commit(START, 'fetchRegions');
      const { result } = await client.regions();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_REGIONS, result.items);
      commit(END, 'fetchRegions');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchDistricts({ commit }) {
      commit(START, 'fetchDistricts');
      const { result } = await client.districts();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_DISTRICTS, result.items);
      commit(END, 'fetchDistricts');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchStatuses({ commit }) {
      commit(START, 'fetchStatuses');
      const { result } = await client.statuses();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_STATUSES, result.items);
      commit(END, 'fetchStatuses');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchClassifications({ commit }) {
      commit(START, 'fetchClassifications');
      const { result } = await client.classifications();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_CLASSIFICATIONS, result.items);
      commit(END, 'fetchClassifications');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchCKGList({ commit }) {
      commit(START, 'fetchCKGList');
      const { result } = await client.ckgList();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_CKG_LIST, result.items);
      commit(END, 'fetchCKGList');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchBPConnectedList({ commit }) {
      commit(START, 'fetchBPConnectedList');
      const { result } = await client.bpConnectedList();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_BP_CONNECTED_LIST, result.items);
      commit(END, 'fetchBPConnectedList');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchExel({ commit }, { page, size, sort, filter, type }) {
      commit(START, 'fetchExel');
      const { result } = await client.excel(page, size, sort, filter, type);
      const success = !get(result, 'error');
      if (success) {
        const url = get(result, 'file.path');
        const name = get(result, 'file.name', 'list');
        if (url) {
          const a = document.createElement('a');
          a.href = url;
          a.download = name;
          a.click();
          a.remove();
        }
      }
      commit(END, 'fetchExel');

      if (!success) throw {
        error: 'Ошибка экспорта данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchImportExcel({ commit }, { files, distributor }) {
      commit(START, 'fetchImportExcel');
      const { result } = await client.importSaleExcel(files, distributor);
      commit(END, 'fetchImportExcel');
      return result;
    },
  },
}
