import { toString } from 'lodash';
import { Api } from '@/api';
export class Affiliation extends Api {
  list(inn) {
    return this.request('clients.findAffiliated', {
      params: {
        inn: toString(inn),
      }
    })
  }
}
export default new Affiliation();
