var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:_vm.$style.root,attrs:{"dense":"","must-sort":"","hide-default-footer":_vm.count < 6,"items-per-page":5,"items":_vm.items,"item-class":function (ref) {
    var _obj;

    var cls = ref.cls;
    var blocked = ref.blocked;
    return [_vm.$style.tr, cls, ( _obj = {}, _obj[_vm.$style.blocked] = blocked, _obj )];
},"headers":_vm.hasRealizations ? [
      {value: 'inn', text: 'ИНН'},
      {value: 'name', text: 'Имя'},
      {value: 'realizations.period', text: 'Период'},
      {value: 'realizations.sum', text: 'Сумма, руб.'} ] : [
      {value: 'inn', text: 'ИНН'},
      {value: 'name', text: 'Имя'} ],"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: [5, 10, 20, -1],
      itemsPerPageText: '',
    }},on:{"click:row":function (ref) {
        var inn = ref.inn;
        var blocked = ref.blocked;

        return blocked || _vm.$router.push({ name: 'ClientListPage', params: { inn: inn } });
}}})}
var staticRenderFns = []

export { render, staticRenderFns }