import {get, includes, set, map, toNumber, findIndex} from 'lodash';
import audit from './api';
import { enumerationAdapter } from '@/store/utils';
import { auditItemAdapter } from '@/store/audit/utils';
import task from "../tasks/api";

const START = 'START';
const END = 'END';
const UPDATE_COUNT = 'UPDATE_COUNT';
const UPDATE_ITEMS = 'UPDATE_ITEMS';
const UPDATE_CURRENT = 'UPDATE_CURRENT';
const REMOVE_CURRENT = 'REMOVE_CURRENT';
const UPDATE_STATUSES = 'UPDATE_STATUSES';
const UPDATE_DISTRIBUTORS = 'UPDATE_DISTRIBUTORS';
const UPDATE_VIOLATIONS_LIST = 'UPDATE_VIOLATIONS_LIST';
const UPDATE_CHECKS_LIST = 'UPDATE_CHECKS_LIST';
const UPDATE_INN_TYPE_LIST = 'UPDATE_INN_TYPE_LIST';
const UPDATE_REGIONS = 'UPDATE_REGIONS';

export default {
  namespaced: true,
  state: {
    items: [],
    count: 0,
    current: null,
    statuses: [],
    distributors: [],
    violationsList: [],
    innTypeList: [],
    checksList: [],
    regions: [],
    pending: {
      update: false,
      send: false,
      fetchExel: false,
      fetchItems: false,
      fetchCurrent: false,
      fetchStatuses: false,
      fetchDistributors: false,
      fetchViolationsList: false,
      fetchInnTypeList: false,
      fetchChecksList: false,
    }
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    current(state) {
      return auditItemAdapter(state.current);
    },
    items(state) {
      return map(state.items, auditItemAdapter);
    },
    count(state) {
      return toNumber(state.count);
    },
    statuses(state) {
      return map(state.statuses, enumerationAdapter);
    },
    distributors(state) {
      return map(state.distributors, enumerationAdapter);
    },
    violationsList(state) {
      return map(state.violationsList, enumerationAdapter);
    },
    innTypeList(state) {
      return map(state.innTypeList, enumerationAdapter);
    },
    checksList(state) {
      return map(state.checksList, enumerationAdapter);
    },
    regions(state) {
      return map(state.regions, enumerationAdapter);
    },
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [REMOVE_CURRENT](state) {
      state.current = null;
    },
    [UPDATE_CURRENT](state, audit) {
      state.current = audit;
      const index = findIndex(state.items, { id: audit.id });
      if (index + 1) {
        state.items.splice(index, 1, audit);
      }
    },
    [UPDATE_ITEMS](state, items) {
      state.items = items;
    },
    [UPDATE_COUNT](state, count) {
      state.count = count;
    },
    [UPDATE_STATUSES](state, items){
      state.statuses = items;
    },
    [UPDATE_DISTRIBUTORS](state, items) {
      state.distributors = items;
    },
    [UPDATE_VIOLATIONS_LIST](state, items){
      state.violationsList = items;
    },
    [UPDATE_INN_TYPE_LIST](state, items){
      state.innTypeList = items;
    },
    [UPDATE_CHECKS_LIST](state, items){
      state.checksList = items;
    },
    [UPDATE_REGIONS](state, items){
      state.regions = items;
    }
  },
  actions: {
    async fetchCurrent({ commit }, id) {
      commit(START, 'fetchCurrent');
      const { result } = await audit.item(id);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_CURRENT, result);
      }
      commit(END, 'fetchCurrent');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async removeCurrent({ commit }) {
      commit(REMOVE_CURRENT);
    },
    async fetchItems({ commit }, { page, size, sort, filter }) {
      commit(START, 'fetchItems');
      const { result } = await audit.list(page, size, sort, filter);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_ITEMS, result.items);
        commit(UPDATE_COUNT, toNumber(get(result, 'navResult.navRecordCount', 0)));
      }
      commit(END, 'fetchItems');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchStatuses({ commit }) {
      commit(START, 'fetchStatuses');
      const { result } = await audit.statuses();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_STATUSES, result.items);
      commit(END, 'fetchStatuses');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchViolationsList({ commit }) {
      commit(START, 'fetchViolationsList');
      const { result } = await audit.violationsList();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_VIOLATIONS_LIST, result.items);
      commit(END, 'fetchViolationsList');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchInnTypeList({ commit }) {
      commit(START, 'fetchInnTypeList');
      const { result } = await audit.innTypeList();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_INN_TYPE_LIST, result.items);
      commit(END, 'fetchInnTypeList');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchChecksList({ commit }) {
      commit(START, 'fetchChecksList');
      const { result } = await audit.checksList();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_CHECKS_LIST, result.items);
      commit(END, 'fetchChecksList');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchRegions({ commit }) {
      commit(START, 'fetchRegions');
      const { result } = await task.regions();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_REGIONS, result.items);
      commit(END, 'fetchRegions');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchDistributors({ commit }) {
      commit(START, 'fetchDistributors');
      const { result } = await audit.distributors();
      const success = !get(result, 'error');
      if (success) commit(UPDATE_DISTRIBUTORS, result.items);
      commit(END, 'fetchDistributors');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchExel({ commit }, { page, size, sort, filter }) {
      commit(START, 'fetchExel');
      const { result } = await audit.excel(page, size, sort, filter);
      const success = !get(result, 'error');
      if (success) {
        const url = get(result, 'file.path');
        const name = get(result, 'file.name', 'list');
        if (url) {
          const a = document.createElement('a');
          a.href = url;
          a.download = name;
          a.click();
          a.remove();
        }
      }
      commit(END, 'fetchExel');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async update({ commit }, {id, name, status, check, violations, inn, preview, comment, person, files, clients, distributor, distributorName}) {
      commit(START, 'update');
      const { result } = await audit.update({id, name, status, check, violations, inn, preview, comment, person, files, clients, distributor, distributorName});
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_CURRENT, result);
      }
      commit(END, 'update');

      if (!success) throw {
        error: 'Ошибка при попытке сохранить данные заявки',
        error_description: get(result, 'message'),
      }

      return get(result, 'id', null);
    },
  },
}
