<template>
    <div :class="$style.root">
        <v-row dense>
            <v-col :cols="12">
                <div :class="$style.label">{{title}}</div>
                <div :class="$style.value">{{ text(value)}}</div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

    export default {
        name: 'UserViewRow',
        props: {
            title: {type: String},
            value: {type: Array, default: () => []},
            items: {type: Array}
        },
        methods: {
            text(value) {
                return value || 'Не указано';
            }
        }
    }
    
</script>

<style module lang="scss">
    .root {
        margin-bottom: 15px;
    }

    .label {
        font-size: 12px;
        color: rgba(0, 0, 0, .6);

        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }
</style>
