<template>
  <v-form v-model="valid" ref="form" @submit.prevent="onSubmit({ login })">
    <v-text-field
        class="mb-6"
        label="Email"
        outlined
        hide-details="auto"
        v-model="login"
        :rules="[rules.required, rules.email]"
    />
    <v-alert v-if="error" dense outlined type="error">
      <div v-html="error.error_description" />
    </v-alert>
    <div>
      <v-btn
          v-text="'Отправить'"
          block
          depressed
          color="primary"
          type="submit"
      />
      <v-btn
          v-text="'Отмена'"
          block
          depressed
          class="mt-3"
          @click="onReset"
      />
    </div>
  </v-form>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'UserRecoveryForm',
  data: () => ({
    valid: true,
    error: null,
    login: '',
    rules: {
      required: v => !!v || 'Обязательное поле',
      email: v => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Некорректный адрес электронной почты'
    },
  }),
  methods: {
    ...mapActions({
      sendPassword: 'user/sendPassword',
    }),
    reset() {
      this.login = '';
    },
    onSubmit({ login }) {
      this.$refs.form.validate();
      if (this.valid) {
        this.error = null;
        this.sendPassword({ login })
        .then(() => this.$emit('submit'))
        .catch((error) => (this.error = error));
      }
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    }
  }
}
</script>
