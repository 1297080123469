<template>
  <v-dialog persistent max-width="1200" :value="true" @click:outside="close" @keydown.esc="close">
    <well title="Инструкция для пользователей">
      <template v-slot:title="{ title }">
        {{title}}
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <div :class="$style.root" v-html="html" ref="root"  />
    </well>
  </v-dialog>
</template>

<script>
import instructions from '!!raw-loader!./instructions.html';
import Well from '@/components/Well/Well';
export default {
  name: 'InstructionsDialog',
  components: {
    Well,
  },
  data() {
    return {
      html: instructions,
    }
  },
  computed: {},
  methods: {
    close() {
      this.$router.push({ hash: null });
    }
  },
  mounted() {
    document.addEventListener('click', event => {
      if (this.$refs.root.contains(event.target)) {
        event.preventDefault();
        this.$refs.root.querySelector(event.target.hash).scrollIntoView();
      }
    });
  }
}
</script>
<style module lang="scss">
.root{
  pointer-events: none;
  user-select: none;
  a {
    pointer-events: auto;
  }
  img {
    max-width: 100%;
  }
  h2 {
    margin-bottom: 20px;
    margin-top: 40px;
  }
  ul {
    margin-bottom: 30px;
    li {
      margin-bottom: 5px;
    }
    ul {
      margin-top: 5px;
      margin-bottom: 15px;
    }
  }
}
</style>
