<template>
    <task-view-row v-if="disabled" :title="title" :value="value" :items="items"/>
    <div :class="$style.root" v-else class="mb-5">
        <v-row dense>
            <v-col cols="4">
                <v-select
                    :items="protocolList"
                    hide-details
                    dense
                    @input="onChangeProtocol"
                    v-model="protocol"
                    outlined
                />
            </v-col>
            <v-col cols="8">
                <v-text-field
                    :value="inputValue"
                    :label="title"
                    :disabled="disabled"
                    type="text"
                    outlined
                    dense
                    hide-details="auto"
                    @input="(value) => onInput(protocol+value, 0)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { set } from 'lodash';
import TaskViewRow from '@/components/TaskViewRow/TaskViewRow';

export default {
    name: 'PromotionsFormRowSite',
    components: {
        TaskViewRow
    },
    props: {
        title: {type: String},
        value: {type: Array, default: () => []},
        rules: {type: Array, default: () => []},
        items: {type: Array},
        disabled: {type: Boolean},
        component: {type: String},
    },
    data() {
        return {
            protocolList: ['https://', 'http://'],
            protocol: 'https://',
        }
    },
    computed: {
        inputValue() {
            const val = this.value ? this.value[0] : '';
            return val.replace(/https?:\/\//, '');
        },
    },
    methods: {
        onInput(value, index) {
            const next = JSON.parse(JSON.stringify(this.value));
            set(next, index, value);
            return this.$emit('input', next);
        },
        onChangeProtocol(value) {
            return this.$emit('input', [value + this.inputValue]);
        },
        getProtocol() {
            const val = this.value ? this.value[0] : '';
            if (val && val.indexOf('http://') > -1) {
                return 'http://';
            } else {
                return 'https://';
            }
        },
    },
    beforeMount() {
        this.protocol = this.getProtocol();
    },
}
</script>

<style module lang="scss">
.root {
    textarea {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 100%;
        resize: none;
    }

    :global {
        .v-input--checkbox {
            margin-top: 0;
            padding-top: 0;
        }
    }

    .label {
        font-size: 12px;
        color: rgba(0, 0, 0, .6);

        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }
}
</style>
