<template>
  <v-data-table
      dense
      hide-default-header
      hide-default-footer
      :class="$style.root"
      :items="value.map((item) => ({'value': item && item.code === 'Y'}))"
      :item-class="() => $style.tr"
      :headers="[
        {value: 'name', text: 'Название'},
        {value: 'value', text: 'Значение'},
      ]"
  >
    <template v-slot:item.name="{item}">
      Пользователь {{item.value ? '' : 'не '}}зарегистрирован
    </template>
    <template v-slot:item.value="{ item }">
      <v-icon v-if="item.value" color="success" v-text="'mdi-check-bold'" />
      <v-icon v-else color="error" v-text="'mdi-close-thick'" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'ClientBP',
  props: {
    value: {
      type: Array,
    },
  },
}
</script>

<style module lang="scss">
.root {
  .tr {
    background: none !important;
    td {
      font-size: 14px !important;
      width: 50%;
    }
  }
}
</style>
