<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <h1 :class="$style.title">Восстановление пароля</h1>
      <user-password-change-form
          v-if="!error"
          :login="login"
          :word="word"
          @submit="$router.replace({ name: 'UserSignInPage' })"
          @reset="$router.replace({ name: 'UserSignInPage' })"
      />
      <template v-else>
        <v-alert dense outlined type="error">
          Похоже ваша ссылка устарела.
        </v-alert>
        <v-btn
            v-text="'Сгенерировать новую'"
            :to="{ name: 'UserRecoveryPage' }"
            block
            depressed
            color="primary"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import UserPasswordChangeForm from '@/components/UserPasswordChangeForm/UserPasswordChangeForm';

export default {
  name: 'UserSignInPage',
  components: {
    UserPasswordChangeForm,
  },
  computed: {
    login(){
      return get(this.$route, 'params.login', get(this.$route, 'query.login'));
    },
    word() {
      return get(this.$route, 'params.word', get(this.$route, 'query.word'));
    },
    error() {
      return !this.login || !this.word;
    }
  },
}
</script>

<style module lang="scss">
.root {
  display: flex;
  min-height: 90vh;
  .inner {
    margin: auto;
    max-width: 100%;
    width: 440px;
  }
  .title {
    margin-bottom: 30px;
    font-weight: normal;
    text-align: center;
  }
}
</style>
