<template>
  <div :class="$style.root">
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="_.filter([
          {text: 'Название', value: 'name'},
          {text: 'ИНН', value: 'inn'},
          {text: 'Статус', value: 'status.name'},
          {text: 'bp.iek.ru', value: 'bpConnected.name'},
          {text: 'Классификация', value: 'classification.name'},
          {text: 'Дистрибуторы', value: 'distributors'},
        ])"
        :mobile-breakpoint="0"
        :height="height"
        :items="items"
        :item-class="({ inn }) => [$style.row, {
            [$style.active]: inn === active,
        }]"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500],
          itemsPerPageText: '',
        }"
    >
      <template v-slot:item.distributors="{ item }">
        <div :class="$style.distributors">
          {{ _.join(_.map(item.distributors, 'name'), ', ') }}
        </div>
      </template>
      <template v-slot:body.prepend>
        <regional-client-list-filter v-model="filter" />
      </template>
      <template v-slot:item.bpConnected.name="{ item }">
        <div :class="$style.distributors">
          <v-icon v-if="item.bpConnected.code === 'Y'" color="success" v-text="'mdi-check-bold'" />
          <v-icon v-else color="error" v-text="'mdi-close-thick'" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {get, pick, merge} from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import RegionalClientListFilter from '@/components/RegionalClientListFilter/RegionalClientListFilter';

export default {
  name: 'RegionalClientList',
  components: {
    RegionalClientListFilter,
  },
  data() {
    const filter = {};
    const options = pick(JSON.parse(localStorage.getItem('RegionalClientList.options')), [ 'sortBy', 'sortDesc', 'page', 'itemsPerPage' ]);
    return {
      height: window.innerHeight - 90,
      filter: merge({}, filter),
      options: merge({
        sortBy: ['name'],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 20,
      }, options),
    }
  },
  computed: {
    ...mapGetters({
      pending: 'clients/pending',
      items: 'clients/items',
      count: 'clients/count',
    }),
    active() {
      return this.$route.params.inn;
    },
    sort() {
      const field = get(this.options, ['sortBy', 0], 'name');
      const direction = ['asc', 'desc'][+get(this.options, ['sortDesc', 0], false)];
      const alias = {
        'status.name': 'status',
        'classification.name': 'classification',
        'bpConnected.name': 'bpConnected',
      };
      return {
        field: alias[field] || field,
        direction,
      };
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'clients/fetchItems',
    }),
    update() {
      this.fetchItems({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      });
    },
    updateLocalStorage() {
      localStorage.setItem('RegionalClientList.options', JSON.stringify(this.options));
    },
    resize() {
      this.height = window.innerHeight - 90;
    },
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    filter: {
      handler() {
        this.update();
      },
      deep: true,
    },
    options: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-data-footer{
      justify-content: flex-start;
      padding-left: 16px;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
  .distributors {
    white-space: nowrap;
  }
  .row {
    cursor: default;
    td {
      width: 1px;
    }
  }
  .active{
    background: #eeeeee;
  }
}
</style>
