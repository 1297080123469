import { toString } from 'lodash';
import { Api } from '@/api';
export class CisLink extends Api {
  all(inn){
    return this.request('cislink.sellOut', {
      params: {
        inn: toString(inn),
      },
    });
  }
  quarters(inn){
    return this.request('cislink.sellOutByQuarter', {
      params: {
        inn: toString(inn),
      },
    });
  }
}
export default new CisLink();
