<template>
    <tr :class="$style.root">
        <td>
            <div :class="$style.control">
                <date-picker
                        v-model="dateRegister"
                        @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                        v-model="name"
                        clearable
                        placeholder="Все"
                        hide-details
                        dense
                        @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                      v-model="email"
                      clearable
                      placeholder="Все"
                      hide-details
                      dense
                      @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                      v-model="personalPhone"
                      clearable
                      placeholder="Все"
                      hide-details
                      dense
                      @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-select
                      v-model="active"
                      :items="activeList"
                      clearable
                      placeholder="Все"
                      hide-details
                      dense
                      @input="onChange"
                />
            </div>
        </td>
    </tr>
</template>

<script>
    import {get, debounce, pickBy} from 'lodash';

    import DatePicker from '@/components/DatePickerRange/DatePickerRange';
    import {bitrixBooleanList} from "../../store/utils";

    export default {
        name: 'UserListFilter',
        components: {
            DatePicker,
        },
        props: {
            value: {type: Object},
        },
        data() {
            return {
                dateRegister: get(this.value, 'dateRegister', ''),
                name: get(this.value, 'name', ''),
                email: get(this.value, 'email', ''),
                personalPhone: get(this.value, 'personalPhone', ''),
                active: get(this.value, 'active', ''),
            }
        },
        computed: {
            activeList() {
                return bitrixBooleanList;
            }
        },
        methods: {
            onChange: debounce(function () {
                this.$emit('input', pickBy({
                    dateRegister: this.dateRegister,
                    name: this.name,
                    email: this.email,
                    personalPhone: this.personalPhone,
                    active: this.active,
                }, Boolean));
            }, 600),
        },
    }
</script>

<style module lang="scss">
    .root {
        background: none !important;

        .control {
            width: 150px;
        }
    }
</style>
