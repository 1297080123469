import {includes, set, get, map, toNumber, pick, values} from 'lodash';
import cislink from './api';

const START = 'START';
const END = 'END';
const UPDATE_ALL = 'UPDATE_ALL';
const UPDATE_QUARTERS = 'UPDATE_QUARTERS';

export default {
  namespaced: true,
  state: {
    pending: {
      fetchSellOut: false,
      fetchSellOutByQuarter: false,
    },
    all: [],
    quarters: [],
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    all(state) {
      return map(state.all, item => ({
        code: get(item, 'code', ''),
        name: get(item, 'name', ''),
        amount: toNumber(get(item, 'amount', 0)),
      }))
    },
    quarters(state) {
      return map(state.quarters, (item) => ({
        [item.inn]: values(pick(item, ['1q', '2q', '3q', '4q']))
      }))
    },
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [UPDATE_ALL](state, items) {
      set(state, 'all', items);
    },
    [UPDATE_QUARTERS](state, items) {
      set(state, 'quarters', items);
    }
  },
  actions: {
    async fetchSellOut({ commit }, inn) {
      commit(START, 'fetchSellOut');
      const {result} = await cislink.all(inn);
      const success = !get(result, 'error');
      if (success) commit(UPDATE_ALL, result.items);
      commit(END, 'fetchSellOut');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async fetchSellOutByQuarter({ commit }, inn) {
      commit(START, 'fetchSellOutByQuarter');
      const {result} = await cislink.quarters(inn);
      const success = !get(result, 'error');
      if (success) commit(UPDATE_QUARTERS, result.items);
      commit(END, 'fetchSellOutByQuarter');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    }
  },
}
