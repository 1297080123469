var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('v-data-table',{attrs:{"fixed-header":"","must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":_vm._.filter([
        {text: 'Раздел выгрузки', value: 'name'},
        {text: 'Дата формирования', value: 'dateStart'},
        {text: 'Статус', value: 'status.name'},
        {text: 'Срок действия ссылки', value: 'dateAvailable'} ]),"mobile-breakpoint":0,"height":_vm.height,"items":_vm.items,"item-class":function (ref) {
            var _obj;

            var id = ref.id;
            return [_vm.$style.row, ( _obj = {}, _obj[_vm.$style.active] = id === _vm.active, _obj )];
},"server-items-length":_vm.count,"options":_vm.options,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 100, 500],
        itemsPerPageText: '',
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status.name",fn:function(ref){
      var item = ref.item;
return [(item.status.code==='formed')?_c('div',[_c('a',{class:_vm.$style.downloadLink,attrs:{"href":item.link,"target":"_blank"}},[_vm._v("Скачать")])]):(item.status.code==='formation')?_c('div',[_vm._v(" "+_vm._s(item.status.name)+" "+_vm._s(item.readyPercent)+"% ")]):_c('div',[_vm._v(" "+_vm._s(item.status.name)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }