var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('v-data-table',{attrs:{"fixed-header":"","must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":[
        {text: 'ИНН', value: 'inn'},
        {text: 'Название', value: 'name'},
        {text: 'Адрес', value: 'address'},
        {text: 'Телефон', value: 'phone'},
        {text: 'ОКВЭД', value: 'okved'} ],"itemsPerPage":20,"mobile-breakpoint":0,"height":_vm.height,"items":_vm.items,"item-class":function () { return _vm.$style.row; },"footer-props":{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500, -1],
        }}}),(_vm.pending)?_c('div',{class:_vm.$style.loader}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }