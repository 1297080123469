<template>
  <div :class="$style.root">
    <well title="Проверка ИНН">
      <v-form ref="form" v-model="valid" :class="$style.root">
        <v-alert v-if="error" class="mb-6" dense outlined type="error">
          <div v-html="error.error_description" />
        </v-alert>
        <task-form-row
            title="ИНН"
            v-model="fields.inn"
            :component="'inn-field'"
            :check="['required', 'inn', 'not']"
            @update="onUpdate"
            class="mt-2"
        />
        <task-form-row
            title="Клиент"
            v-model="fields.name"
            :rules="[rules.required]"
            disabled="true"
        />
        <task-form-row
            title="Адрес"
            v-model="fields.address"
            :rules="[rules.required]"
            disabled="true"
        />
        <task-form-row
            title="Телефон"
            v-model="fields.phone"
            disabled="true"
        />
        <task-form-row
            title="Сайт"
            v-model="fields.site"
            disabled="true"
        />
        <task-form-row
            title="Классификация"
            v-model="fields.classification"
            :rules="[rules.required]"
            :items="classifications"
            disabled="true"
        />
        <task-form-row
            title="ОКВЭД"
            v-model="fields.okved"
            disabled="true"
        />
        <task-form-row
            title="Статус"
            v-model="fields.statusClient"
            :rules="[rules.required]"
            :items="statusesByClassification"
            disabled="true"
        />
        <panel-difference title="Дистрибьюторы" :items="fields.distributors">
          <template v-slot:default="{ items }">
            <client-distributor-table :value="items" />
          </template>
        </panel-difference>
      </v-form>
    </well>
  </div>
</template>

<script>
import { get, head, toNumber, map, filter, includes, last, compact } from 'lodash';
import client from '@/store/clients/api';
import { enumerationAdapter } from '@/store/utils';
import { clientItemAdapter } from '@/store/clients/utils';
import { taskEmptyAdapter } from '@/store/tasks/utils';

import TaskForm from '@/mixins/TaskForm/TaskForm';
import TaskFormRow from '@/components/TaskFormRow/TaskFormRow';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import ClientDistributorTable from '@/components/ClientDistributorTable/ClientDistributorTable';
import Well from '@/components/Well/Well';

export default {
  name: 'InnCheckForm',
  mixins: [ TaskForm ],
  components: {
    TaskFormRow,
    PanelDifference,
    ClientDistributorTable,
    Well
  },
  data() {
    return {
      statusesByClassification: this.statuses,
      correct: false,
      inner: false,
      related: false,
    };
  },
  methods: {
    onUpdate(items) {
      const item = head(items) || {};
      const { name, phone, address, okved } = item;
      const code = item.code || this.values.inn;
      this.correct = !!get(item, 'code', false);
      this.inner = get(item, 'inner', false);
      this.related = get(item, 'related', false);
      if (this.isCreate) {
        this.reset();
        this.$refs.form.validate();

        code && this.$set(this.fields.inn, 0, code);
        name && this.$set(this.fields.name, 0, name);
        phone && this.$set(this.fields.phone, 0, phone);
        address && this.$set(this.fields.address, 0, address);
        okved && this.$set(this.fields.okved, 0, okved);

        if (this.inner && code) {
          client.item(code).then(result => {
            const client = taskEmptyAdapter(clientItemAdapter(result.result));
            if (client.fields) {
              this.fields = client.fields;
            }
          })
        }

        this.$snack({
          text: `Клиент ${['не', ''][toNumber(this.inner)]} найден в базе iPrice`,
          pos: 'top-center',
          actionText: 'Понятно',
        });
      }
    },
    onReset(){
      this.reset();
      this.$emit('reset');
    },
    onUpdateStatusesByClassification() {
      const statuses = this.statuses;
      const classification = this.values.classification;
      this.statusesByClassification = statuses;
      classification && client.statuses(classification).then(result => {
        const currents = this.fields.statusClient;
        const pick = map(map(get(result, 'result.items'), enumerationAdapter), 'code');
        const items = map(statuses, (item) => ({ ...item, disabled: !item.approval }));
        this.statusesByClassification = filter(items, ({ code, disabled }) => {
          return (!disabled && includes(pick, code)) || disabled && includes(compact(currents), code);
        });
      });
    }
  },
  watch: {
    'statuses': {
      handler() {
        // Произойдет только если статусы не успели загрузиться на момент рендера
        this.onUpdateStatusesByClassification();
        this.onReset();
      },
    },
    'values.classification': {
      immediate: true,
      handler() {
        this.onUpdateStatusesByClassification();
      },
    },
    'statusesByClassification': {
      handler(statuses) {
        if (!includes(map(statuses, 'code'), last(this.fields.statusClient))) {
          this.fields.statusClient.splice(-1, 1,'');
        }
      }
    }
  }
}
</script>

<style module lang="scss">
  .root {}
</style>
