<template>
<div :class="$style.root">
  <realization-table :inn="inn">
    <div class="my-3">
      <realization-form v-if="isEditing" :inn="inn" @submit="onSave" :type="realizationType" />
      <v-btn v-else dense depressed @click="onEditing" v-show="!isAdminView" v-text="'Изменить'" />
    </div>
  </realization-table>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import RealizationTable from '@/components/RealizationTable/RealizationTable';
import RealizationForm from '@/components/RealizationForm/RealizationForm';
export default {
  name: 'RealizationAttachTable',
  components: {
    RealizationTable,
    RealizationForm,
  },
  props: {
    inn: { type: String, required: true },
    required: { type: Boolean },
    realizationType: { type: String }
  },
  data() {
    return {
      editing: false
    };
  },
  computed: {
    ...mapGetters({
      access: 'access',
      realizations: 'realizations/count'
    }),
    isEditing() {
      return this.editing || (this.required && !this.realizations);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
  },
  methods: {
    onEditing(){
      this.editing = true;
    },
    onSave(){
      this.editing = false;
    },
    onUpdate() {
      this.editing = false;
    },
  },
  watch: {
    'inn': {
      immediate: true,
      handler() {
        this.onUpdate();
      }
    }
  },
}
</script>

<style module lang="scss">
.root {}
.loader {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
