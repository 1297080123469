<template>
    <div :class="$style.root">
        <v-menu v-model="show" absolute offset-y>
            <template v-slot:activator="{ on }">
                <v-row dense v-on="on">
                    <v-col :cols="12" v-for="(val, index) in value" :key="index">
                        <div :class="$style.label">{{ title }}</div>
                        <div :class="$style.value">{{ val }}</div>
                    </v-col>
                </v-row>
            </template>

            <div :class="$style.menu">
                <advance-history-table :inn="inn"/>
            </div>
        </v-menu>
    </div>

</template>

<script>
    import AdvanceHistoryTable from '@/components/AdvanceHistoryTable/AdvanceHistoryTable';

    export default {
        name: 'AdvanceViewRow',
        components: {
            AdvanceHistoryTable
        },
        data() {
            return {
                show: false,
            };
        },
        props: {
            inn: {type: String, required: true},
            title: {type: String},
            value: {type: Array, default: () => []},
        },
    }
</script>

<style module lang="scss">
    .root {
        margin-bottom: 15px;
        cursor: pointer;

        & .menu {
            width: 700px;
            max-width: 100%;
            max-height: 50vh;
            overflow: auto;
        }
    }

    .label {
        font-size: 12px;
        color: rgba(0, 0, 0, .6);

        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }

    .root:hover .value {
        text-decoration: underline;
    }
</style>
