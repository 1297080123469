import {toString, forEach, pickBy} from 'lodash';
import {Api} from '@/api';

export class Employee extends Api {
    item(id) {
        return this.request('employee.info', {
            params: {
                id: toString(id),
            }
        });
    }

    /**
     * Получение списка доступных сотрудников
     * @param page
     * @param size
     * @param sort
     * @param filter
     * @returns {Promise<*>}
     */
    list(page = 1, size = -1, sort = {}, filter = {}) {
        const params = {
            page: toString(page),
            size: toString(size),
        };
        forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
        forEach(filter, (value, key) => params[`filter[${key}]`] = value);
        return this.request('employee.list', {
            params,
        });
    }

    update({id, active}) {
        const data = pickBy({
            id: toString(id),
            active: toString(active),
        }, Boolean);

        const url = 'employee.update';
        return this.request(url, {
            method: 'POST',
            data: this.toFormData(data),
        });
    }

    create(fields) {
        const data = pickBy({
            activeCode: toString(fields.activeCode),
            email: toString(fields.email),
            name: toString(fields.name),
            password: toString(fields.password),
            personalPhone: toString(fields.personalPhone),
            position: toString(fields.position),
            positionType: toString(fields.positionType),
        }, Boolean);

        const url = 'employee.create';
        return this.request(url, {
          method: 'POST',
          data: this.toFormData(data),
        });
    }

    positionTypes() {
        return this.request('employee.listPositionTypes');
    }
}

export default new Employee();
