import { get, map, toString, head, values, uniq, uniqBy, join, mapValues, last, merge, isArray } from 'lodash';
import { clientAdapter, distributorAdapter, fileAdapter } from '@/store/utils';
import { clientItemAdapter } from '@/store/clients/utils';
import STATUSES from '@/api/enums/promotion';
import TYPES from '@/api/enums/types';

/**
 * Заглушка пустой заявки на основе адаптера клиента "client.info"
 * @param client
 */
export const promotionEmptyAdapter = (client) => {
  const id = get(client, 'id');
  return {
    inn: get(client, 'inn'),
    status: {
      code: id ? STATUSES.EDITING : STATUSES.CREATURE,
    },
    skuFile: [],
    fields: {
      name: [''],
      clientName: [get(client, 'name')],
      clientInn: [get(client, 'inn', '')],
      clientStatus: [get(client, 'status.code', '')],
      district: [get(client, 'district.code', '')],
      classification: [get(client, 'classification.code', '')],
      conditions: [get(client, 'conditions', '')],
      client: [get(client, 'client', [])],
      distributorName: [get(client, 'distributorName', '')],
      discount: [get(client, 'discount', '')],
      activeFrom: [get(client, 'activeFrom', '')],
      activeTo: [get(client, 'activeTo', '')],
      comment: [''],
      site: [get(client, 'site', '')],
      salePoint: [get(client, 'salePoint', '')],
    },
  }
};

/**
 * Поля для формы редактирования на основе клиента "promotions.info"
 * @param item клиент
 * @param create признак создания
 * @returns {{site: unknown, address: unknown, clients: unknown, distributors: unknown, phone: unknown, name: unknown, inn: unknown, check: unknown, sales: unknown, statusClient: unknown}}
 */
export const promotionFieldsAdapter = (item, create = false) => merge(mapValues({
  name: [get(item, 'name')],
  clientName: [get(item, 'displayProperties.clientName.displayValue')],
  clientInn: [get(item, 'displayProperties.clientInn.displayValue')],
  clientStatus: [get(item, 'displayProperties.clientStatus.displayValue')],
  district: [get(item, 'displayProperties.district.displayValue')],
  classification: [get(item, 'displayProperties.classification.displayValue')],
  status: uniq([get(item, 'displayProperties.status.displayValue')]),
  statusCode: get(item, 'displayProperties.status.valueXmlId'),
  conditions: [get(item, 'displayProperties.conditions.valueXmlId')],
  discount: [get(item, 'displayProperties.discount.value')],
  distributorName: [get(item, 'displayProperties.distributorName.displayValue')],
  activeFrom: [get(item, 'displayProperties.activeFrom.displayValue', '')],
  activeTo: [get(item, 'displayProperties.activeTo.displayValue', '')],
  site: [get(item, 'displayProperties.site.value', '')],
  salePoint: [get(item, 'displayProperties.salePoint.value', '')],

}, (value) => create ? [last(value)] : value), {
  distributors: uniqBy([
    map(values(get(item, 'displayProperties.distributors.linkElementValue')), distributorAdapter),
    map(values(get(item, 'displayProperties.distributors.nextLinkElementValue')), distributorAdapter),
  ], value => join(map(value, 'id'))),
  clients: uniqBy([
    map(values(get(item, 'displayProperties.client.linkElementValue')), clientAdapter),
  ], value => join(map(value, 'id'))),
});

/**
 * Выборка полей заявки из структуры arResult
 * @param item
 * @returns {{date: string, comments: unknown[], inn: string, skuFile: unknown[], client: {site: string, address: string, clients: minimist.Opts.unknown[], phone: string, name: string, inn: string, id: string, check: {code: string, name: string}, status: {code: string, name: string}}, id: string, check: {code: string, name: string}, type: {code: string, name: string}, fields: {site: minimist.Opts.unknown, address: minimist.Opts.unknown, clients: minimist.Opts.unknown, distributors: minimist.Opts.unknown, phone: minimist.Opts.unknown, name: minimist.Opts.unknown, inn: minimist.Opts.unknown, check: minimist.Opts.unknown, sales: minimist.Opts.unknown, statusClient: minimist.Opts.unknown}, user: string, distributor: (*), status: {code: string, name: string}}}
 */
export const promotionItemAdapter = (item) => {
  const client = head(values((get(item, 'displayProperties.client.linkElementValue'))));
  const create = toString(get(item, 'displayProperties.type.value')) === TYPES.CREATE;
  const skuFile = get(item, 'displayProperties.skuFile.fileValue', []);

  return {
    id: toString(get(item, 'id')),
    name: toString(get(item, 'name')),
    date: toString(get(item, 'dateCreate')),
    clientInn: toString(get(item, 'displayProperties.clientInn.displayValue')),
    clientName: toString(get(item, 'displayProperties.clientName.displayValue')),
    clientStatus: {
      code: toString(get(item, 'displayProperties.clientStatus.value')),
      name: toString(get(item, 'displayProperties.clientStatus.displayValue')),
    },
    conditions: {
      code: toString(get(item, 'displayProperties.conditions.valueXmlId')),
      name: toString(get(item, 'displayProperties.conditions.valueEnum')),
    },
    classification: {
      code: toString(get(item, 'displayProperties.classification.value')),
      name: toString(get(item, 'displayProperties.classification.displayValue')),
    },
    user: toString(get(item, 'createdByName')),
    status: {
      code: toString(get(item, 'displayProperties.status.valueXmlId')),
      name: toString(get(item, 'displayProperties.status.valueEnum')),
    },
    district: {
      code: toString(get(item, 'displayProperties.district.value')),
      name: toString(get(item, 'displayProperties.district.displayValue')),
    },
    distributor: head(map(get(item, 'displayProperties.distributor.linkElementValue'), distributorAdapter)),
    distributorName: toString(get(item, 'displayProperties.distributorName.displayValue')),
    site: toString(get(item, 'displayProperties.site.value')),
    salePoint: toString(get(item, 'displayProperties.salePoint.value')),
    skuFile: map(isArray(skuFile) ? skuFile : [skuFile], fileAdapter),
    canEdit: get(item, 'canEdit', false),
    client: clientItemAdapter(client),
    fields: promotionFieldsAdapter(item, create),
    comments: get(item, 'comments'),
  };
};
