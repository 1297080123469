<template>
  <div :class="$style.root">
    <audit-result-list :reload="reload"/>
    <v-navigation-drawer :value="!!id" app stateless width="430" right mobile-breakpoint="0">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="['Заявка №' + id, 'Новая заявка'][+isCreate]" />
          </v-list-item-content>
          <v-list-item-action>
            <v-row dense align="center">
              <v-col>
                <v-btn v-if="!editingView && userParams && userParams.edit && userParams.edit.audit_result" icon @click="onEditClick">
                  <v-icon color="grey lighten-1" v-text="['mdi-pen', 'mdi-close'][+editingView]" />
                </v-btn>
              </v-col>
              <v-col>
                <v-btn icon @click="onClose">
                  <v-icon color="grey lighten-1" v-text="'mdi-close'" />
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </template>
      <div v-if="id" :class="$style.drawer">
        <audit-result-detail :id="id" :editingView.sync="editingView"/>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuditResultList from '@/components/AuditResultList/AuditResultList';
import AuditResultDetail from '@/components/AuditResultDetail/AuditResultDetail';

export default {
  name: 'AuditResultListPage',
  components: {
    AuditResultList,
    AuditResultDetail,
  },
  props: {
    id: { type: String },
    reload: { type: Boolean },
    view: { type: String },
    editingView: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      access: 'access',
      distributor: 'user/distributor',
      task: 'audit/current',
      userParams: 'user/userParams',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    isCreate() {
      return this.id === 'creation';
    }
  },
  methods: {
    onCreate() {
      this.$router.push({name: 'AuditResultListPage', params: { id: 'creation' }});
    },
    onClose() {
      this.$router.push({ name: 'AuditResultListPage' });
    },
    onEditClick() {
      this.editingView = true;
    }
  },
  watch: {
    userParams: {
      handler() {
        const auditorGroups=['auditor','auditor_rm','auditor_rfo'];
        if(this.userParams && this.userParams.navigation && !this.userParams.navigation[this.$options.name] && this.userParams.defaultPage) this.$router.push({ name: this.userParams.defaultPage});
        if(!this.userParams || !this.userParams.groups || !this.userParams.groups.filter(x => auditorGroups.includes(x)).length) this.$router.push({ name: this.userParams.defaultPage});
      },
      deep: true,
    }
  }
}
</script>

<style module lang="scss">
.root{
  .drawer{
    padding: 6px 16px 16px;
  }
}
</style>
