<template>
  <v-dialog persistent max-width="1300" :value="true" @click:outside="close" @keydown.esc="close">
    <well title="Аффилированные">
      <template v-slot:title="{ title }">
        {{title}}
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <AffiliationTable :inn="$route.params.inn || task.inn" />
    </well>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Well from '@/components/Well/Well';
import AffiliationTable from '@/components/AffiliationTable/AffiliationTable';

export default {
  name: 'AffiliationDialog',
  components: {
    Well,
    AffiliationTable,
  },
  computed: {
    ...mapGetters({
      task: 'tasks/current',
    }),
  },
  methods: {
    close() {
      this.$router.push({ hash: null });
    }
  },
}
</script>
