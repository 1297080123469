<template>
  <div :class="$style.root">
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="_.filter([
          {text: 'Дата создания', value: 'date'},
          {text: 'Автор заявки', value: 'user'},
          {text: '№ заявки', value: 'id'},
          {text: 'Название акции', value: 'name'},
          {text: 'Округ', value: 'district.name', sortable: false},
          {text: 'ЦКГ/Классификация', value: 'classification.name'},
          {text: 'ИНН Клиента', value: 'clientInn'},
          {text: 'Название клиента', value: 'clientName'},
          {text: 'Статус клиента', value: 'clientStatus.name'},
          {text: 'Условия акции', value: 'conditions.name'},
          {text: 'Статус заявки', value: 'status.name'},
          {text: 'Дистрибутор', value: 'distributorName'},
        ])"
        :mobile-breakpoint="0"
        :height="height"
        :items="items"
        :item-class="({ id }) => [$style.row, {
            [$style.active]: id === active,
        }]"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500],
          itemsPerPageText: '',
        }"
        @click:row="onClickRow"
    >
      <template v-slot:body.prepend>
        <promotions-list-filter v-model="filter"/>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get, pick, merge } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import PromotionsListFilter from "@/components/PromotionsListFilter/PromotionsListFilter.vue";

export default {
  name: 'PromotionsList',
  components: {
    PromotionsListFilter,
  },
  data() {
    const filter = {};
    const options = pick(JSON.parse(localStorage.getItem('PromotionsList.options')), ['sortBy', 'sortDesc', 'page', 'itemsPerPage']);
    return {
      height: window.innerHeight - 90,
      filter: merge({}, filter),
      options: merge({
        sortBy: ['date'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 20,
      }, options),
    }
  },
  props: {
    reload: {type: Boolean}
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'promotions/pending',
      items: 'promotions/items',
      count: 'promotions/count'
    }),
    active() {
      return this.$route.params.id;
    },
    sort() {
      const field = get(this.options, ['sortBy', 0], 'name');
      const direction = ['asc', 'desc'][+get(this.options, ['sortDesc', 0], false)];
      const alias = {
        'status.name': 'status',
        'clientName': 'client',
        'distributorName': 'distributorName',
        'conditions.name': 'conditions',
        'district.name': 'district',
      };
      return {
        field: alias[field] || field,
        direction,
      };
    },
    filesDisplay: function () {
      return 'файлы';
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'promotions/fetchItems',
    }),
    update() {
      this.fetchItems({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      });
    },
    updateLocalStorage() {
      localStorage.setItem('PromotionsList.options', JSON.stringify(this.options));
    },
    resize() {
      this.height = window.innerHeight - 90;
    },
    onClickRow({id}) {
      this.$router.push({name: 'PromotionsListPage', params: {id}});
    }
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, {passive: true});
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    filter: {
      handler() {
        this.update();
      },
      deep: true,
    },
    options: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
    reload: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-data-footer {
      justify-content: flex-start;
      padding-left: 16px;

      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }

      .v-data-footer__pagination {
        min-width: 70px;
      }

      .v-select {
        margin-left: 0 !important;
      }
    }
  }

  .row {
    cursor: pointer;

    td {
      width: 1px;
    }
  }

  .active {
    background: #eeeeee;
  }
}
</style>
