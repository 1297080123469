<template>
<div :class="$style.root">
  <audit-status-switch v-if="hasControls" :value="status" @input="onSubmit" class="mb-6" />
  <audit-view-row v-if="!hasInn" title="Дистрибутор" :value="fields.distributor" />
  <panel-difference v-if="!hasInn" title="Клиенты" :items="fields.clients" :diff="diff">
    <template v-slot:default="{ items }">
      <audit-client-table :value="items" />
    </template>
  </panel-difference>
  <audit-view-row v-if="hasInn" title="Не зависимый ИНН" :value="fields.inn" />
  <audit-view-row v-if="hasInn && innName" title="Клиент" :value="innName" />
  <audit-view-row title="Статус" :value="fields.status" />
</div>
</template>

<script>
import { get, includes } from 'lodash';
import AuditForm from '@/mixins/AuditForm/AuditForm';

import AuditViewRow from '@/components/AuditViewRow/AuditViewRow';
import AuditClientTable from '@/components/AuditClientTable/AuditClientTable';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import AuditStatusSwitch from '@/components/AuditStatusSwitch/AuditStatusSwitch';
import STATUSES from '@/api/enums/statuses';

export default {
  name: 'AuditView',
  mixins: [ AuditForm ],
  components: {
    AuditViewRow,
    AuditClientTable,
    PanelDifference,
    AuditStatusSwitch
  },
  computed: {
    hasControls() {
      return this.audit.canEdit && (includes([STATUSES.APPROVAL], this.status) || (this.isAdmin && includes([STATUSES.ADJUSTMENT], this.status)));
    },
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    diff() {
      return !includes([STATUSES.APPROVAL, STATUSES.ADJUSTMENT], get(this.audit, 'status.code'));
    },
    hasInn() {
      return (!!this.fields.inn && !!this.fields.inn[0]);
    },
    innName: function () {
      if(this.fields.clients[0].length) return [this.fields.clients[0][0].name];
      else return false;
    }
  },
  methods: {
    onSubmit(status) {
      this.submit(status);
    }
  }
}
</script>

<style module lang="scss">
.root{}
</style>
