<template>
  <div :class="$style.root">
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="[
          {text: 'Изменён', value: 'date'},
          {text: 'Клиент', value: 'name', sortable: false},
          {text: 'ИНН ', value: 'inn'},
          {text: '% скидки изменился с', value: 'sales[0]', sortable: false},
          {text: 'Текущий ', value: 'sales[1]', sortable: false},
        ]"
        :mobile-breakpoint="0"
        :height="height"
        :items="items"
        :item-class="({ inn }) => [$style.row, {
            [$style.active]: inn === active,
        }]"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500],
          itemsPerPageText: '',
        }"
        @click:row="onClickRow"
    >
      <template v-slot:body.prepend>
        <statuses-list-filter v-model="filter" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {get, pick, merge} from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import StatusesListFilter from '@/components/StatusesListFilter/StatusesListFilter';

export default {
  name: 'DealerStatusesList',
  components: {
    StatusesListFilter,
  },
  data() {
    const filter = {};
    const options = pick(JSON.parse(localStorage.getItem('DealerStatusesList.options')), [ 'sortBy', 'sortDesc', 'page', 'itemsPerPage' ]);
    return {
      height: window.innerHeight - 90,
      filter: merge({}, filter),
      options: merge({
        sortBy: ['date'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 20,
      }, options),
    }
  },
  computed: {
    ...mapGetters({
      pending: 'statuses/pending',
      items: 'statuses/items',
      count: 'statuses/count',
    }),
    active() {
      return this.$route.params.inn;
    },
    sort() {
      const field = get(this.options, ['sortBy', 0], 'name');
      const direction = ['asc', 'desc'][+get(this.options, ['sortDesc', 0], false)];
      const alias = {
        'sales[0]': 'saleOld',
        'sales[1]': 'saleNew',
      };
      return {
        field: alias[field] || field,
        direction,
      };
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'statuses/fetchItems',
      fetchExel: 'statuses/fetchExel',
    }),
    update() {
      this.fetchItems({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      });
    },
    updateLocalStorage() {
      localStorage.setItem('DealerStatusesList.options', JSON.stringify(this.options));
    },
    resize() {
      this.height = window.innerHeight - 90;
    },
    onClickRow({ inn }) {
      this.$router.push({ name: 'DealerStatusesListPage', params: { inn }});
    }
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    filter: {
      handler() {
        this.update();
      },
      deep: true,
    },
    options: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-data-footer{
      justify-content: flex-start;
      padding-left: 16px;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
  .row {
    cursor: pointer;
    td {
      width: 1px;
    }
  }
  .active {
    background: #eeeeee;
  }
}
</style>
