const statuses = {
  TOAPPROVE: 'toApprove',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  ADJUSTMENT: 'adjustment',
  CREATURE: 'creature', // Не существует в базе служебный чтобы отличить состояние создания
  EDITING: 'editing', // Не существует в базе служебный чтобы отличить состояние изменения
  CANCEL: 'cancel', // Не существует в базе служебный чтобы отличить состояние отмены текущих изменений
};

export const conditions = {
  ROC: 'ROC',
  BELOW_ROC: 'below_ROC',
};

export default statuses;
