import {get, includes, set, map, toNumber, findIndex} from 'lodash';
import employee from './api';
import {employeeItemAdapter} from '@/store/employee/utils';
import {enumerationAdapter} from "../utils";

const START = 'START';
const END = 'END';
const UPDATE_COUNT = 'UPDATE_COUNT';
const UPDATE_ITEMS = 'UPDATE_ITEMS';
const UPDATE_POSITION_TYPES = 'UPDATE_POSITION_TYPES';
const UPDATE_CURRENT = 'UPDATE_CURRENT';
const REMOVE_CURRENT = 'REMOVE_CURRENT';

export default {
    namespaced: true,
    state: {
        items: [],
        positionTypes: [],
        count: 0,
        current: null,
        pending: {
            update: false,
            send: false,
            fetchItems: false,
            fetchCurrent: false,
            fetchPositionTypes: false,
        }
    },
    getters: {
        pending(state) {
            return includes(state.pending, true);
        },
        current(state) {
            return employeeItemAdapter(state.current);
        },
        items(state) {
            return map(state.items, employeeItemAdapter);
        },
        positionTypes(state) {
            return map(state.positionTypes, enumerationAdapter);
        },
        count(state) {
            return toNumber(state.count);
        },
    },
    mutations: {
        [START](state, key) {
            set(state, ['pending', key], true);
        },
        [END](state, key) {
            set(state, ['pending', key], false);
        },
        [REMOVE_CURRENT](state) {
            state.current = null;
        },
        [UPDATE_CURRENT](state, employee) {
            state.current = employee;
            const index = findIndex(state.items, {id: employee.id});
            if (index + 1) {
                state.items.splice(index, 1, employee);
            }
        },
        [UPDATE_ITEMS](state, items) {
            state.items = items;
        },
        [UPDATE_COUNT](state, count) {
            state.count = count;
        },
        [UPDATE_POSITION_TYPES](state, items) {
            state.positionTypes = items;
        },
    },
    actions: {
        async fetchCurrent({commit}, id) {
            commit(START, 'fetchCurrent');
            const {result} = await employee.item(id);
            const success = !get(result, 'error');
            if (success) {
                commit(UPDATE_CURRENT, result);
            }
            commit(END, 'fetchCurrent');

            if (!success) throw {
                error: 'Ошибка получения данных',
                error_description: get(result, 'message'),
            };
        },
        async removeCurrent({commit}) {
            commit(REMOVE_CURRENT);
        },
        async fetchItems({commit}, {page, size, sort, filter}) {
            commit(START, 'fetchItems');
            const {result} = await employee.list(page, size, sort, filter);
            const success = !get(result, 'error');
            if (success) {
                commit(UPDATE_ITEMS, result.items);
                commit(UPDATE_COUNT, toNumber(get(result, 'count', 0)));
            }
            commit(END, 'fetchItems');

            if (!success) throw {
                error: 'Ошибка получения данных',
                error_description: get(result, 'message'),
            };
        },
        async update({commit}, {id, active}) {
            commit(START, 'update');
            const {result} = await employee.update({id, active});
            const success = !get(result, 'error');
            if (success) {
                commit(UPDATE_CURRENT, result);
            }
            commit(END, 'update');

            if (!success) throw {
                error: 'Ошибка при попытке сохранить данные заявки',
                error_description: get(result, 'message'),
            }

            return get(result, 'id', null);
        },
        async fetchPositionTypes({commit}) {
            commit(START, 'fetchPositionTypes');
            const {result} = await employee.positionTypes();
            const success = !get(result, 'error');
            if (success) commit(UPDATE_POSITION_TYPES, result.items);
            commit(END, 'fetchPositionTypes');

            if (!success) throw {
                error: 'Ошибка получения данных',
                error_description: get(result, 'message'),
            };
        },
        async create({commit}, fields) {
            commit(START, 'create');
            const {result} = await employee.create(fields);
            const success = !get(result, 'error');
            if (success) {
                commit(UPDATE_CURRENT, result);
            }
            commit(END, 'create');

            if (!success) throw {
                error: 'Ошибка при попытке сохранить данные',
                error_description: get(result, 'message'),
            }
console.log('result',result);
            return get(result, 'id', null);
        },
    },
}
