<template>
  <div :class="$style.root">
    <v-tooltip v-if="!isAdminView" left>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn @click="download" color="primary" absolute right fab v-bind="attrs" v-on="on">
            <v-icon v-text="'mdi-table-arrow-right'" />
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Экспорт в excel</span>
    </v-tooltip>
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="[
          {text: 'Изменён', value: 'date'},
          {text: 'Клиент', value: 'name', sortable: false},
          {text: 'ИНН ', value: 'inn'},
          {text: 'Классификация изменилась с', value: 'classifications[0].name', sortable: false},
          {text: 'Текущая ', value: 'classifications[1].name', sortable: false},
        ]"
        :mobile-breakpoint="0"
        :height="height"
        :items="items"
        :item-class="({ inn }) => [$style.row, {
            [$style.active]: inn === active,
        }]"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500],
          itemsPerPageText: '',
        }"
        @click:row="onClickRow"
    >
      <template v-slot:body.prepend>
        <classifications-list-filter v-model="filter" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {get, pick, merge} from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import ClassificationsListFilter from '@/components/ClassificationsListFilter/ClassificationsListFilter';

export default {
  name: 'ClassificationsList',
  components: {
    ClassificationsListFilter,
  },
  data() {
    const filter = {};
    const options = pick(JSON.parse(localStorage.getItem('ClassificationsList.options')), [ 'sortBy', 'sortDesc', 'page', 'itemsPerPage' ]);
    return {
      height: window.innerHeight - 90,
      filter: merge({}, filter),
      options: merge({
        sortBy: ['date'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 20,
      }, options),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'classifications/pending',
      items: 'classifications/items',
      count: 'classifications/count',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    active() {
      return this.$route.params.inn;
    },
    sort() {
      const field = get(this.options, ['sortBy', 0], 'name');
      const direction = ['asc', 'desc'][+get(this.options, ['sortDesc', 0], false)];
      const alias = {
        'sales[0]': 'saleOld',
        'sales[1]': 'saleNew',
      };
      return {
        field: alias[field] || field,
        direction,
      };
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'classifications/fetchItems',
      fetchExel: 'classifications/fetchExel',
    }),
    download() {
      this.fetchExel({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      }).then(() => {
        this.$snack({
          text: 'Экспорт истории классификаций добавлен в очередь на формирование XLSX. После формирования файл будет доступен в разделе «Загрузка»',
          pos: 'top-center',
          actionText: 'Понятно',
        });
      }).catch((error) => {
        this.$snack({
          text: get(error, 'error_description', 'Сервис не доступен, попробуйте позже'),
          pos: 'top-center',
          actionText: 'Понятно',
        });
      });
    },
    update() {
      this.fetchItems({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      });
    },
    updateLocalStorage() {
      localStorage.setItem('ClassificationsList.options', JSON.stringify(this.options));
    },
    resize() {
      this.height = window.innerHeight - 90;
    },
    onClickRow({ inn }) {
      this.$router.push({ name: 'ClassificationsListPage', params: { inn }});
    }
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    filter: {
      handler() {
        this.update();
      },
      deep: true,
    },
    options: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-data-footer{
      justify-content: flex-start;
      padding-left: 16px;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
  .row {
    cursor: pointer;
    td {
      width: 1px;
    }
  }
  .active {
    background: #eeeeee;
  }
}
</style>
