import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import UserSignInPage from '@/pages/UserSignInPage/UserSignInPage';
import UserRecoveryPage from '@/pages/UserRecoveryPage/UserRecoveryPage';
import UserRecoverySuccessPage from '@/pages/UserRecoverySuccessPage/UserRecoverySuccessPage';
import UserPasswordChangePage from '@/pages/UserPasswordChangePage/UserPasswordChangePage';
import ClientListPage from '@/pages/ClientListPage/ClientListPage';
import TaskListPage from '@/pages/TaskListPage/TaskListPage';
import RelevanceListPage from '@/pages/RelevanceListPage/RelevanceListPage';
import AuditListPage from '@/pages/AuditListPage/AuditListPage';
import AuditResultListPage from '@/pages/AuditResultListPage/AuditResultListPage';
import StatusesListPage from '@/pages/StatusesListPage/StatusesListPage';
import ClassificationsListPage from '@/pages/ClassificationsListPage/ClassificationsListPage';
import DealerClientListPage from '@/pages/DealerClientListPage/DealerClientListPage';
import DealerTaskListPage from '@/pages/DealerTaskListPage/DealerTaskListPage';
import DealerStatusesListPage from '@/pages/DealerStatusesListPage/DealerStatusesListPage';
import InnCheckPage from '@/pages/InnCheckPage/InnCheckPage';
import AdvanceClientListPage from '@/pages/AdvanceClientListPage/AdvanceClientListPage';
import UserListPage from '@/pages/UserListPage/UserListPage';
import PromotionsListPage from "@/pages/PromotionsListPage/PromotionsListPage.vue";
import StopListPage from "@/pages/StopListPage/StopListPage.vue";
import DownloadsListPage from "@/pages/DownloadsListPage/DownloadsListPage.vue";

const ifNotAuthenticated = (to, from, next) => {
  if (!['Y', 'true', true].includes(localStorage.getItem('auth'))) {
    next();
    return;
  }
  next({ name: 'ClientListPage' });
}
const ifAuthenticated = (to, from, next) => {
  if (['Y', 'true', true].includes(localStorage.getItem('auth'))) {
    next();
    return;
  }
  next({ name: 'UserSignInPage' });
}

export const router = new Router({
  base: '/',
  routes: [
    {
      path: '/',
      name: 'UserSignInPage',
      component: UserSignInPage,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/user/recovery',
      name: 'UserRecoveryPage',
      component: UserRecoveryPage,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/user/recovery/success',
      name: 'UserRecoverySuccessPage',
      component: UserRecoverySuccessPage,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/user/recovery/form',
      name: 'UserPasswordChangePage',
      component: UserPasswordChangePage,
      beforeEnter: ifNotAuthenticated,
    },

    {
      path: '/clients/:inn?/:view?',
      name: 'ClientListPage',
      component: ClientListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/tasks/:id?',
      name: 'TaskListPage',
      component: TaskListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/audit/:id?',
      name: 'AuditListPage',
      component: AuditListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/audit_result/:id?',
      name: 'AuditResultListPage',
      component: AuditResultListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/relevance/:inn?/:view?',
      name: 'RelevanceListPage',
      component: RelevanceListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/statuses/:inn?/:view?',
      name: 'StatusesListPage',
      component: StatusesListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/classifications/:inn?/:view?',
      name: 'ClassificationsListPage',
      component: ClassificationsListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/dealer_clients/:inn?/:view?',
      name: 'DealerClientListPage',
      component: DealerClientListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/dealer_tasks/:id?',
      name: 'DealerTaskListPage',
      component: DealerTaskListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/dealer_statuses/:inn?/:view?',
      name: 'DealerStatusesListPage',
      component: DealerStatusesListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/advance/:inn?/:view?',
      name: 'AdvanceClientList',
      component: AdvanceClientListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/users/:id?',
      name: 'UserListPage',
      component: UserListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/check/',
      name: 'InnCheckPage',
      component: InnCheckPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/promotions/:id?',
      name: 'PromotionsListPage',
      component: PromotionsListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/stop_list/:inn?/:view?',
      name: 'StopListPage',
      component: StopListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/downloads/:id?/:view?',
      name: 'DownloadsListPage',
      component: DownloadsListPage,
      props: true,
      beforeEnter: ifAuthenticated,
    },
    { path: '*', redirect: { name: 'UserSignInPage' }}
  ],
  mode: 'history',
});
