import axios from 'axios';
import Cookie from 'js-cookie';
import { isUndefined, get, map, merge, forEach, isArray } from 'lodash';
import { config, error, sessid } from '@/api/interceptors';

axios.interceptors.request.use(config, undefined);
axios.interceptors.response.use(sessid, error);

const ROOT = '/rest/';

export class Api {
  /**
   *
   * @param {string} root Корень rest
   */
  constructor(root = ROOT) {
    this.root = root;
  }

  /**
   * Чтение запись в cookie браузера
   * @param {string} name
   * @param {string} [value]
   * @returns {string}
   */
  cookie(name, value) {
    if (!isUndefined(value)) return Cookie.set(name, value);
    return Cookie.get(name);
  }

  /**
   * Формирование FormData из объекта
   * @param {object} object
   * @returns {FormData}
   */
  toFormData(object) {
    const data = new FormData();
    forEach(object, (value, key) => {
      if (isArray(value)) {
        value.length ?
          forEach(value, value => data.append(`${key}[]`, value)):
          data.append(`${key}`, '');
      } else {
        data.append(key, value)
      }
    });
    return data;
  }

  /**
   * Обработка ошибок соединения
   * @param error
   */
  catch(error) {
    console.error(error);
  }

  /**
   * Переопределенный метод запроса с токеном авторизации
   * @param url
   * @param options
   * @returns {Promise<any>}
   */
  async request(url, options = {}) {
    const defaultOptions = {};
    return axios(this.root + url, merge(defaultOptions, options))
      .then(res => get(res, 'data.result', null))
      .catch(this.catch);
  }

  /**
   * Загрузка файлов на сервер
   * @param files
   * @returns {Promise<*>}
   */
  async upload(files) {
    return this.request('/utility.fileSave', {
      method: 'POST',
      data: this.toFormData({
        files,
      }),
    }).then(result => {
      return map(get(result, 'result.files'), (file) => ({
        id: get(file, 'id',''),
        name: get(file, 'name',''),
        size: get(file, 'sizeH',''),
        byte: get(file, 'size',''),
        path: get(file, 'path',''),
        description: get(file, 'description',''),
        extension: get(file, 'extension', ''),
      }))
    })
  }

  /**
   * Эмуляция клика по ссылке с атрибутом download
   * @param {string} url
   * @param {string} name
   * @param {object} params
   * @returns {Promise<void>}
   */
  async download(url, name, params) {
    const link = document.createElement('a');
    const href = url + `?${new URLSearchParams(params).toString()}`;
    link.setAttribute('download', name || 'file');
    link.setAttribute('href', href);
    link.click();
  }
}

export default new Api();

