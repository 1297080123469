import {get, toString} from 'lodash';

/**
 * Заглушка пустой заявки
 * @param item
 */
export const employeeEmptyAdapter = (item) => {
    return {
        id: [get(item, 'id', '')],
        dateRegister: [get(item, 'dateRegister', '')],
        name: [get(item, 'name', '')],
        email: [get(item, 'email', '')],
        personalPhone: [get(item, 'personalPhone', '')],
        activeCode: [get(item, 'activeCode', 'Y')],
        positionType: [get(item, 'positionType', '')],
        position: [get(item, 'position', '')],
        password: [get(item, 'password', '')],
    }
};

/**
 * Выборка полей заявки из структуры arResult
 * @param item
 * @returns {{id: string, dateRegister: string, name:string, email:string, personalPhone:string, active:string, activeCode:string, position:string, positionType:string}}
 */
export const employeeItemAdapter = (item) => {
    return {
        id: toString(get(item, 'id')),
        dateRegister: toString(get(item, 'dateRegister')),
        name: toString(get(item, 'name')),
        email: toString(get(item, 'email')),
        personalPhone: toString(get(item, 'personalPhone')),
        active: toString(get(item, 'active')),
        activeCode: toString(get(item, 'activeCode')),
        position: toString(get(item, 'position')),
        positionType: toString(get(item, 'positionType')),
    };
};
