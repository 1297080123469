<template>
  <audit-view-row  v-if="disabled" :title="title" :value="value" :items="items" />
  <div :class="$style.root" v-else class="mb-5">
    <v-row dense>
      <v-col :cols="12 / value.length" v-for="(val, index) in value" :key="index">
          <component
              :is="component || (items ? 'v-select' : 'v-text-field')"
              :value="val"
              :label="(value.length - (index + 1)) ? 'Было' : title"
              :items="items"
              :readonly="!!(value.length - (index + 1))"
              :disabled="disabled"
              :rules="(value.length - (index + 1)) ? [] : rules"
              :check="check"
              :rows="rows"
              outlined
              dense
              hide-details="auto"
              @input="(value) => onInput(value, index)"
              @update="(value) => $emit('update', value)"
          />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { set } from 'lodash';
import InnField from '@/components/InnField/InnField';
import AuditViewRow from '@/components/AuditViewRow/AuditViewRow';

export default {
  name: 'AuditFormRow',
  components: {
    InnField,
    AuditViewRow
  },
  props: {
    title: { type: String },
    value: { type: Array, default: () => [] },
    rules: { type: Array, default: () => [] },
    check: { type: Array, default: () => [] },
    rows: { type: Number, default: 3 },
    items: { type: Array },
    disabled: { type: Boolean },
    component: { type: String }
  },
  methods: {
    onInput(value, index) {
      this.$emit('innString', value);
      const next = JSON.parse(JSON.stringify(this.value));
      set(next, index, value);
      return this.$emit('input', next);
    }
  }
}
</script>

<style module lang="scss">
.root{
  textarea {
    margin-top: 12px;
    margin-bottom: 12px;
    width: 100%;
    resize: none;
  }
}
</style>
