<template>
<div :class="$style.root">
  <v-file-input multiple :class="$style.input" :id="`FILE_${_uid}`" @change="upload" />
  <div v-if="value.length" :class="$style.list">
    <div v-for="({name, size}, index) in value" :class="$style.li" :key="index">
      <v-text-field :label="size" :value="name" outlined dense readonly hide-details />
    </div>
  </div>
  <div v-if="files.length" :class="$style.list">
    <div v-for="({name, size}, index) in files" :class="$style.li" :key="index">
      <v-text-field
          outlined
          dense
          readonly
          hide-details="auto"
          :label="size"
          :value="name"
      />
      <v-btn class="ml-2" small icon @click="files.splice(index, 1)">
        <v-icon dense v-text="'mdi-delete'" />
      </v-btn>
    </div>
  </div>
  <v-btn :class="$style.btn" tag="label" depressed :for="`FILE_${_uid}`" :loading="pending">
    <v-icon v-text="'mdi-plus'" />
    Загрузить
  </v-btn>
</div>
</template>

<script>
import api from '@/api';
import { concat } from 'lodash'

export default {
  name: 'FileList',
  props: {
    value: { type: Array },
  },
  data(){
    return {
      pending: false,
      files: [],
    }
  },
  methods: {
    upload(items) {
      this.pending = true;
      api.upload(items).then(files => {
        this.files = concat(this.files, files);
        this.pending = false;
      });
    }
  },
  watch: {
    files: {
      deep: true,
      handler() {
        this.$emit('input', [
          ...this.value,
          ...this.files,
        ]);
      }
    }
  }
}
</script>

<style module lang="scss">
.root{
  .input {
    display: none;
  }
  .list{
    margin-bottom: 20px;
    .li{
      margin-bottom: 15px;
      display: flex;
      align-items: center;
    }
  }
  .btn {
    cursor: pointer;
  }
}
</style>
