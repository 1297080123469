import { toString, forEach } from 'lodash';
import { Api } from '@/api';

export class ChecksList extends Api {
  /**
   * Получение списка доступных статусов
   * @param page
   * @param size
   * @param sort
   * @param filter
   * @returns {Promise<*>}
   */
  list(page = 1, size = -1, sort = {}, filter = {}) {
    const params = {
      page: toString(page),
      size: toString(size),
    };
    forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
    forEach(filter, (value, key) => params[`filter[${key}]`] = value);
    return this.request('audit.listChecks', {
      params,
    });
  }
}

export default new ChecksList();
