import { get, includes, map, set, toNumber } from 'lodash';
import client from '@/store/clients/api';
import { priceAdapter } from '@/store/utils';
import { realizationItemAdapter } from './utils';

const START = 'START';
const END = 'END';
const UPDATE_COUNT = 'UPDATE_COUNT';
const UPDATE_SUM = 'UPDATE_SUM';
const UPDATE_ITEMS = 'UPDATE_ITEMS';
const UPDATE_DISTRIBUTORS = 'UPDATE_DISTRIBUTORS';
const UPDATE_ACTUAL_PERIOD = 'UPDATE_ACTUAL_PERIOD';

export default {
  namespaced: true,
  state: {
    items: [],
    sum: 0,
    count: 0,
    distributors: [],
    actualPeriod: {},
    pending: {
      fetchItems: false,
      fetchDistributors: false,
      sendItem: false,
      getActualPeriod: false,
    }
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    items(state) {
      return map(state.items, realizationItemAdapter);
    },
    distributors(state){
      return map(state.distributors, realizationItemAdapter);
    },
    sum(state){
      return priceAdapter(state.sum || 0);
    },
    count(state) {
      return toNumber(state.count);
    }
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [UPDATE_DISTRIBUTORS](state, items) {
      state.distributors = items;
    },
    [UPDATE_ITEMS](state, items) {
      state.items = items;
    },
    [UPDATE_SUM](state, sum) {
      state.sum = sum;
    },
    [UPDATE_COUNT](state, count) {
      state.count = count;
    },
    [UPDATE_ACTUAL_PERIOD](state, actualPeriod) {
      state.actualPeriod = actualPeriod;
    },
  },
  actions: {
    async fetchItems({ commit }, { inn, distributor }) {
      commit(START, 'fetchItems');
      const { result } = await client.realization(inn, distributor);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_ITEMS, result.items);
        commit(UPDATE_COUNT, toNumber(get(result, 'count', 0)));
      }
      commit(END, 'fetchItems');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async getActualPeriod({ commit }, { inn, type }) {
      commit(START, 'getActualPeriod');
      const { result } = await client.actualPeriod(inn, type);
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_ACTUAL_PERIOD, get(result, 'actualPeriod', {}));
      }
      commit(END, 'getActualPeriod');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };

      return get(result, 'actualPeriod', {});
    },
    async clearItems({ commit }) {
      commit(UPDATE_ITEMS, []);
      commit(UPDATE_COUNT, 0);
    },
    async fetchDistributors({ commit }, { inn }) {
      commit(START, 'fetchDistributors');
      const { result } = await client.realizationsByDistributors(inn, '');
      const success = !get(result, 'error');
      if (success) {
        commit(UPDATE_DISTRIBUTORS, get(result, 'items', []));
        commit(UPDATE_SUM, toNumber(get(result, 'sum', 0)));
      }
      commit(END, 'fetchDistributors');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async clearDistributors({ commit }) {
      commit(UPDATE_DISTRIBUTORS, []);
      commit(UPDATE_SUM, 0);
    },
    async sendItem({ commit, dispatch }, { inn, sum, date, type }) {
      commit(START, 'sendItem');
      const { result } = await client.addRealization(inn, sum, date, type);
      const success = !get(result, 'error');
      if (success) dispatch('fetchItems', { inn });
      commit(END, 'sendItem');

      if (!success) throw {
        error: 'Ошибка отправки сообщения',
        error_description: get(result, 'message'),
      };
    }
  }
};
