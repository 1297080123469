var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('v-data-table',{attrs:{"fixed-header":"","must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":[
        {text: 'Изменён', value: 'date'},
        {text: 'Клиент', value: 'name', sortable: false},
        {text: 'ИНН ', value: 'inn'},
        {text: '% скидки изменился с', value: 'sales[0]', sortable: false},
        {text: 'Текущий ', value: 'sales[1]', sortable: false} ],"mobile-breakpoint":0,"height":_vm.height,"items":_vm.items,"item-class":function (ref) {
            var _obj;

            var inn = ref.inn;
            return [_vm.$style.row, ( _obj = {}, _obj[_vm.$style.active] = inn === _vm.active, _obj )];
},"server-items-length":_vm.count,"options":_vm.options,"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 100, 500],
        itemsPerPageText: '',
      }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('statuses-list-filter',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }