<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template v-slot:activator="{ on: { click }}">
      <slot name="activator" v-bind="{ onOpen: click, onInput, value: computedDateFormatted, mask }">
        <v-text-field
            v-mask="mask"
            :value="computedDateFormatted"
            dense
            clearable
            placeholder="Все"
            prepend-icon="mdi-calendar"
            hide-details
            @input="onInput"
            @click:prepend="click"
        />
      </slot>
    </template>
    <v-date-picker v-model="date" scrollable>
      <v-spacer />
      <v-btn text color="primary" @click="menu = false">
        Отмена
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { mask } from 'vue-the-mask';
export default {
  name: 'DatePicker',
  directives: { mask },
  props: {
    value: { type: String },
  },
  data() {
    return {
      mask: '##.##.####',
      date: this.parseDate(this.value),
      dateFormatted: this.value,
      menu: false,
    }
  },
  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date);
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return '';
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    onInput(value) {
      if (value && value.replace(/[^\d]/ig, '').length === 8) {
        this.date = this.parseDate(value);
        this.$emit('input', value);
      } else {
        this.date = null;
        this.$emit('input', '');
      }
    }
  },
  watch: {
    date (val) {
      this.menu = false;
      this.dateFormatted = this.formatDate(val);
      this.$emit('input', this.dateFormatted);
    },
  },
}
</script>

<style module lang="scss"></style>
