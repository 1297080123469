<template>
    <div :class="$style.root">
        <v-form v-model="valid" ref="form" @submit.prevent="onSubmit()">
            <div class="mt-4 mb-4">
                <inn-autocomplete-field
                        name="inn"
                        v-model="inn"
                        label="Начните вводить ИНН вашего дистрибутора"
                        class="mt-4"
                        :rules="[rules.required]"
                        v-if="!result"
                />
            </div>
            <div class="mt-4 mb-4">
                <v-textarea
                        name="question"
                        v-model="question"
                        label="Ваше сообщение дистрибутору"
                        outlined
                        dense
                        hide-details="auto"
                        class="mt-4"
                        :rules="[rules.required]"
                        v-if="!result"
                ></v-textarea>
            </div>
            <div class="mt-4 mb-4">
                <v-text-field
                        name="email"
                        v-model="email"
                        label="Укажите вашу почту"
                        outlined
                        dense
                        hide-details="auto"
                        class="mt-4"
                        :rules="[rules.required, rules.email]"
                        v-if="!result"
                ></v-text-field>
            </div>
            <div v-if="captchaCode && !result" class="mt-4 mb-4">
                <div class="row">
                    <div class="col">
                        <input name="captchaCode" v-model="captchaCode" type="hidden">
                        <v-text-field
                                name="captchaWord"
                                v-model="captchaWord"
                                label="Код с картинки"
                                outlined
                                dense
                                hide-details="auto"
                                :rules="[rules.required]"
                                v-if="!result"
                        ></v-text-field>
                    </div>
                    <div class="col">
                        <img :src="`/bitrix/tools/captcha.php?captcha_code=${captchaCode}`"/>
                    </div>
                </div>
            </div>
            <v-alert v-if="result" dense outlined type="success">
                <div v-html="result"/>
            </v-alert>
            <v-alert v-if="error" dense outlined type="error">
                <div v-html="error.error_description"/>
            </v-alert>
            <div>
                <v-btn
                        v-text="'Отправить'"
                        depressed
                        color="primary"
                        type="submit"
                        class="mr-3"
                        v-if="!result"
                />
                <v-btn
                        v-text="'Закрыть'"
                        depressed
                        @click="onReset"
                />
            </div>
        </v-form>
        <div v-if="pending" :class="$style.loader"/>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';
    import InnAutocompleteField from "../InnAutocompleteField/InnAutocompleteField";

    export default {
        name: 'DistributorQuestionForm',
        components: {InnAutocompleteField},
        props: {
            value: {type: Object},
        },
        data() {
            return {
                valid: true,
                error: null,
                question: '',
                inn: '',
                email: '',
                code: '',
                result: null,
                captchaCode: '',
                captchaWord: '',
                pending: true,
                rules: {
                    required: v => !!v || 'Обязательное поле',
                    email: v => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(v) || 'Некорректный адрес электронной почты',
                },
            }
        },
        methods: {
            ...mapActions({
                sendDistributorQuestionForm: 'user/sendDistributorQuestionForm',
                getCaptcha: 'user/getCaptcha',
            }),
            reset() {
                this.question = '';
            },
            onSubmit() {
                this.$refs.form.validate();
                if (this.valid) {
                    this.error = null;
                    const data = {
                        inn: this.inn,
                        question: this.question,
                        email: this.email,
                        captchaCode: this.captchaCode,
                        captchaWord: this.captchaWord,
                    };
                    this.sendDistributorQuestionForm(data)
                        .then(() => {
                            this.result = 'Спасибо за Ваше обращение!';
                        })
                        .catch((error) => {
                            this.error = error;
                            if(!error.error_description || error.error_description !== 'Код с картинки указан не верно!') {
                                this.resetCaptcha();
                            }
                        });
                }
            },
            onReset() {
                this.reset();
                this.$emit('reset');
            },
            resetCaptcha() {
                this.getCaptcha()
                    .then((data) => {
                        if (data && data.captcha) {
                            this.captchaCode = data.captcha;
                            this.pending = false;
                        } else {
                            this.error = 'Что-то пошло не так, попробуйте позже';
                        }
                    })
                    .catch((error) => (this.error = error));
            }
        },
        beforeMount() {
            this.reset();
            this.resetCaptcha()
        },
    }
</script>

<style module lang="scss">
    .root {
        position: relative;

        .loader {
            background: rgba(255, 255, 255, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
</style>
