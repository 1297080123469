<template>
  <div :class="$style.root">
    <v-tooltip v-if="canExport && !isAdminView" left>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn @click="download" color="primary" absolute right fab v-bind="attrs" v-on="on">
            <v-icon v-text="'mdi-table-arrow-right'" />
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Экспорт в excel</span>
    </v-tooltip>
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="tableHeaders"
        :mobile-breakpoint="0"
        :height="height"
        :items="items"
        :item-class="({ inn }) => [$style.row, {
            [$style.active]: inn === active,
        }]"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500],
          itemsPerPageText: '',
        }"
        @click:row="onClickRow"
    >
      <template v-slot:item.distributors="{ item }">
        <div :class="$style.distributors">
          {{ _.join(_.map(item.distributors, 'name'), ', ') }}
        </div>
      </template>
      <template v-slot:item.realizations="{ item }">
        <div :class="$style.distributors">
          {{ item.realizations }} руб.
        </div>
      </template>
      <template v-slot:item.bpConnected.name="{ item }">
        <div :class="$style.distributors">
          <v-icon v-if="item.bpConnected.code === 'Y'" color="success" v-text="'mdi-check-bold'" />
          <v-icon v-else color="error" v-text="'mdi-close-thick'" />
        </div>
      </template>
      <template v-slot:body.prepend>
        <client-list-filter v-model="filter" :preFilter="preFilter" :show-date-column="showDateColumn" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {get, pick, merge} from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import ClientListFilter from '@/components/ClientListFilter/ClientListFilter';

export default {
  name: 'ClientList',
  components: {
    ClientListFilter,
  },
  props: {
    preFilter: { type: Object || undefined },
    showDateColumn: { type: Boolean, default: false },
  },
  data() {
    const filter = this.preFilter ?? {};
    const options = pick(JSON.parse(localStorage.getItem(this.$route.name + '.options')), [ 'sortBy', 'sortDesc', 'page', 'itemsPerPage' ]);

    return {
      height: window.innerHeight - 90,
      filter: merge({}, filter),
      options: merge({
        sortBy: ['name'],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 20,
      }, options),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      canExport: 'user/canExport',
      pending: 'clients/pending',
      items: 'clients/items',
      count: 'clients/count',
    }),
    tableHeaders() {
      let headers = [
        {text: 'Название', value: 'name'},
        {text: 'ИНН', value: 'inn'},
        {text: 'Продажи (всего)', value: 'realizations', sortable: this.isAdmin || this.isAdminView},
        {text: 'Округ', value: 'district.name'},
        {text: 'Регион', value: 'region.name'},
        {text: 'Статус', value: 'status.name'},
        {text: 'Рекомендованный статус', value: 'recommendedStatus.name'},
        {text: 'bp.iek.ru', value: 'bpConnected.name'},
        {text: 'Классификация', value: 'classification.name'},
        {text: 'Дистрибуторы', value: 'distributors'},
      ];

      if (this.showDateColumn) {
        headers.unshift({text: 'Дата включения', value: 'statusChangeDate.name', sortable: false});
      }

      return headers.filter(({value}) => {
        if (value === 'bpConnected.name') {
          return this.isAdmin || this.isAdminView
        }
        return this.isAdmin || this.isAdminView || value !== 'distributors'
      });
    },
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    active() {
      return this.$route.params.inn;
    },
    sort() {
      const field = get(this.options, ['sortBy', 0], 'name');
      const direction = ['asc', 'desc'][+get(this.options, ['sortDesc', 0], false)];
      const alias = {
        'district.name': 'district',
        'region.name': 'region',
        'status.name': 'status',
        'classification.name': 'classification',
        'bpConnected.name': 'bpConnected',
        'recommendedStatus.name': 'recommendedStatus',
      };
      return {
        field: alias[field] || field,
        direction,
      };
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'clients/fetchItems',
      fetchExel: 'clients/fetchExel',
    }),
    update() {
      this.fetchItems({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      });
    },
    download() {
      this.fetchExel({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      }).then(() => {
        this.$snack({
          text: 'Экспорт списка клиентов добавлен в очередь на формирование XLSX. После формирования файл будет доступен в разделе «Загрузка»',
          pos: 'top-center',
          actionText: 'Понятно',
        });
      }).catch((error) => {
        this.$snack({
          text: get(error, 'error_description', 'Сервис не доступен, попробуйте позже'),
          pos: 'top-center',
          actionText: 'Понятно',
        });
      });
    },
    updateLocalStorage() {
      localStorage.setItem(this.$route.name + '.options', JSON.stringify(this.options));
    },
    resize() {
      this.height = window.innerHeight - 90;
    },
    onClickRow({ inn }) {
      this.$router.push({ name: this.$route.name, params: { inn }});
    }
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    filter: {
      handler() {
        this.update();
      },
      deep: true,
    },
    options: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-data-footer{
      justify-content: flex-start;
      padding-left: 16px;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
  .distributors {
    white-space: nowrap;
  }
  .row {
    cursor: pointer;
    td {
      width: 1px;
    }
  }
  .active{
    background: #eeeeee;
  }
}
</style>
