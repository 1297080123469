<template>
<div :class="$style.root">
  <dynamic-scroller :class="$style.list" key-field="id" :items="value" :min-item-size="50">
    <template v-slot="{ item, index, active }">
      <dynamic-scroller-item
          :class="[$style.item]"
          :item="item"
          :active="active"
          :size-dependencies="[item.text]"
      >
        <div :class="[$style.comment, {[$style.my]: item.user === user}]">
          {{item.text}}
          <div :class="$style.signature">
            {{item.author}}
            {{item.date}}
          </div>
        </div>
      </dynamic-scroller-item>
    </template>
  </dynamic-scroller>
  <slot />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {DynamicScroller, DynamicScrollerItem} from 'vue-virtual-scroller';

export default {
  name: 'CommentList',
  components: {
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    value: { type: Array, default: () => []  },
  },
  computed: {
    ...mapGetters({
      user: 'user/id',
    })
  }
}
</script>

<style module lang="scss">
.root {
  font-size: 12px;
  .list {
    max-height: 300px;
    @include scroll();
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .item {
    overflow: hidden;
    padding-bottom: 10px;
  }
  .comment {
    white-space: normal;
    padding: 10px;
    background: #eee;
    max-width: 90%;
    float: left;
    &.my {
      background: #f0f8ff;
      float: right;
    }
  }
  .signature {
    margin-top: 10px;
    padding-top: 10px;
    color: #666;
    border-top: 1px dashed #999;
    font-size: 10px;
  }
}
</style>
