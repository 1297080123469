<template>
  <div :class="$style.root">
    <relevance-task-view :task="task" />
    <div v-if="pending" :class="$style.loader" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { taskEmptyAdapter } from '@/store/tasks/utils';

import RelevanceTaskView from '@/components/RelevanceTaskView/RelevanceTaskView';

export default {
  name: 'RelevanceClientDetail',
  components: {
    RelevanceTaskView
  },
  props: {
    inn: { type: String, required: true },
    editing: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      pending: 'clients/pending',
      client: 'clients/current',
    }),
    task() {
      return taskEmptyAdapter(this.client);
    },
  },
  methods: {
    ...mapActions({
      'fetchCurrent': 'clients/fetchCurrent',
      'removeCurrent': 'clients/removeCurrent',
    }),
    onUpdate() {
      this.fetchCurrent(this.inn);
    },
    onReset() {
      if (this.$route.params.view) this.$router.push({ name: this.$route.name, params: { inn: this.inn } });
    },
  },
  watch: {
    inn() {
      this.onUpdate();
      this.onReset();
    },
  },
  beforeMount() { this.onUpdate() },
  beforeDestroy() { this.removeCurrent() }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
