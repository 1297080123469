<template>
<div :class="$style.root" v-if="items.length">
  <v-tooltip v-for="{ text, icon, color, disabled, action } in items" bottom :key="text">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" v-bind="attrs" v-on="on" depressed :disabled="disabled" :color="color" @click="action">
        <v-icon v-if="icon" v-text="icon" />
        <template v-else>{{text}}</template>
      </v-btn>
    </template>
    <span>{{text}}</span>
  </v-tooltip>
  <slot />
</div>
</template>

<script>
import { includes } from 'lodash';
import { mapGetters } from 'vuex';
import STATUSES from '@/api/enums/statuses';

export default {
  name: 'AuditStatusSwitch',
  props: {
    value: { type: String },
    disabled: { type: Boolean },
    editingView: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      accessAuditor: 'user/accessAuditor',
      accessAuditorRM: 'user/accessAuditorRM'
    }),
    isAuditor() {
      return this.accessAuditor || this.accessAuditorRM;
    },
    items() {
      const items = [];
      if(this.isAuditor) {
       if (this.editingView) {
          items.push(
                  {
                    text: 'Сохранить',
                    color: 'primary',
                    action: () => this.$emit('input', this.value),
                  },
                  {
                    text: 'Отмена',
                    action: () => this.$emit('reset', STATUSES.CANCEL),
                  },
          );
        }
        else if (includes(['100_new'], this.value)) {
          items.push(
                  {
                    text: 'На проверку',
                    icon: 'mdi-check-circle-outline',
                    color: 'success',
                    action: () => this.$emit('input', '200_checking'),
                  }
          );
        }
        else if (includes(['200_checking'], this.value)) {
          items.push(
                  {
                    text: 'Проведена',
                    icon: 'mdi-check-circle-outline',
                    color: 'success',
                    action: () => this.$emit('input', '300_success'),
                  },
                  {
                    text: 'Перенос',
                    icon: 'mdi-chevron-double-right',
                    color: 'warning',
                    action: () => this.$emit('input', '400_transfer'),
                  },
                  {
                    text: 'Отказ партнёра',
                    icon: 'mdi-close-circle-outline',
                    color: 'error',
                    action: () => this.$emit('input', '500_decline'),
                  },
          );
        }
        else if (includes(['400_transfer'], this.value)) {
          items.push(
                  {
                    text: 'На проверку',
                    icon: 'mdi-check-circle-outline',
                    color: 'success',
                    action: () => this.$emit('input', '200_checking'),
                  }
          );
        }
      }

      if (includes([STATUSES.CREATURE], this.value)) {
        items.push(
            {
              text: 'Создать заявку',
              color: 'primary',
              disabled: this.disabled,
              action: () => this.$emit('input', '100_new'),
            },
        );
      }

      return items;
    }
  },
}
</script>

<style module lang="scss">
.root {}
</style>
