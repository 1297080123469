<template>
    <tr :class="$style.root">
        <td>
            <div :class="$style.control">
                <date-picker
                    v-model="date"
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                    v-model="user"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                    v-model="id"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                    v-model="name"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-select
                    v-model="district"
                    :items="districts"
                    hideSelected
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-select
                    v-model="classification"
                    :items="classifications"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                    v-model="clientInn"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                    v-model="client"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-select
                    v-model="clientStatus"
                    :items="clientStatuses"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-select
                    v-model="conditions"
                    :items="conditionsList"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-select
                    v-model="status"
                    :items="statuses"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
        <td>
            <div :class="$style.control">
                <v-text-field
                    v-model="distributorName"
                    clearable
                    placeholder="Все"
                    hide-details
                    dense
                    @input="onChange"
                />
            </div>
        </td>
    </tr>
</template>

<script>
import { get, debounce, pickBy } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import DatePicker from '@/components/DatePickerRange/DatePickerRange';

export default {
    name: 'PromotionsListFilter',
    components: {
        DatePicker,
    },
    props: {
        value: {type: Object},
    },
    data() {
        return {
            date: get(this.value, 'date', ''),
            user: get(this.value, 'user', ''),
            id: get(this.value, 'id', ''),
            name: get(this.value, 'name', ''),
            district: get(this.value, 'district', ''),
            classification: get(this.value, 'classification', ''),
            clientInn: get(this.value, 'clientInn', ''),
            client: get(this.value, 'client', ''),
            clientStatus: get(this.value, 'clientStatus', ''),
            conditions: get(this.value, 'conditions', ''),
            status: get(this.value, 'status', ''),
            distributorName: get(this.value, 'distributorName', ''),
        }
    },
    computed: {
        ...mapGetters({
            access: 'access',
            conditionsList: 'promotions/conditionsList',
            statuses: 'promotions/statuses',
            districts: 'clients/districts',
            classifications: 'clients/classifications',
            clientStatuses: 'clients/statuses',
        })
    },
    methods: {
        ...mapActions({
            'fetchStatuses': 'promotions/fetchStatuses',
            'fetchConditionsList': 'promotions/fetchConditionsList',
        }),
        onChange: debounce(function () {
            this.$emit('input', pickBy({
                date: this.date,
                user: this.user,
                id: this.id,
                name: this.name,
                district: this.district,
                classification: this.classification,
                clientInn: this.clientInn,
                client: this.client,
                clientStatus: this.clientStatus,
                conditions: this.conditions,
                status: this.status,
                distributorName: this.distributorName,
            }, Boolean));
        }, 600),
    },
    watch: {
        district: {
            handler() {
                this.region = '';
            }
        }
    },
    mounted() {
        this.fetchStatuses();
        this.fetchConditionsList();
    },
}
</script>

<style module lang="scss">
.root {
    background: none !important;

    .control {
        width: 150px;
    }
}
</style>
