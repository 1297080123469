import { toString, forEach, pickBy, toNumber, map } from 'lodash';
import { Api } from '@/api';

export class Promotions extends Api {
    item(id) {
        return this.request('promotions.info', {
            params: {
                id: toString(id),
            }
        });
    }

    /**
     * Получение списка доступных элементов
     * @param page
     * @param size
     * @param sort
     * @param filter
     * @returns {Promise<*>}
     */
    list(page = 1, size = -1, sort = {}, filter = {}) {
        const params = {
            page: toString(page),
            size: toString(size),
        };
        forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
        forEach(filter, (value, key) => params[`filter[${key}]`] = value);
        return this.request('promotions.list', {
            params,
        });
    }

    statuses() {
        return this.request('promotions.listStatuses');
    }

    listConditions() {
        return this.request('promotions.listConditions');
    }

    promotionRules() {
        return this.request('promotions.promotionRules');
    }

    districts() {
        return this.request('clients.listDistricts');
    }

    update({
               id,
               name,
               status,
               conditions,
               clientInn,
               discount,
               activeFrom,
               activeTo,
               site,
               salePoint,
               skuFile,
           }) {
        const data = pickBy({
            id: toString(id),
            name: toString(name),
            discount: toNumber(discount),
            status: toString(status),
            conditions: toString(conditions),
            clientInn: toString(clientInn),
            activeFrom: toString(activeFrom),
            activeTo: toString(activeTo),
            site: toString(site),
            salePoint: toString(salePoint),
            skuFile: skuFile ? map(skuFile, (id) => toString(id)) : null,
        }, Boolean);

        const url = '/promotions.update';
        return this.request(url, {
            method: 'POST',
            data: this.toFormData(data),
        });
    }

    comment(id, message) {
        return this.request('promotions.addComment', {
            method: 'POST',
            data: this.toFormData({
                id: toString(id),
                message: toString(message),
            }),
        })
    }
}

export default new Promotions();
