const statuses = {
  APPROVAL: '200_approval',
  AGREED: '300_agreed',
  REJECTED: '100_rejected',
  ADJUSTMENT: '400_adjustment',
  CREATURE: 'creature', // Не существует в базе служебный чтобы отличить состояние создания
  EDITING: 'editing', // Не существует в базе служебный чтобы отличить состояние изменения
  CANCEL: 'cancel', // Не существует в базе служебный чтобы отличить состояние отмены текущих изменений
};

export default statuses;
