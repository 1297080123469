var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('v-menu',{attrs:{"absolute":"","offset-y":"","close-on-content-click":false,"close-on-click":false},on:{"close":_vm.handleMenuClose},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.items)?_c('v-data-table',{class:_vm.$style.table,attrs:{"dense":"","must-sort":"","item-key":"name","loading":_vm.pending,"loading-text":"Загрузка...","items-per-page":5,"hide-default-footer":_vm.items.length < 6,"items":_vm.items,"headers":[
            {value: 'distributor.name', text: 'Дистрибьютор'},
            {value: 'sum', text: 'Сумма, руб'} ],"item-class":function () { return _vm.$style.tr; },"footer-props":{
            showFirstLastPage: true,
            itemsPerPageOptions: [5, 10, 20, -1],
            itemsPerPageText: '',
          }},on:{"click:row":_vm.onClick},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{class:_vm.$style.total},[_c('span',[_vm._v("Итого: "+_vm._s(_vm.sum)+" руб.")])])]},proxy:true}],null,true)}):_vm._e(),(_vm.pending)?_c('div',{class:_vm.$style.loader}):_vm._e()],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{ref:"menuContent",class:_vm.$style.menu},[_c('realization-cislink-table',{attrs:{"inn":_vm.inn,"distributor":_vm.distributor}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }