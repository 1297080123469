<template>
  <div :class="$style.root">
    <div :class="$style.inner">
      <audit-form
          v-if="editing"
          :audit="audit"
          :key="audit.id"
          @submit="onSubmit"
      />
      <audit-view v-else :audit="audit"/>
    </div>
    <div v-if="pending" :class="$style.loader" />
  </div>
</template>

<script>
import { get, includes } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import STATUSES from '@/api/enums/statuses';

import AuditView from '@/components/AuditView/AuditView';
import AuditForm from '@/components/AuditForm/AuditForm';
import { auditEmptyAdapter } from '@/store/audit/utils';

export default {
  name: 'AuditDetail',
  components: {
    AuditView,
    AuditForm
  },
  props: {
    id: { type: String, required: true },
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'audit/pending',
      current: 'audit/current',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    editing() {
      const status = get(this.audit, 'status.code');
      return includes([STATUSES.CREATURE], status) || !this.isAdmin && includes([STATUSES.ADJUSTMENT], status);
    },
    isCreate() {
      return this.id === 'creation';
    },
    audit() {
      if (this.isCreate) return auditEmptyAdapter();
      return this.current;
    }
  },
  methods: {
    ...mapActions({
      'fetchCurrent': 'audit/fetchCurrent',
      'removeCurrent': 'audit/removeCurrent',
    }),
    onUpdate() {
      this.isCreate || this.fetchCurrent(this.id);
    },
    onSubmit() {
      this.$router.push({ name: 'AuditListPage', params: { reload: true } });
    },
  },
  watch: {
    id() {
      this.onUpdate();
    }
  },
  beforeMount() { this.onUpdate() },
  beforeDestroy() { this.removeCurrent() }
}
</script>

<style module lang="scss">
.root {
  position: relative;
  .inner {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .loader {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
