<template>
  <tr :class="$style.root">
    <td>
      <div :class="$style.control">
        <date-picker
            v-model="date"
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="user"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
                v-model="authorDistributorName"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="id"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-select
            v-model="status"
            :items="statuses"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
                v-model="distributorName"
                clearable
                placeholder="Все"
                hide-details
                dense
                @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="client"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import { get, debounce, pickBy } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import DatePicker from '@/components/DatePickerRange/DatePickerRange';

export default {
  name: 'AuditListFilter',
  components: {
    DatePicker,
  },
  props: {
    value: { type: Object },
  },
  data() {
    return {
      date: get(this.value, 'date', ''),
      user: get(this.value, 'user', ''),
      authorDistributorName: get(this.value, 'authorDistributorName', ''),
      id: get(this.value, 'id', ''),
      status: get(this.value, 'status', ''),
      distributorName: get(this.value, 'distributorName', ''),
      client: get(this.value, 'client', ''),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      statuses: 'audit/statuses'
    }),
  },
  methods: {
    ...mapActions({
      'fetchStatuses': 'audit/fetchStatuses',
    }),
    onChange: debounce(function() {
      this.$emit('input', pickBy({
        date: this.date,
        user: this.user,
        authorDistributorName: this.authorDistributorName,
        author: this.author,
        id: this.id,
        status: this.status,
        distributorName: this.distributorName,
        client: this.client,
      }, Boolean));
    }, 600),
  },
  mounted() {
    this.fetchStatuses();
  },
}
</script>

<style module lang="scss">
.root {
  background: none !important;
  .control {
    width: 150px;
  }
}
</style>
