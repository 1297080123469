<template>
  <div :class="$style.root">
    <v-tooltip v-if="distributor.id" left>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn color="primary" absolute right fab v-bind="attrs" v-on="on" @click="onCreate">
            <v-icon v-text="'mdi-plus'"/>
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Новая акция</span>
    </v-tooltip>
    <promotions-list :reload="reload"/>
    <v-navigation-drawer :value="!!id" app stateless width="430" right mobile-breakpoint="0">
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="['Акция №' + id, 'Новая акция'][+isCreate]"/>
          </v-list-item-content>
          <v-list-item-action>
            <v-row dense align="center">
              <v-col>
                <v-btn icon @click="onClose">
                  <v-icon color="grey lighten-1" v-text="'mdi-close'"/>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </template>
      <div v-if="id" :class="$style.drawer">
        <promotions-detail :id="id" :editingView.sync="editingView"/>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PromotionsList from "@/components/PromotionsList/PromotionsList.vue";
import PromotionsDetail from "@/components/PromotionsDetail/PromotionsDetail.vue";

export default {
  name: 'PromotionsListPage',
  components: {
    PromotionsList,
    PromotionsDetail,
  },
  props: {
    id: {type: String},
    reload: {type: Boolean},
    view: {type: String},
    editingView: {type: Boolean},
  },
  computed: {
    ...mapGetters({
      access: 'access',
      distributor: 'user/distributor',
      task: 'promotions/current',
      userParams: 'user/userParams',
    }),
    isCreate() {
      return this.id === 'creation';
    }
  },
  methods: {
    ...mapActions({
      'fetchPromotionRules': 'promotions/fetchPromotionRules',
    }),
    onCreate() {
      this.$router.push({name: 'PromotionsListPage', params: {id: 'creation'}});
    },
    onClose() {
      this.$router.push({name: 'PromotionsListPage'});
    },
    onEditClick() {
      this.editingView = true;
    }
  },
  mounted() {
    this.fetchPromotionRules();
  },
  watch: {
    userParams: {
      handler() {
        if (this.userParams && this.userParams.navigation && !this.userParams.navigation[this.$options.name] && this.userParams.defaultPage) this.$router.push({name: this.userParams.defaultPage});
      },
      deep: true,
    }
  }
}
</script>

<style module lang="scss">
.root {
  .drawer {
    padding: 6px 16px 16px;
  }
}
</style>
