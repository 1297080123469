var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:_vm.$style.root,attrs:{"dense":"","must-sort":"","hide-default-footer":_vm.value.length < 6,"items-per-page":5,"items":_vm.value,"item-class":function () { return _vm.$style.tr; },"headers":[
      {value: 'date', text: 'Дата'},
      {value: 'to', text: 'Значение'},
      {value: 'user', text: 'Пользователь'},
      {value: 'login', text: 'Логин'} ],"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: [5, 10, 20, -1],
      itemsPerPageText: '',
    }}})}
var staticRenderFns = []

export { render, staticRenderFns }