var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('v-card-title',[_vm._t("title",[_vm._v(" Бухгалтерские формы ")])],2),_c('v-data-table',{attrs:{"fixed-header":"","must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":[
      {text: 'Год', value: 'year'},
      {text: 'Выручка', value: 'revenue'},
      {text: 'Валовая прибыль (убыток)', value: 'gross'},
      {text: 'Чистая прибыль (убыток)', value: 'profit'},
      {text: 'Запасы', value: 'reserves'} ],"itemsPerPage":20,"mobile-breakpoint":0,"items":_vm.formItems,"item-class":function () { return _vm.$style.row; },"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [-1],
      }}}),_c('v-card-title',[_vm._t("title",[_vm._v(" Госконтракты ")])],2),_c('v-data-table',{attrs:{"must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":[
      {text: 'Год', value: 'year'},
      {text: 'Сумма по госконтрактам', value: 'sum'},
      {text: 'Доля ГК е Выручке', value: 'share'} ],"itemsPerPage":20,"mobile-breakpoint":0,"items":_vm.governmentItems,"item-class":function () { return _vm.$style.row; },"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [-1],
      }}}),_c('v-card-title',[_vm._t("title",[_vm._v(" Экспертный рейтинг ")])],2),_c('v-data-table',{attrs:{"must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":[
      {text: 'Год', value: 'year'},
      {text: 'Рейтинг', value: 'rating'} ],"itemsPerPage":20,"mobile-breakpoint":0,"items":_vm.ratingItems,"item-class":function () { return _vm.$style.row; },"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [-1],
      }}}),_c('v-card-title',[_vm._t("title",[_vm._v(" Размер компании ")])],2),_c('v-data-table',{attrs:{"must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":[
      {text: 'Год', value: 'year'},
      {text: 'Размер', value: 'sizeName'} ],"itemsPerPage":20,"mobile-breakpoint":0,"items":_vm.companySizeItems,"item-class":function () { return _vm.$style.row; },"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [-1],
      }}}),_c('v-card-title',[_vm._t("title",[_vm._v(" Численность компании ")])],2),_c('v-data-table',{attrs:{"must-sort":"","loading-text":"Загрузка... Пожалуйста, подождите","loading":_vm.pending,"headers":[
      {text: 'Год', value: 'year'},
      {text: 'Численность', value: 'size'} ],"itemsPerPage":20,"mobile-breakpoint":0,"items":_vm.companySizeCountItems,"item-class":function () { return _vm.$style.row; },"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [-1],
      }}}),(_vm.pending)?_c('div',{class:_vm.$style.loader}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }