<template>
<div :class="$style.root">
  <task-status-switch v-if="hasControls" :buttonView="buttonView" :value="status" @input="onSubmit" class="mb-6" />
  <v-alert v-if="error" class="mb-6" dense outlined type="error">
    <div v-html="error.error_description" />
  </v-alert>
  <task-view-row
      v-if="task.type"
      title="Тип"
      :value="[task.type.name]"
      disabled
  />
  <task-view-row title="ИНН" :value="fields.inn" />
  <task-view-row title="Клиент" :value="fields.name" />
  <task-view-row title="Адрес" :value="fields.address" />
  <task-view-row title="Телефон" :value="fields.phone" />
  <task-view-row title="Сайт" :value="fields.site" />
  <task-view-row title="Классификация" :value="fields.classification" :items="classifications" />
  <task-view-row title="Классификация ОКВЭД (справочная)" :value="fields.classificationOkved"/>
  <task-view-row v-if="!canEditCKG" title="Дополнительная классификация" :value="fields.ckg" :items="ckgList"/>
  <task-form-row v-else label="Дополнительная классификация" v-model="fields.ckg" :items="ckgListByClassification" />
  <task-view-row title="ОКВЭД" :value="fields.okved" />
  <task-form-row :disabled="!canEditStatus" title="Статус" v-model="fields.statusClient" :items="statuses" />
  <advance-view-row :inn="inn" v-if="taskType==='client' && fields.advanceDiscountPeriod[0]" title="Авансовая скидка представлена на" :value="fields.advanceDiscountPeriod" />
  <task-view-row v-if="taskType==='task'" title="Авансовая скидка" :value="showAdvanceDiscount" />
  <task-form-row
          v-if="taskType==='task' && isAdvanceDiscount && task.type && task.type.code!=='confirmation'"
          title="Период авансовой скидки"
          v-model="task.advanceDiscountPeriod"
          :rules="[rules.required]"
          :items="advanceDiscountPeriods"
          :disabled="!hasControls"
  />
  <task-view-row v-if="isAdmin || isAdminView" title="Рекомендуемый статус" :items="statuses" :value="fields.recommendedStatus" />
  <panel v-if="task.files && task.files.length" title="Документы" :items="listBoolean">
    <file-table :value="task.files" />
  </panel>
  <panel-difference title="Скидки" :items="fields.sales">
    <template v-slot:default="{ items }">
      <client-discount-table :value="items" />
    </template>
  </panel-difference>
  <panel v-if="(isAdmin || isAdminView) && taskType==='client'" title="Бизнес платформа IEK" :items="fields.bpConnected">
    <client-b-p :value="fields.bpConnected"/>
  </panel>
  <panel-difference title="Связанные клиенты" :items="fields.clients" :diff="diff">
    <template v-slot:default="{ items }">
      <client-table :value="items" />
    </template>
  </panel-difference>
  <panel-difference v-if="isAdmin || isAdminView" title="Дистрибьюторы" :items="fields.distributors" :diff="diff">
    <template v-slot:default="{ items }">
      <client-distributor-table :value="items" />
    </template>
  </panel-difference>
  <panel v-if="inn && (isAdmin || isAdminView)" title="Продажи (Cislink) мес.">
    <realization-cislink-distributor :inn="inn"/>
  </panel>
  <panel v-if="inn" title="Продажи">
    <realization-distributor v-if="isAdmin || isAdminView" :inn="inn" />
    <realization-attach-table v-else :inn="inn" :realizationType="realizationType"/>
  </panel>
</div>
</template>

<script>
import { get, includes } from 'lodash';
import { mapGetters } from 'vuex';

import TaskForm from '@/mixins/TaskForm/TaskForm';
import Panel from '@/components/Panel/Panel';
import FileTable from '@/components/FileTable/FileTable';
import TaskViewRow from '@/components/TaskViewRow/TaskViewRow';
import AdvanceViewRow from '@/components/AdvanceViewRow/AdvanceViewRow';
import TaskFormRow from '@/components/TaskFormRow/TaskFormRow';
import ClientTable from '@/components/ClientTable/ClientTable';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import TaskStatusSwitch from '@/components/TaskStatusSwitch/TaskStatusSwitch';
import ClientDiscountTable from '@/components/ClientDiscountTable/ClientDiscountTable';
import ClientDistributorTable from '@/components/ClientDistributorTable/ClientDistributorTable';
import RealizationDistributor from '@/components/RealizationDistributor/RealizationDistributor';
import RealizationCislinkDistributor from '@/components/RealizationCislinkDistributor/RealizationCislinkDistributor';
import RealizationAttachTable from '@/components/RealizationAttachTable/RealizationAttachTable';
import STATUSES from '@/api/enums/statuses';
import ClientBP from "@/components/ClientBP/ClientBP.vue";

export default {
  name: 'TaskView',
  mixins: [ TaskForm ],
  components: {
    Panel,
    FileTable,
    TaskViewRow,
    AdvanceViewRow,
    ClientTable,
    PanelDifference,
    TaskStatusSwitch,
    ClientDiscountTable,
    ClientDistributorTable,
    RealizationAttachTable,
    RealizationDistributor,
    RealizationCislinkDistributor,
    TaskFormRow,
    ClientBP
  },
  props: {
    taskType: { type: String },
    realizationType: { type: String },
  },
  computed: {
    ...mapGetters({
      advanceDiscountPeriods: 'tasks/advanceDiscountPeriods',
      ckgList: 'clients/ckgList',
    }),
    buttonView() {
      let buttonView = {
        approve: true,
        adjustment: true,
        reject: true
      };

      if(this.task.type && this.task.type.code==='confirmation') buttonView.adjustment = false;
      if(this.task.type && this.task.type.code==='confirmation' && this.isAdvanceDiscount) buttonView.reject = false;

      return buttonView
    },
    hasControls() {
      return this.task.canEdit && (includes([STATUSES.APPROVAL], this.status) || (this.isAdmin && includes([STATUSES.ADJUSTMENT], this.status)));
    },
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    diff() {
      return !includes([STATUSES.APPROVAL, STATUSES.ADJUSTMENT], get(this.task, 'status.code'));
    },
    showAdvanceDiscount() {
      return [((this.task.advanceDiscount && this.task.advanceDiscount[0])?'Да':'Нет')];
    },
    isAdvanceDiscount() {
      return (this.task.advanceDiscount && this.task.advanceDiscount[0]);
    },
    canEditStatus() {
      return (this.task.type && this.task.type.code==='confirmation' && this.isAdvanceDiscount && includes([STATUSES.APPROVAL], get(this.task, 'status.code')));
    },
    canEditCKG() {
      return (this.isAdmin && includes([STATUSES.APPROVAL], this.status));
    },
    ckgListByClassification() {
      const currentClass=this.classifications.find((el) => el.code===this.fields.classification[0]);
      if(currentClass) {
        return this.ckgList.filter((ckg) => ckg.ufClassification === currentClass.id);
      }
      else {
        return [];
      }
    }
  },
  methods: {
    onSubmit(status) {
      this.error = null;
      this.submit(status);
    }
  }
}
</script>

<style module lang="scss">
.root{}
</style>
