var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(_vm.items)?_c('v-data-table',{staticClass:"mb-3",class:_vm.$style.table,attrs:{"dense":"","hide-default-footer":"","must-sort":"","item-key":"id","items-per-page":-1,"items":_vm.items,"headers":[
        {value: 'name', text: 'Имя'},
        {value: 'control'} ],"item-class":function () { return _vm.$style.tr; }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
      var index = ref.index;
      var ref_item = ref.item;
      var name = ref_item.name;
      var id = ref_item.id;
return [_c('div',{class:_vm.$style.field},[_c('distributor-field',{attrs:{"name":name,"value":id},on:{"input":function (value) { return _vm.onInput(value, index); }}})],1)]}},{key:"item.control",fn:function(ref){
      var index = ref.index;
return [_c('v-btn',{staticClass:"ml-2 float-right",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.onRemove(index)}}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-delete')}})],1)]}}],null,false,23878863)}):_vm._e(),_c('v-btn',{attrs:{"dense":"","depressed":"","disabled":_vm.disabled},on:{"click":_vm.onAdd}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-plus')}}),_vm._v(" Привязать ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }