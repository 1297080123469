<template>
<div :class="$style.root">
  <v-data-table
      v-if="items"
      class="mb-3"
      dense
      hide-default-footer
      must-sort
      item-key="id"
      :class="$style.table"
      :items-per-page="-1"
      :items="items"
      :headers="[
        {value: 'name', text: 'Имя'},
        {value: 'control'},
      ]"
      :item-class="() => $style.tr"
  >
    <template v-slot:item.name="{ index, item: { name, id }}">
      <div :class="$style.field">
        <distributor-field
            :name="name"
            :value="id"
            @input="(value) => onInput(value, index)"
        />
      </div>
    </template>
    <template v-slot:item.control="{ index }">
      <v-btn class="ml-2 float-right" small icon @click="onRemove(index)">
        <v-icon small v-text="'mdi-delete'" />
      </v-btn>
    </template>
  </v-data-table>
  <v-btn dense depressed @click="onAdd" :disabled="disabled">
    <v-icon v-text="'mdi-plus'" />
    Привязать
  </v-btn>
</div>
</template>

<script>
import { map, findIndex, uniqBy } from 'lodash';

import DistributorField from '@/components/DistributorField/DistributorField';

export default {
  name: 'DistributorAttachTable',
  components: {
    DistributorField,
  },
  props: {
    value: { type: Array, default: () => [] },
  },
  computed: {
    items() {
      return map(this.value, (item, index) => ({ index, ...item }));
    },
    disabled(){
      return !!(findIndex(this.value, {id: ''}) + 1);
    }
  },
  methods: {
    getRaw(value) {
      return JSON.parse(JSON.stringify(value))
    },
    onAdd() {
      const value = this.getRaw(this.value);
      this.$emit('input', [...value, { id: '' }]);
    },
    onRemove(index){
      const value = this.getRaw(this.value);
      value.splice(index, 1);
      this.$emit('input', value);
    },
    onInput(id, index) {
      const value = this.getRaw(this.value);
      value[index].id = id;
      this.$emit('input', uniqBy(value, 'id'));
    },
  },
}
</script>

<style module lang="scss">
.root {}
.table {}
.tr {}
.field {}
</style>
