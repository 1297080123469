<template>
  <v-data-table
      dense
      must-sort
      hide-default-header
      :hide-default-footer="count < 6"
      :class="$style.root"
      :items-per-page="5"
      :items="value"
      :item-class="() => $style.tr"
      :headers="[
        {value: 'name', text: 'Название'},
        {value: 'value', text: 'Процент %'},
      ]"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, -1],
        itemsPerPageText: '',
      }"
  >
    <template v-slot:item.name="{ item: {code, name} }">
      {{ name || _.get(_.find(sales, 'code'), 'name') || code }}
    </template>
    <template v-slot:item.value="{ item: {value} }">
      {{ value }}%
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
export default {
  name: 'ClientDiscountTable',
  props: {
    value: {
      type: Array,
    },
  },
  computed: {
    ...mapGetters({
      sales: 'clients/sales',
    }),
    count() {
      return get(this, 'value.length', 0);
    }
  }
}
</script>

<style module lang="scss">
.root {
  .tr {
    background: none !important;
    td {
      font-size: 18px !important;
      width: 50%;
    }
  }
  :global {
    .v-data-footer{
      justify-content: space-between;
      padding: 0;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
}
</style>
