import { toString, forEach, pickBy, map } from 'lodash';
import { Api } from '@/api';

export class Audit extends Api {
  item(id) {
    return this.request('audit.info', {
      params: {
        id: toString(id),
      }
    });
  }
  /**
   * Получение списка доступных клиентов
   * @param page
   * @param size
   * @param sort
   * @param filter
   * @returns {Promise<*>}
   */
  list(page = 1, size = -1, sort = {}, filter = {}) {
    const params = {
      page: toString(page),
      size: toString(size),
    };
    forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
    forEach(filter, (value, key) => params[`filter[${key}]`] = value);
    return this.request('audit.list', {
      params,
    });
  }
  statuses() {
    return this.request('audit.listStatuses');
  }
  regions() {
    return this.request('clients.listRegions');
  }
  update({ id, name, status, check, violations, inn, preview, comment, person, files, clients, distributor, distributorName }) {
    const data = pickBy({
      id: toString(id),
      name: toString(name),
      status: toString(status),
      check: ((check && check[0])?toString(check):''),
      violations: ((violations && violations[0])?toString(violations):''),
      inn: inn,
      preview: ((preview && preview[0])?toString(preview):''),
      comment: ((comment && comment[0])?toString(comment):''),
      person: ((person && person[0])?toString(person):''),
      files: files ? map(files, (id) => toString(id)) : null,
      clients: clients ? map(clients, (obj) => toString(obj.id)) : null,
      distributor: toString(distributor),
      distributorName: toString(distributorName),
    }, Boolean);

    const url = '/audit.update';
    return this.request(url, {
      method: 'POST',
      data: this.toFormData(data),
    });
  }
  distributors(filter = {}) {
    const params = {};
    forEach(filter, (value, key) => params[`filter[${key}]`] = value);
    console.log('distributors',params);
    return this.request('audit.listDistributors', {
      params,
    });
  }
  violationsList() {
    return this.request('audit.listViolations');
  }
  innTypeList() {
    return this.request('audit.listInnType');
  }
  checksList() {
    return this.request('audit.listChecks');
  }
  excel(page = 1, size = -1, sort = {}, filter = {}) {
    const params = {
      page: toString(page),
      size: toString(size),
    };
    forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
    forEach(filter, (value, key) => params[`filter[${key}]`] = value);
    return this.request('audit.excelToQueue', {
      params,
    });
  }
}

export default new Audit();
