<template>
  <div :class="$style.root">
    <v-tooltip v-if="!isAdminView" left>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn @click="download" color="primary" absolute right fab v-bind="attrs" v-on="on">
            <v-icon v-text="'mdi-table-arrow-right'" />
          </v-btn>
        </v-fab-transition>
      </template>
      <span>Экспорт в excel</span>
    </v-tooltip>
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="_.filter([
          {text: 'Время создания', value: 'date'},
          {text: '№ заявки', value: 'id'},
          {text: 'Проверяется', value: 'distributorName'},
          {text: 'Клиент', value: 'nameClient'},
          {text: 'Тип проверки по', value: 'check.name'},
          {text: 'Выявлены нарушения', value: 'violations.name'},
          {text: 'Контактное лицо', value: 'person'},
          {text: 'Результат', value: 'preview', sortable: false},
          {text: 'Округ', value: 'district.name', sortable: false},
          {text: 'Регион', value: 'region.name', sortable: false},
          {text: 'Тип ИНН', value: 'innType.name'},
          {text: 'Дата закрытия заявки', value: 'dateClose'},
          {text: 'Документы отчёта', value: 'filesDisplay', sortable: false},
        ], ({value}) => (isAdmin || isAdminView) || (!['distributor.name'].includes(value)))"
        :mobile-breakpoint="0"
        :height="height"
        :items="items"
        :item-class="({ id }) => [$style.row, {
            [$style.active]: id === active,
        }]"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500],
          itemsPerPageText: '',
        }"
        @click:row="onClickRow"
    >
      <template v-slot:item.filesDisplay="{ item }">
        <span v-html="item.filesDisplay"></span>
      </template>
      <template v-slot:body.prepend>
        <audit-result-list-filter v-model="filter" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get, pick, merge } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import AuditResultListFilter from '@/components/AuditResultListFilter/AuditResultListFilter';
export default {
  name: 'AuditResultList',
  components: {
    AuditResultListFilter,
  },
  data() {
    const filter = {};
    const options = pick(JSON.parse(localStorage.getItem('AuditResultList.options')), [ 'sortBy', 'sortDesc', 'page', 'itemsPerPage' ]);
    return {
      height: window.innerHeight - 90,
      filter: merge({}, filter),
      options: merge({
        sortBy: ['date'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 20,
      }, options),
    }
  },
  props: {
    reload: { type: Boolean }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'audit/pending',
      items: 'audit/items',
      count: 'audit/count',
      accessAuditor: 'user/accessAuditor'
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    active() {
      return this.$route.params.id;
    },
    sort() {
      const field = get(this.options, ['sortBy', 0], 'name');
      const direction = ['asc', 'desc'][+get(this.options, ['sortDesc', 0], false)];
      const alias = {
        'status.name': 'status',
        'nameClient': 'client',
        'distributorName': 'distributorName',
        'check.name': 'check',
        'violations.name': 'violations',
        'innType.name': 'innType',
        'district.name': 'district',
        'region.name': 'region',
      };
      return {
        field: alias[field] || field,
        direction,
      };
    },
    filesDisplay: function () {
      return 'файлы';
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'audit/fetchItems',
      fetchExel: 'audit/fetchExel',
    }),
    update() {
      this.fetchItems({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      });
    },
    updateLocalStorage() {
      localStorage.setItem('AuditResultList.options', JSON.stringify(this.options));
    },
    resize() {
      this.height = window.innerHeight - 90;
    },
    onClickRow({ id }) {
      this.$router.push({ name: 'AuditResultListPage', params: { id }});
    },
    download() {
      this.fetchExel({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      }).then(() => {
        this.$snack({
          text: 'Экспорт результатов аудита добавлен в очередь на формирование XLSX. После формирования файл будет доступен в разделе «Загрузка»',
          pos: 'top-center',
          actionText: 'Понятно',
        });
      }).catch((error) => {
        this.$snack({
          text: get(error, 'error_description', 'Сервис не доступен, попробуйте позже'),
          pos: 'top-center',
          actionText: 'Понятно',
        });
      });
    }
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    filter: {
      handler() {
        this.update();
      },
      deep: true,
    },
    options: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
    reload: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-data-footer{
      justify-content: flex-start;
      padding-left: 16px;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
  .row {
    cursor: pointer;
    td {
      width: 1px;
    }
  }
  .active{
    background: #eeeeee;
  }
}
</style>
