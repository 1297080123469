<template>
  <div :class="$style.root">
    <v-data-table
        fixed-header
        must-sort
        loading-text="Загрузка... Пожалуйста, подождите"
        :loading="pending"
        :headers="_.filter([
          {text: 'Время создания', value: 'date'},
          {text: 'Автор заявки', value: 'user'},
          {text: '№ заявки', value: 'id'},
          {text: 'Округ', value: 'district.name'},
          {text: 'Регион', value: 'region.name'},
          {text: 'Тип', value: 'type.name'},
          {text: 'Статус заявки', value: 'status.name'},
          {text: 'Дистрибьютор', value: 'distributor.name'},
          {text: 'Клиент', value: 'nameClient'},
          {text: 'Статус в заявке', value: 'newStatus.name'},
          {text: 'ИНН', value: 'inn'},
          {text: 'Классификация', value: 'classification.name'},
        ], ({value}) => (isAdmin || isAdminView) || (!['distributor.name'].includes(value)))"
        :mobile-breakpoint="0"
        :height="height"
        :items="items"
        :item-class="({ id }) => [$style.row, {
            [$style.active]: id === active,
        }]"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: [5, 10, 20, 100, 500],
          itemsPerPageText: '',
        }"
        @click:row="onClickRow"
    >
      <template v-slot:body.prepend>
        <task-list-filter v-model="filter" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { get, pick, merge } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

import TaskListFilter from '@/components/TaskListFilter/TaskListFilter';
export default {
  name: 'TaskList',
  components: {
    TaskListFilter,
  },
  data() {
    const filter = {};
    const options = pick(JSON.parse(localStorage.getItem('TaskList.options')), [ 'sortBy', 'sortDesc', 'page', 'itemsPerPage' ]);
    return {
      height: window.innerHeight - 90,
      filter: merge({}, filter),
      options: merge({
        sortBy: ['date'],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 20,
      }, options),
    }
  },
  computed: {
    ...mapGetters({
      access: 'access',
      pending: 'tasks/pending',
      items: 'tasks/items',
      count: 'tasks/count',
    }),
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    active() {
      return this.$route.params.id;
    },
    sort() {
      const field = get(this.options, ['sortBy', 0], 'name');
      const direction = ['asc', 'desc'][+get(this.options, ['sortDesc', 0], false)];
      const alias = {
        'district.name': 'district',
        'region.name': 'region',
        'status.name': 'status',
        'newStatus.name': 'newStatus',
        'type.name': 'type',
        'distributor.name': 'distributor',
        'nameClient': 'client',
        'classification.name': 'classification',
      };
      return {
        field: alias[field] || field,
        direction,
      };
    }
  },
  methods: {
    ...mapActions({
      fetchItems: 'tasks/fetchItems',
    }),
    update() {
      this.fetchItems({
        page: this.options.page,
        size: this.options.itemsPerPage,
        sort: this.sort,
        filter: this.filter,
      });
    },
    updateLocalStorage() {
      localStorage.setItem('TaskList.options', JSON.stringify(this.options));
    },
    resize() {
      this.height = window.innerHeight - 90;
    },
    onClickRow({ id }) {
      this.$router.push({ name: 'TaskListPage', params: { id }});
    },
  },
  mounted() {
    this.resize();
    window.addEventListener('resize', this.resize, { passive: true });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  watch: {
    filter: {
      handler() {
        this.update();
      },
      deep: true,
    },
    options: {
      handler() {
        this.update();
        this.updateLocalStorage();
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global {
    .v-data-footer{
      justify-content: flex-start;
      padding-left: 16px;
      .v-data-footer__select {
        .v-input {
          min-width: 60px;
        }
      }
      .v-data-footer__pagination {
        min-width: 70px;
      }
      .v-select {
        margin-left: 0 !important;
      }
    }
  }
  .row {
    cursor: pointer;
    td {
      width: 1px;
    }
  }
  .active{
    background: #eeeeee;
  }
}
</style>
