<template>
<div :class="$style.root">
  <div v-if="title" :class="$style.title">{{title}}</div>
  <div :class="$style.inner">
    <slot />
  </div>
</div>
</template>

<script>
export default {
  name: 'Panel',
  props: {
    title: {
      type: String,
    }
  }
}
</script>

<style module lang="scss">
.root {
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  .title {
    padding: 5px;
    text-align: center;
    background: #eeeeee;
    font-weight: bold;
  }
  .inner {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .root {
    margin-top: -10px;
    .title {
      font-weight: normal;
      font-size: 12px;
      background: #f5f5f5;
    }
  }
}
</style>
