<template>
  <tr :class="$style.root">
    <td>
      <div :class="$style.control">
        <date-picker
            v-model="date"
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="name"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td>
      <div :class="$style.control">
        <v-text-field
            v-model="inn"
            clearable
            placeholder="Все"
            hide-details
            dense
            @input="onChange"
        />
      </div>
    </td>
    <td />
    <td />
  </tr>
</template>

<script>
import { get, debounce, pickBy } from 'lodash';
import DatePicker from '@/components/DatePickerRange/DatePickerRange';

export default {
  name: 'ClassificationsListFilter',
  components: {
    DatePicker
  },
  props: {
    value: { type: Object },
  },
  data() {
    return {
      date: get(this.value, 'date', ''),
      name: get(this.value, 'name', ''),
      inn: get(this.value, 'inn', ''),
    }
  },
  methods: {
    onChange: debounce(function() {
      this.$emit('input', pickBy({
        date: this.date,
        name: this.name,
        inn: this.inn,
      }, Boolean));
    }, 600),
  },
}
</script>

<style module lang="scss">
.root {
  background: none !important;
  .control {
    width: 150px;
  }
}
</style>
