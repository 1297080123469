import {get, toString} from 'lodash';
import {enumerationAdapter} from '@/store/utils';
/**
 * Выборка полей клиента из структуры arResult
 * @param item
 * @returns {{site: string, address: string, clients: unknown[], phone: string, name: string, inn: string, id: string, classification: {code: string, name: string}, status: {code: string, name: string}}}
 */
export const checksListItemAdapter = (item) => ({
  id: toString(get(item, 'id')),
  name: toString(get(item, 'name')),
  inn: toString(get(item, 'inn')),
  date: toString(get(item, 'date')),
  checksList: [
    enumerationAdapter({
      code: toString(get(item, ['checkOld'])),
      name: toString(get(item, ['checkNameOld'])),
    }),
    enumerationAdapter({
      code: toString(get(item, ['checkNew'])),
      name: toString(get(item, ['checkNameNew'])),
    })
  ], // TODO: заглушка взять из api
});
