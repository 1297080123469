<template>
  <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="onSubmit({ id, message })">
    <div :class="$style.row">
      <v-text-field
          label="Комментарий"
          outlined
          clearable
          hide-details="auto"
          v-model="message"
      />
      <v-btn
          :disabled="!message.length"
          color="primary"
          fab
          type="submit"
          class="ml-3"
      >
        <v-icon v-text="'mdi-lead-pencil'" />
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'PromotionsCommentForm',
  props: {
    id: {type: String},
  },
  data: () => ({
    valid: true,
    message: '',
    rules: {
      required: v => !!v || 'Обязательное поле',
    },
  }),
  methods: {
    ...mapActions({
      sendComment: 'promotions/sendComment',
    }),
    reset() {
      this.message = '';
    },
    onSubmit({id, message}) {
      this.$refs.form.validate();
      if (this.valid) {
        this.error = null;
        this.sendComment({id, message})
        .then(() => {
          this.$emit('submit');
          this.reset();
        })
        .catch((error) => (this.error = error));
      }
    },
    onReset() {
      this.reset();
      this.$emit('reset');
    }
  }
}
</script>

<style module lang="scss">
.row {
  display: flex;
}
</style>
