<template>
    <task-view-row v-if="disabled" :title="title" :value="value" :items="items"/>
    <div :class="$style.root" v-else class="mb-5">
        <v-row dense>
            <v-col :cols="12 / value.length" v-for="(val, index) in value" :key="index">
                <div v-if="label" :class="$style.label">{{label}}</div>
                <component
                        :is="component || (items ? 'v-select' : 'v-text-field')"
                        :value="val"
                        :label="(value.length - (index + 1)) ? 'Было' : title"
                        :items="itemsFiltered"
                        :readonly="!!(value.length - (index + 1))"
                        :disabled="disabled"
                        :rules="(value.length - (index + 1)) ? [] : rules"
                        :check="check"
                        :rows="rows"
                        :input-value="inputValue"
                        :type="type"
                        outlined
                        dense
                        hide-details="auto"
                        @input="(value) => onInput(value, index)"
                        @update="(value) => $emit('update', value)"
                        @change="(value) => $emit('change', value)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {set} from 'lodash';
    import InnField from '@/components/InnField/InnField';
    import TaskViewRow from '@/components/TaskViewRow/TaskViewRow';

    export default {
        name: 'TaskFormRow',
        components: {
            InnField,
            TaskViewRow
        },
        props: {
            title: {type: String},
            type: {type: String, default: 'text'},
            label: {type: String},
            value: {type: Array, default: () => []},
            rules: {type: Array, default: () => []},
            check: {type: Array, default: () => []},
            rows: {type: Number, default: 3},
            items: {type: Array},
            disabled: {type: Boolean},
            component: {type: String},
            inputValue: {type: Boolean},
        },
        data() {
            return {
                itemsFiltered: [],
            }
        },
        methods: {
            onInput(value, index) {
                const next = JSON.parse(JSON.stringify(this.value));
                set(next, index, value);
                return this.$emit('input', next);
            },
            filterItems() {
                this.itemsFiltered = this.items.filter(item => (item.ufActive === '1' || (this.value && this.value.length && this.value[0]===item.code)));
            }
        },
        watch: {
            'items': {
                handler() {
                    if(this.items) {
                        this.itemsFiltered=JSON.parse(JSON.stringify(this.items));

                        if(this.items && this.items.length && this.items[0].ufActive!==false) {
                            this.filterItems();
                        }
                    }

                },
            },
        },
        mounted() {
            this.$nextTick(() => {
                if(this.items) {
                    this.itemsFiltered=JSON.parse(JSON.stringify(this.items));

                    if(this.items && this.items.length && this.items[0].ufActive!==false) {
                        this.filterItems();
                    }
                }
            });
        },
    }
</script>

<style module lang="scss">
    .root {
        textarea {
            margin-top: 12px;
            margin-bottom: 12px;
            width: 100%;
            resize: none;
        }
        :global {
            .v-input--checkbox {
                margin-top: 0;
                padding-top: 0;
            }
        }
        .label {
            font-size: 12px;
            color: rgba(0,0,0,.6);
            &:not(:last-child) {
                margin-bottom: 6px;
            }
        }
    }
</style>
