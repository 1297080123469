const roles = {
  ADMIN: 'admin_iprice',
  USER: 'distributor',
  ADMIN_VIEW: 'view_iprice',
  AUDITOR: 'auditor',
  AUDITOR_RM: 'auditor_rm',
  AUDITOR_RFO: 'auditor_rfo',
  DEALER: 'dealer',
  REGIONAL: 'regional'
};

export default roles;
