<template>
    <div :class="$style.root">
        <v-form ref="form" v-model="valid" :class="$style.root">
            <promotions-status-switch class="mb-6" :value="status" :disabled="!formValid" :editingView="editingView"
                                      @input="onSubmit" @reset="onReset"/>
            <v-alert v-if="error" class="mb-6" dense outlined type="error">
                <div v-html="error.error_description"/>
            </v-alert>
            <task-form-row title="Название акции" v-model="fields.name"/>
            <task-form-row
                title="ИНН клиента"
                v-model="fields.clientInn"
                :component="isCreate ? 'inn-field': null"
                :check="['required', 'inn', 'not', 'duplicate', 'related']"
                :disabled="!isCreate"
                @update="onUpdate"
            />
            <task-view-row title="Название клиента" :value="fields.clientName"/>
            <task-view-row title="Округ" :value="fields.district" :items="districts"/>
            <task-view-row title="ЦКГ/Классификация" :value="fields.classification" :items="classifications"/>
            <task-view-row title="Статус Клиента" :value="fields.clientStatus" :items="clientStatuses"/>
            <task-view-row title="Дистрибутор" :value="[distributor.name]"/>

            <panel title="Информация по акции">
                <task-form-row title="Точка продаж" v-model="fields.salePoint"/>
                <promotions-form-row-site title="Сайт" v-model="fields.site"/>
            </panel>

            <panel title="Акционное оборудование">
                <promotions-file-list :value="promotion.skuFile" @input="onUpdateFiles"/>
                <v-alert v-if="formValid && !files.length" class="mt-5 mb-0" dense outlined type="error">
                    <div>Приложите документ с артикулами</div>
                </v-alert>
            </panel>

            <panel title="Запрашиваемая скидка">
                <task-form-row
                    title="Условия акции"
                    v-model="fields.conditions"
                    :rules="[rules.required]"
                    :items="conditionsList"
                />
                <task-form-row :rules="[rules.required, checkPercentValue]" type="number"
                               v-if="condition" title="Размер снижения РРЦ, %" v-model="fields.discount"/>
                <date-picker-range v-if="condition" :outlined="true"
                                   :title="`Срок действия акции (не более ${promotionDaysMax} дней)`"
                                   v-model="activeRange"
                                   :defaultValue="defaultActiveRange"
                                   @input="onChangeActiveRange"/>
            </panel>
        </v-form>
    </div>
</template>

<script>
import { first, get, head, join, reverse, split, toNumber } from 'lodash';
import { mapGetters } from 'vuex';

import TaskViewRow from "@/components/TaskViewRow/TaskViewRow.vue";
import TaskFormRow from "@/components/TaskFormRow/TaskFormRow.vue";
import PromotionsForm from "@/mixins/PromotionsForm/PromotionsForm.vue";
import DatePickerRange from "@/components/DatePickerRange/DatePickerRange.vue";
import { conditions } from "@/api/enums/promotion";
import PromotionsStatusSwitch from "@/components/PromotionsStatusSwitch/PromotionsStatusSwitch.vue";
import Panel from "@/components/Panel/Panel.vue";
import PromotionsFileList from "@/components/PromotionsFileList/PromotionsFileList.vue";
import PromotionsFormRowSite from "@/components/PromotionsFormRowSite/PromotionsFormRowSite.vue";

export default {
    name: 'PromotionsForm',
    mixins: [PromotionsForm],
    props: {
        editingView: {type: Boolean},
    },
    components: {
        PromotionsFileList,
        Panel,
        PromotionsStatusSwitch,
        DatePickerRange,
        TaskFormRow,
        TaskViewRow,
        PromotionsFormRowSite
    },
    data() {
        return {
            correct: false,
            inner: false,
            related: false,
            activeRange: '',
            belowROCPercentMax: 25
        };
    },
    computed: {
        ...mapGetters({
            conditionsList: 'promotions/conditionsList',
            roc: 'promotions/roc',
            promotionRules: 'promotions/promotionRules',
            classifications: 'clients/classifications',
            clientStatuses: 'clients/statuses',
            districts: 'clients/districts',
            distributor: 'user/distributor',
        }),
        formValid: function () {
            return !!this.fields.clientInn && !!this.fields.clientInn[0] && this.valid && !!this.fields.activeFrom[0] && !!this.fields.activeTo[0] && !!this.fields.name[0];
        },
        condition() {
            return first(this.fields.conditions);
        },
        conditionsEnum() {
            return conditions;
        },
        defaultActiveRange() {
            const activeRange = [];
            if (first(this.fields.activeFrom)) {
                const activeFrom = join(reverse(split(first(this.fields.activeFrom), '.')), '-');
                activeRange.push(activeFrom)
            }
            if (first(this.fields.activeTo)) {
                const activeTo = join(reverse(split(first(this.fields.activeTo), '.')), '-');
                activeRange.push(activeTo)
            }
            return activeRange;
        },
        promotionDaysMax() {
            if (this.condition && this.promotionRules[this.condition]) {
                return this.promotionRules[this.condition].UF_MAX_DAYS;
            } else {
                return '';
            }
        }
    },
    methods: {
        onUpdate(items) {
            const item = head(items) || {};
            const {name, classification, status, district} = item;
            const code = item.code || this.values.inn;
            this.correct = !!get(item, 'code', false);
            this.inner = get(item, 'inner', false);
            this.related = get(item, 'related', false);

            this.reset();
            code && this.$set(this.fields.clientInn, 0, code);
            if (this.inner && this.related) {
                if (this.isCreate) {
                    name && this.$set(this.fields.clientName, 0, name);
                    classification && this.$set(this.fields.classification, 0, classification);
                    status && this.$set(this.fields.clientStatus, 0, status);
                    district && this.$set(this.fields.district, 0, district);

                    this.$snack({
                        text: `Клиент ${['не', ''][toNumber(this.inner)]} найден в базе iPrice`,
                        pos: 'top-center',
                        actionText: 'Понятно',
                    });
                }
            }
        },
        onChangeActiveRange(value) {
            this.fields.activeFrom = '';
            this.fields.activeTo = '';

            if (value) {
                const range = value.split('-');
                if (range[0]) {
                    this.fields.activeFrom = range[0];
                }
                if (range[1]) {
                    this.fields.activeTo = range[1];
                }
            }
        },
        onUpdateFiles(value) {
            this.files = value;
        },
        onSubmit(status) {
            this.$refs.form.validate();
            if (this.valid) {
                this.submit(status, this.inner);
            }
        },
        onReset() {
            this.editingView = false;
            this.$emit('update:editingView', false);
            this.reset();
            this.$emit('reset');
        },
        checkPercentValue(val) {
            if(this.condition) {
                if(this.condition === this.conditionsEnum.ROC) {
                    return (1 <= toNumber(val) && toNumber(val) <= this.roc) || `Введите значение от 1 до ${this.roc}`;
                } else {
                    return (this.roc <= toNumber(val) && toNumber(val) <= this.belowROCPercentMax) || `Введите значение от ${this.roc} до ${this.belowROCPercentMax}`;
                }
            }

            return true;
        }
    },
    watch: {
        'statuses': {
            handler() {
                // Произойдет только если статусы не успели загрузиться на момент рендера
                this.onReset();
            },
        },
        'condition': {
            handler() {
                this.$set(this.fields.discount, 0, this.roc);
            },
        },
    }
}
</script>

<style module lang="scss">
.root {
}
</style>
