import {includes, set, get, forEach, find, values, pick} from 'lodash';
import { router } from '@/router';
import ROLES from '@/api/enums/roles';
import user from './api';

const START = 'START';
const END = 'END';
const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';
const UPDATE = 'UPDATE';
const SEND_QUESTION = 'SEND_QUESTION';
const SET_PARAMS = 'SET_PARAMS';

export default {
  namespaced: true,
  state: {
    id: null,
    inn: null,
    name: null,
    email: null,
    login: null,
    groups: null,
    distributor: null,
    canExport: null,
    notSendEvent: false,
    userParams: null,
    pending: {
      fetch: false,
      signIn: false,
      signOut: false,
      sendPassword: false,
      resetPassword: false,
      getParams: false,
      getCaptcha: false,
    },
  },
  getters: {
    pending(state) {
      return includes(state.pending, true);
    },
    id(state) {
      return get(state, 'id', '');
    },
    inn(state) {
      return get(state, 'inn', '');
    },
    name(state) {
      return get(state, 'name', '');
    },
    email(state) {
      return get(state, 'email', '');
    },
    login(state) {
      return get(state, 'login', '');
    },
    groups(state) {
      return get(state, 'groups', []);
    },
    distributor(state) {
      return pick(state.distributor, ['id', 'name']);
    },
    canExport(state) {
      return get(state, 'canExport', false);
    },
    accessAuditor(state, getters) {
      return find(getters.groups, ({ code }) => ROLES.AUDITOR===code);
    },
    accessAuditorRM(state, getters) {
      return find(getters.groups, ({ code }) => ROLES.AUDITOR_RM===code);
    },
    accessAuditorRFO(state, getters) {
      return find(getters.groups, ({ code }) => ROLES.AUDITOR_RFO===code);
    },
    accessDealer(state, getters) {
      return find(getters.groups, ({ code }) => ROLES.DEALER===code);
    },
    accessRegional(state, getters) {
      return find(getters.groups, ({ code }) => ROLES.REGIONAL===code);
    },
    role(state, getters) {
      const role = find(getters.groups, ({ code }) => includes(values(ROLES), code));
      return pick(role, ['name', 'code']);
    },
    notSendEvent(state) {
      return get(state, 'notSendEvent', false);
    },
    userParams(state) {
      return state.userParams;
    },
  },
  mutations: {
    [START](state, key) {
      set(state, ['pending', key], true);
    },
    [END](state, key) {
      set(state, ['pending', key], false);
    },
    [LOGIN](state) {
      set(state, ['auth'], true);
    },
    [LOGOUT](state) {
      set(state, ['auth'], false);
    },
    [UPDATE](state, fields) {
      forEach(fields, (value, key) => set(state, key, value));
    },
    [SEND_QUESTION](state, fields) {
      forEach(fields, (value, key) => set(state, key, value));
    },
    [SET_PARAMS](state, fields) {
      state.userParams = fields;
    }
  },
  actions: {
    async fetch({ commit }) {
      commit(START, 'fetch');
      const { result } = await user.info();
      const success = !get(result, 'error');
      if (success) commit(UPDATE, pick(result.user, [
        'id',
        'inn',
        'name',
        'email',
        'login',
        'groups',
        'distributor',
        'canExport',
        'notSendEvent',
      ]));
      commit(END, 'fetch');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };
    },
    async signIn({ commit }, payload) {
      const login = get(payload, 'login');
      const password = get(payload, 'password');
      commit(START, 'signIn');
      const { result } = await user.signIn(login, password);
      const success = get(result, 'isAuthorized') || !get(result, 'error');
      if (success) commit(LOGIN);
      commit(END, 'signIn');

      if (!success) throw {
        error: 'Ошибка авторизации',
        error_description: get(result, 'message'),
      };
    },
    async signOut({ commit }) {
      commit(START, 'signOut');
      const { result } = await user.signOut();
      const success = !get(result, 'error');
      if (success) commit(LOGOUT);
      commit(END, 'signOut');

      if (!success) throw {
        error: 'Ошибка авторизации',
        error_description: get(result, 'message'),
      };
      else await router.replace({ name: 'UserSignInPage' });
    },
    async sendPassword({ commit }, payload) {
      const login = get(payload, 'login');
      commit(START, 'sendPassword');
      const { result } = await user.sendPassword(login);
      const success = !get(result, 'error');
      commit(END, 'sendPassword');

      if(!success) throw {
        error: 'Ошибка авторизации',
        error_description: get(result, 'message'),
      }
    },
    async resetPassword({ commit }, payload) {
      const login = get(payload, 'login');
      const password = get(payload, 'password');
      const word = get(payload, 'word');
      commit(START, 'resetPassword');
      const { result } = await user.resetPassword(login, password, word);
      const success = !get(result, 'error');
      commit(END, 'resetPassword');

      if(!success) throw {
        error: 'Ошибка авторизации',
        error_description: get(result, 'message'),
      }
      else await router.replace({ name: 'UserSignInPage' });
    },
    async update({ commit }, payload) {
      const name = get(payload, 'name');
      const password = get(payload, 'password');
      const newPassword = get(payload, 'newPassword');
      const notSendEvent = get(payload, 'notSendEvent');
      commit(START, 'update');
      const { result } = await user.update(name, password, newPassword, notSendEvent);
      const success = !get(result, 'error');
      if (success) commit(UPDATE, {
        name,
      });
      commit(END, 'update');

      if (!success) throw {
        error: 'Ошибка сохранения данных',
        error_description: get(result, 'message'),
      };
    },

    async sendQuestionForm({ commit }, payload) {
      const question = get(payload, 'question');
      commit(START, 'sendQuestionForm');
      const { result } = await user.sendQuestionForm(question);
      const success = !get(result, 'error');
      if (success) commit(SEND_QUESTION, {
        question,
      });
      commit(END, 'sendQuestionForm');

      if (!success) throw {
        error: 'Ошибка сохранения данных',
        error_description: get(result, 'message'),
      };
    },

    async sendDistributorQuestionForm({ commit }, payload) {
      const data = payload;
      commit(START, 'sendDistributorQuestionForm');
      const { result } = await user.sendDistributorQuestionForm(data);
      const success = !get(result, 'error');
      if (success) commit(SEND_QUESTION, data,);
      commit(END, 'sendDistributorQuestionForm');

      if (!success) throw {
        error: 'Ошибка сохранения данных',
        error_description: get(result, 'message'),
      };
    },

    async getParams({ commit }) {
      commit(START, 'getParams');
      const { result } = await user.getParams();
      const success = !get(result, 'error');
      if (success) {
        commit(SET_PARAMS, result.userParams);
      }
      commit(END, 'getParams');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };

      return result.userParams;
    },

    async getCaptcha({ commit }) {
      commit(START, 'getCaptcha');
      const { result } = await user.getCaptcha();
      const success = !get(result, 'error');
      commit(END, 'getCaptcha');

      if (!success) throw {
        error: 'Ошибка получения данных',
        error_description: get(result, 'message'),
      };

      return result;
    },
  }
}
