<template>
  <div :class="$style.root">
    <inn-check-form />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import InnCheckForm from '@/components/InnCheckForm/InnCheckForm';

export default {
  name: 'InnCheckPage',
  components: {
    InnCheckForm
  },
  computed: {
    ...mapGetters({
      access: 'access',
      userParams: 'user/userParams',
    }),
    isCreate() {
      return this.id === 'creation';
    },
    showContent() {
      return (this.userParams && !this.userParams.navigation) || (this.userParams && this.userParams.navigation && this.userParams.navigation[this.$options.name]);
    },
  },
  watch: {
    userParams: {
      handler() {
        if(this.userParams) {
          if(this.userParams.navigation && !this.userParams.navigation[this.$options.name] && this.userParams.defaultPage) this.$router.push({ name: this.userParams.defaultPage});
        }
      },
      deep: true,
    },
  }
}
</script>

<style module lang="scss">
.root{
  .drawer{
    padding: 6px 16px 16px;
  }
}
</style>
