import {toString, forEach} from 'lodash';
import {Api} from '@/api';

export class Downloads extends Api {
    item(id) {
        return this.request('downloads.info', {
            params: {
                id: toString(id),
            }
        });
    }

    /**
     * Получение списка доступных сотрудников
     * @param page
     * @param size
     * @param sort
     * @param filter
     * @returns {Promise<*>}
     */
    list(page = 1, size = -1, sort = {}, filter = {}) {
        const params = {
            page: toString(page),
            size: toString(size),
        };
        forEach(sort, (value, key) => (params[`sort[${key}]`] = value));
        forEach(filter, (value, key) => params[`filter[${key}]`] = value);
        return this.request('downloads.list', {
            params,
        });
    }

    downloadStatuses() {
        return this.request('downloads.listDownloadStatuses');
    }
}

export default new Downloads();
