<template>
<div :class="$style.root">
  <task-status-switch v-if="hasControls" :value="status" @input="onSubmit" class="mb-6" />
  <task-view-row
      v-if="task.type"
      title="Тип"
      :value="[task.type.name]"
      disabled
  />
  <task-view-row title="ИНН" :value="fields.inn" />
  <task-view-row title="Клиент" :value="fields.name" />
  <task-view-row title="Адрес" :value="fields.address" />
  <task-view-row title="Телефон" :value="fields.phone" />
  <task-view-row title="Сайт" :value="fields.site" />
  <task-view-row title="Классификация" :value="fields.classification" :items="classifications" />
  <task-view-row title="ОКВЭД" :value="fields.okved" />
  <task-view-row title="Статус" :value="fields.statusClient" :items="statuses" />
  <panel v-if="task.files && task.files.length" title="Документы">
    <file-table :value="task.files" />
  </panel>
  <panel-difference title="Скидки" :items="fields.sales">
    <template v-slot:default="{ items }">
      <client-discount-table :value="items" />
    </template>
  </panel-difference>
  <panel-difference title="Связанные клиенты" :items="fields.clients" :diff="diff">
    <template v-slot:default="{ items }">
      <client-table :value="items" />
    </template>
  </panel-difference>
  <panel-difference v-if="isAdmin || isAdminView" title="Дистрибьюторы" :items="fields.distributors" :diff="diff">
    <template v-slot:default="{ items }">
      <client-distributor-table :value="items" />
    </template>
  </panel-difference>
  <panel v-if="inn" title="Продажи">
    <realization-distributor v-if="isAdmin || isAdminView" :inn="inn" />
    <realization-attach-table v-else :inn="inn" realizationType="relevance" />
  </panel>
</div>
</template>

<script>
import { get, includes } from 'lodash';
import TaskForm from '@/mixins/TaskForm/TaskForm';

import Panel from '@/components/Panel/Panel';
import FileTable from '@/components/FileTable/FileTable';
import TaskViewRow from '@/components/TaskViewRow/TaskViewRow';
import ClientTable from '@/components/ClientTable/ClientTable';
import PanelDifference from '@/components/PanelDifference/PanelDifference';
import TaskStatusSwitch from '@/components/TaskStatusSwitch/TaskStatusSwitch';
import ClientDiscountTable from '@/components/ClientDiscountTable/ClientDiscountTable';
import ClientDistributorTable from '@/components/ClientDistributorTable/ClientDistributorTable';
import RealizationDistributor from '@/components/RealizationDistributor/RealizationDistributor';
import RealizationAttachTable from '@/components/RealizationAttachTable/RealizationAttachTable';
import STATUSES from '@/api/enums/statuses';

export default {
  name: 'RelevanceTaskView',
  mixins: [ TaskForm ],
  components: {
    Panel,
    FileTable,
    TaskViewRow,
    ClientTable,
    PanelDifference,
    TaskStatusSwitch,
    ClientDiscountTable,
    ClientDistributorTable,
    RealizationAttachTable,
    RealizationDistributor,
  },
  computed: {
    hasControls() {
      return this.task.canEdit && (includes([STATUSES.APPROVAL], this.status) || (this.isAdmin && includes([STATUSES.ADJUSTMENT], this.status)));
    },
    isAdmin() {
      return this.access(({ ADMIN }, ROLE) => ADMIN === ROLE);
    },
    isAdminView() {
      return this.access(({ ADMIN_VIEW }, ROLE) => ADMIN_VIEW === ROLE);
    },
    diff() {
      return !includes([STATUSES.APPROVAL, STATUSES.ADJUSTMENT], get(this.task, 'status.code'));
    },

  },
  methods: {
    onSubmit(status) {
      this.submit(status);
    }
  },
}
</script>

<style module lang="scss">
.root{}
</style>
