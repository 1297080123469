<template>
  <v-dialog persistent max-width="600" :value="true" @click:outside="close" @keydown.esc="close">
    <well title="Вопрос в техническую поддержку дистрибутора">
      <template v-slot:title="{ title }">
        {{title}}
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <distributor-question-form
          @submit="close"
          @reset="close"
      />
    </well>
  </v-dialog>
</template>

<script>
import Well from '@/components/Well/Well';
import DistributorQuestionForm from "../../components/DistributorQuestionForm/DistributorQuestionForm";

export default {
  name: 'DistributorQuestionDialog',
  components: {
    DistributorQuestionForm,
    Well,
  },
  methods: {
    close() {
      this.$router.push({ hash: null });
    }
  }
}
</script>
